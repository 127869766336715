/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { postOrderCreation } from '../../Api/OrderApi';
import { CLIENT_ID } from '../../utils/constantData';
import { resetProductOrderDetailsRedux } from '../../redux/Order/OrderAction';
import { useNavigate } from 'react-router';
import { postUserPointBurnApi, postUserPointEarnApi } from '../../Api/Points';
import GiftcardTable from './GiftcardTable';
import MerchandiseTable from './MerchandiseTable';
import { ErrorSharp } from '@mui/icons-material';
import { useState } from 'react';
import SnackbarAlert from '../common/SnackbarAlert';

const SuccessPage = () => {
    const dispatch = useDispatch();
    const history = useNavigate();

    const orderState = useSelector((state) => state?.orderState?.orderObject);
    const accountDetailsState = useSelector((state) => state?.settingState?.accountDetails);
    const orderPriceState = useSelector((state) => state?.orderState?.orderPriceObject);

    const [isLoading, setIsLoding] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSnackbar, setIsSnackbar] = useState(false);

    const [orderDetails, setOrderDetails] = useState(false);

    useEffect(() => {
    }, [accountDetailsState]);

    useEffect(() => {
        orderState?.prodType && submit();
    }, []);

    useEffect(() => {
        if (!orderDetails && !orderState) {
            history("/");
        }
    }, [orderDetails, orderState]);

    const submit = async () => {
        setIsLoding(true);
        let obj = null;
        if (orderState?.prodType === "merchandise") {
            obj = {
                "order_by": "client",
                "buyer_id": CLIENT_ID,
                "product_type": "merchandise",
                "product_id": orderState.prodDetails.merchandise.id,
                "product_variant_id": orderState.productVariant,
                "product_value": 0,
                "inventory_type_id": "",
                "quantity": orderState.quantity,
                "order_price": parseFloat(orderState.amount),
                "discount": 0,
                "total_price": parseFloat(orderState.amount),
                "delivery_type": "physical",
                "digital_delivery_option": "separate_email_for_each_giftcard",
                "delivery_address_line_1": orderState.persnalDetails.address + ", " + orderState.persnalDetails.city + ", " + orderState.persnalDetails.pincode,
                "delivery_address_line_2": "",
                "email_id": orderState.persnalDetails.email.toLowerCase(),
                "phone_number": orderState.persnalDetails.countryCode.dial_code + orderState.persnalDetails.mobileNo,
                // "receiver": {
                //     "receiver_type": "",
                //     "receiver_id": ""
                // }
            }
        } else if (orderState?.prodType === "giftcard") {
            obj = {
                "order_by": "client",
                "buyer_id": CLIENT_ID,
                "product_type": "giftcard",
                "product_id": orderState.prodDetails.id,
                "product_variant_id": orderState.giftcard_value_type === "denominations" ? orderState?.denominations.id : "",
                "product_value": 0,
                "inventory_type_id": "",
                "quantity": orderState.quantity,
                "order_price": parseFloat(orderState.orderAmount),
                "discount": 0,
                "total_price": parseFloat(orderState.amount),
                "delivery_type": "digital",
                "digital_delivery_option": "separate_email_for_each_giftcard",
                "delivery_address_line_1": "",
                "delivery_address_line_2": "",
                "email_id": orderState.email.toLowerCase(),
                "phone_number": orderState.persnalDetails.contact_no,
                // "receiver": {
                //     "receiver_type": "",
                //     "receiver_id": ""
                // }
            }
        }
        try {
            const resOrderCreate = await orderCreate(obj);
        } catch (error) {
        }
    }

    const orderCreate = async (obj) => {
        const res = await postOrderCreation(obj);
        if (res?.data?.code === 200) {
            setOrderDetails(res.data.result);
            dispatch(resetProductOrderDetailsRedux());
            let invoiceNo = res?.data?.result?.id;
            let invoiceAmount = res?.data?.result?.order_price
            let payAmount = parseFloat(orderPriceState.payAmount)


            setIsLoding(false);

            // Points Burn Api
            try {
                if (orderPriceState?.burnFlag) {
                    setIsLoding(true);
                    let payload = {
                        "contact_no": accountDetailsState?.contact_no,
                        "invoice_no": `burn${invoiceNo}`,
                        "invoice_amount": invoiceAmount,
                        "points_to_burn": parseFloat(orderPriceState.payPoints)
                    }
                    await postUserPointBurnApi(payload);
                }
                setIsError(false);
                setIsLoding(false);
            } catch (error) {
                setIsError(true);
                setIsLoding(false);
            }

            // Points Earn Api
            if (payAmount > 0) {
                try {
                    await postUserPointEarnApi(`earn${invoiceNo}`, payAmount);
                    setIsLoding(false);
                } catch (error) {
                    setIsLoding(false);
                }
            }
        }
    }

    const handleClose = () => {
        setIsSnackbar(false);
    };

    return (
        < >
            {isLoading ?
                <div className='d-flex justify-content-center align-content-center w-100'>
                    <CircularProgress color="secondary" />
                </div> :
                <>
                    {!isError ?
                        <div className="passenger DetailDrwaer m-5 d-flex flex-column w-100" >
                            <div className="ms-4">
                                <div className="d-flex flex-column align-items-center mt-4">
                                    <CheckCircleIcon style={{ color: 'var(--primayColor)', fontSize: '80px' }} />
                                    {/* <img width={70} src={tick} alt="" /> */}
                                    <h2 className="mt-3">Thank you!</h2>
                                    <span className="fw-bold-c mt-3">
                                        Your payment was successful
                                    </span>
                                    <br />
                                    {/* <span className="text-center">
                                This is your E-tickets. Do present it with a valid photo
                                identification at the airport check-in counter. For
                                international travel: The check-in counters are open 4 hours
                                prior to departure and close strictly 2 hours prior to departure
                            </span> */}
                                </div>
                                {/* <div className="mt-2">
                                <span className="fw-bold">
                                    Order id: <strong>{orderDetails?.id}</strong>
                                </span>
                            </div>
                            <span className="fw-bold">Order Details</span> */}
                                {orderDetails?.product_type === "giftcard" ?
                                    <GiftcardTable
                                        orderDetails={orderDetails}
                                        setIsSnackbar={setIsSnackbar}
                                    /> :
                                    orderDetails?.product_type === "merchandise" ?
                                        <MerchandiseTable
                                            orderDetails={orderDetails}
                                            setIsSnackbar={setIsSnackbar}
                                        /> :
                                        null}
                            </div>

                        </div> : <div className="passenger DetailDrwaer m-5 d-flex flex-column w-100" >
                            <div className="ms-4">
                                <div className="d-flex flex-column align-items-center mt-4">
                                    <ErrorSharp style={{ color: 'var(--primayColor)', fontSize: '80px' }} />
                                    <h2 className="mt-3">Something went wrong!</h2>

                                </div>

                            </div>

                        </div>}
                </>}
            <>
                <SnackbarAlert
                    open={isSnackbar}
                    vertical={"bottom"}
                    horizontal={"center"}
                    handleClose={handleClose}
                    severity="info"
                    autoHideDuration={2000}
                    message={"Please wait..."}
                />
            </>
        </>
    )


}

export default SuccessPage