/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAccountStatus } from "../../utils/constantFunctions";


export default function PointsComponent() {
  const loginStatus = getAccountStatus();
  const userPointState = useSelector((state) => state?.orderState?.points);


  useEffect(() => { }, [userPointState, loginStatus]);

  return (
    <>
      {loginStatus && userPointState ? <div className='d-flex justify-content-end'>
        <div className="pointTab">
          <span style={{ color: "white " }}>Points: <span className='fw-semibold'>
            {parseFloat(userPointState).toFixed(2)}
          </span></span>
        </div>
      </div> : null}
    </>
  );
}
