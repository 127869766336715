import {
  GET_FLIGHT_LIST_REQUEST,
  GET_FLIGHT_LIST_SUCCESS,
  GET_FLIGHT_LIST_FAILURE,
  SET_FLIGHT_SCREEN_STEP,
  SET_FLIGHT_DETAILS,
  SET_SELECTED_FLIGHT_DETAILS,
  SET_PASSENGER_DETAILS,
  SET_TRAVEL_MODULE_STEP,
} from "./flightType";

const initialWeightState = {
  loading: false,
  step: 0,
  travelModuleStep: 1,
  flightDetails: null,
  selectedflightDetails: null,
  passengerDetails: null,
  flightList: [],
  error: "",
};

const flightReducer = (state = initialWeightState, action) => {
  switch (action.type) {

    case SET_FLIGHT_SCREEN_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case SET_TRAVEL_MODULE_STEP:
      return {
        ...state,
        travelModuleStep: action.payload,
      };

    case SET_FLIGHT_DETAILS:
      return {
        ...state,
        flightDetails: action.payload,
      };

    case SET_SELECTED_FLIGHT_DETAILS:
      return {
        ...state,
        selectedflightDetails: action.payload,
      };

    case SET_PASSENGER_DETAILS:
      return {
        ...state,
        passengerDetails: action.payload,
      };

    case GET_FLIGHT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case GET_FLIGHT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        flightList: action.payload,
        error: "",
      };
    case GET_FLIGHT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        flightList: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
export default flightReducer;
