import { Skeleton } from '@mui/material';
import React from 'react'
import bgImgArray from "../../../assets/img/index";

const VoucherCard = ({ title, imgUrl, type, item }) => {


    return (

        item ? <div class="card card--1 ">

            <div class="card__img"></div>
            <div classname="card_link">
                <div class="card__img--hover" style={{ backgroundImage: `url(${bgImgArray[imgUrl] || imgUrl})` }}>
                    {/* <img src={bgImgArray[imgUrl] || imgUrl} alt="logos" style={{ width: '100%' }} /> */}
                </div>
            </div>
            <div class="card__info">
                {/* <span class="card__category"> Recipe</span> */}
                <h3 class="card__title">{title?.slice(0, 50)}</h3>
                {type === "range" ?
                    <span class="card__by">{item?.country?.currency + " " + item?.min_amount} - {item?.country?.currency + " " + item?.max_amount}</span>
                    :
                    <span class="card__by">{item?.country?.currency + " " + Math.min(...item?.denominations && item?.denominations?.map(item => item?.denomination_value))} - {item?.country?.currency + " " + Math.max(...item?.denominations && item?.denominations?.map(item => item?.denomination_value))}</span>
                }
            </div>
        </div> :
            <div>
                <Skeleton animation="wave" height={"220px"} />
                <Skeleton animation="wave" height={"50px"} />
                <Skeleton animation="wave" height={"30px"} />
            </div>
    )
}

export default VoucherCard