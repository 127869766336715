import Attraction from "../components/Atrractions/Attraction";
import Home from "../components/travel/Home";
import Travel1 from "../components/travel/Travel1";
import Voucher from "../components/voucher/Voucher";
import Shop from "../components/shop/Shop";
// import NotFoundScreen from "../components/common/NotFoundScreen";
import SignInScreen from "../components/signin/SignInScreen";


export const PublicRoutes = [
    { url: '/', component: <Home /> },
    { url: '/travel', component: <Travel1 /> },
    { url: '/voucher', component: <Voucher /> },
    { url: '/shop', component: <Shop /> },
    { url: '/attraction', component: <Attraction /> },
    // { url: '*', component: <NotFoundScreen /> },
    { url: '*', component: <SignInScreen /> },
]