import React, { useRef, useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import "./car-rental.css";
import moment from "moment";
import Tabs from "./Tabs";
import TrendingProducts from "./TrendingProducts";
import BannerComponent from "./BannerComponent";
import Menu from "./Menu";
import carsidebanner from "../../assets/img/Travel/webp/close-up-parked.webp";
import CarResult from "./CarResult";
import ButtonAppBar from "../common/Appbar";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import DatePicker from "../DatePicker/DatePicker";
import { nextYearRange } from "../../utils/constantFunctions";

export default function CarRentalComponent({ option, setoption }) {
  const todaysDate = moment(new Date()).format("YYYY-MM-DD");
  const settingState = useSelector(
    (state) => state?.settingState?.settingResponse
  );

  const [bannerShow, setbannerShow] = useState(true);
  const initialData = () => {
    setbannerShow(true);
  };

  // const dispatch = useDispatch();

  const inputElementCountry = useRef(null);

  const carDetails = useSelector((state) => state?.carState?.carDetails);

  const [sarchCarBtn, setSarchCarBtn] = useState(true);

  const step = useSelector((state) => state?.carState?.step);

  const [fields, setFields] = useState({
    country: carDetails ? carDetails.country : "",
    city: carDetails ? carDetails.city : "",
    location: carDetails ? carDetails.location : "",
    pickUpDate: carDetails ? new Date(carDetails.pickUpDate) : new Date(),
    pickUpTime: carDetails ? carDetails.pickUpTime : "",
    dropOffDate: carDetails ? new Date(carDetails.dropOffDate) : new Date(),
  });

  const [error, setError] = useState({
    country: "",
    city: "",
    location: "",
    pickUpDate: "",
    pickUpTime: "",
    dropOffDate: "",
  });

  // useEffect(() => {
  //   if (step >= 1) {
  //     setbannerShow(false);
  //     setSarchCarBtn(true);
  //   }
  // }, [step]);
  console.log(settingState)
  const SelectHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields({ ...fields, [name]: value });
    setError({ ...error, [name]: " " });
  };

  // const dateHandler = (item, name) => {
  //   if (name === "pickUpDate") {
  //     setFields({ ...fields, pickUpDate: item, dropOffDate: item });
  //   } else if (name === "dropOffDate") {
  //     setFields({ ...fields, dropOffDate: item });
  //   }
  // };

  const validation = () => {
    if (!fields.country) {
      setError({ ...error, country: "Please select the Country" });
      if (inputElementCountry.current) {
        inputElementCountry.current.focus();
      }
      return false;
    } else if (!fields.city) {
      setError({ ...error, city: "Please select the City" });
      return false;
    } else if (!fields.location) {
      setError({ ...error, location: "Please select the Location" });
      return false;
    } else if (!fields.pickUpDate) {
      setError({ ...error, pickUpDate: "Please select the Pick Up Date" });
      return false;
    } else if (!fields.pickUpTime) {
      setError({ ...error, pickUpTime: "Please select the Pick Up Time" });
      return false;
    }
    return true;
  };

  const searchCarHandler = () => {
    let val = validation();
    if (val) {
      setSarchCarBtn(false);
      setbannerShow(true);
      setbannerShow(false);
      // dispatch(setCarScreenStep(1));
      // dispatch(setCarDetails(fields));
    }
  };

  return (
    <>
      <div className="w-100 d-flex mobileStack">
        <div
          className={
            bannerShow
              ? "commonw50 mainLeft mobileBox"
              : "commonw50 mainLeft mobileBox mobileIndex"
          }
        >
          <Tabs setoption={setoption} option={option} />
          <div className="d-flex flex-column align-items-center">
            <Box
              sx={{
                width: { xs: "100%", sm: "auto" },
                padding: { xs: "0px 15px", sm: "0px" },
              }}
            >
              <Stack spacing={2}>
                <div>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      flexDirection: { xs: "column", sm: "row", md: "row" },
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack spacing={0}>
                      <InputLabel>Country</InputLabel>
                      <Select
                        ref={inputElementCountry}
                        labelId="Select Country"
                        id="demo-simple-select"
                        onChange={SelectHandler}
                        name={"country"}
                        sx={{
                          minWidth: "250px",
                        }}
                        defaultValue={fields && fields.country}
                      >
                        <MenuItem value={1}>Dubai</MenuItem>
                        <MenuItem value={2}>India</MenuItem>
                        <MenuItem value={3}>USA</MenuItem>
                      </Select>
                      {error && error.country ? (
                        <label className="text-error"> {error.country}</label>
                      ) : null}
                    </Stack>
                    <Stack
                      spacing={0}
                      sx={{
                        marginLeft: {
                          xs: "0px !important",
                          sm: "16px !important",
                          md: "16px !important",
                        },
                      }}
                    >
                      <InputLabel>City</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="Select City"
                        onChange={SelectHandler}
                        sx={{
                          minWidth: "250px",
                        }}
                        name={"city"}
                        defaultValue={fields && fields.city}
                      >
                        <MenuItem value={1}>Sharjah</MenuItem>
                        <MenuItem value={2}>Al Ain</MenuItem>
                        <MenuItem value={3}>Ras Al Khaimah</MenuItem>
                      </Select>
                      {error && error.city ? (
                        <label className="text-error"> {error.city}</label>
                      ) : null}
                    </Stack>
                  </Stack>
                </div>
                <div>
                  <Stack spacing={0}>
                    <InputLabel>Location</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="Select Location"
                      onChange={SelectHandler}
                      // sx={{
                      //   maxWidth: { xs: '100%', sm: '515px' },
                      // }}
                      name={"location"}
                      defaultValue={fields && fields.location}
                    >
                      <MenuItem value={1}>Sharjah</MenuItem>
                      <MenuItem value={2}>Al Ain</MenuItem>
                      <MenuItem value={3}>Ras Al Khaimah</MenuItem>
                    </Select>
                    {error && error.location ? (
                      <label className="text-error"> {error.location}</label>
                    ) : null}
                  </Stack>
                </div>

                <div>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      flexDirection: { xs: "column", sm: "row", md: "row" },
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack spacing={0} style={{ width: "100%" }}>
                      <InputLabel>Pick-up Date</InputLabel>
                      {/* <TextField
                        id="datePick-UP-Date"
                        type="date"
                        defaultValue={todaysDate}
                        sx={{ width: { xs: '100%', sm: '250px' } }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /> */}
                      <DatePicker
                        // onChange={dateHandler}
                        selected={new Date()}
                        minDate={new Date()}
                        maxDate={nextYearRange()}
                        name={"pickup"}
                        id="pickup"
                        className="datepicker-input-style-without-border"
                      />
                      {error && error.pickUpDate ? (
                        <label className="text-error">{error.pickUpDate}</label>
                      ) : null}
                    </Stack>
                    <Stack direction="column" className="marginLeft">
                      <InputLabel className="mx-3">Pick-up time </InputLabel>
                      <Stack direction="row">
                        <Stack
                          spacing={0}
                          sx={{
                            marginLeft: {
                              xs: "0px !important",
                              sm: "16px !important",
                              md: "16px !important",
                            },
                          }}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="Select Location"
                            onChange={SelectHandler}
                            sx={{
                              maxWidth: { xs: "100%", sm: "515px" },
                            }}
                            style={{ width: "100px" }}
                            name={"pickUpTime"}
                            defaultValue={fields && fields.pickUpTime}
                          >
                            <MenuItem value={1}>01</MenuItem>
                            <MenuItem value={2}>02</MenuItem>
                            <MenuItem value={3}>03</MenuItem>
                            <MenuItem value={3}>04</MenuItem>
                            <MenuItem value={3}>05</MenuItem>
                            <MenuItem value={3}>06</MenuItem>
                            <MenuItem value={3}>07</MenuItem>
                            <MenuItem value={3}>08</MenuItem>
                            <MenuItem value={3}>09</MenuItem>
                            <MenuItem value={3}>10</MenuItem>
                            <MenuItem value={3}>11</MenuItem>
                            <MenuItem value={3}>12</MenuItem>
                          </Select>
                          {error && error.pickUpTime ? (
                            <label className="text-error">
                              {error.pickUpTime}
                            </label>
                          ) : null}
                        </Stack>
                        <Stack
                          spacing={0}
                          sx={{
                            marginLeft: {
                              xs: "0px !important",
                              sm: "16px !important",
                              md: "16px !important",
                            },
                          }}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="Select Location"
                            onChange=""
                            sx={{
                              maxWidth: { xs: "100%", sm: "515px" },
                            }}
                            style={{ width: "100px" }}
                          >
                            <MenuItem value={1}>01</MenuItem>
                            <MenuItem value={2}>02</MenuItem>
                            <MenuItem value={3}>03</MenuItem>
                            <MenuItem value={3}>04</MenuItem>
                            <MenuItem value={3}>05</MenuItem>
                            <MenuItem value={3}>06</MenuItem>
                            <MenuItem value={3}>07</MenuItem>
                            <MenuItem value={3}>08</MenuItem>
                            <MenuItem value={3}>09</MenuItem>
                            <MenuItem value={3}>10</MenuItem>
                            <MenuItem value={3}>11</MenuItem>
                            <MenuItem value={3}>12</MenuItem>
                          </Select>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </div>
                <div>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      flexDirection: { xs: "column", sm: "row", md: "row" },
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack spacing={0} style={{ width: "100%" }}>
                      <InputLabel>Drop-off Date</InputLabel>
                      {/* <TextField
                        id="dateDrop-off-Date"
                        type="date"
                        defaultValue={todaysDate}
                        sx={{ width: { xs: "100%", sm: "250px" } }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /> */}
                      <DatePicker
                        // onChange={departureDateHandler}
                        selected={new Date()}
                        minDate={new Date()}
                        maxDate={nextYearRange()}
                        name={"dropOff"}
                        id="dropOff"
                        className="datepicker-input-style-without-border"
                      />
                    </Stack>
                    <Stack
                      spacing={0}
                      sx={{
                        marginLeft: {
                          xs: "0px !important",
                          sm: "16px !important",
                          md: "16px !important",
                        },
                      }}
                    >
                      <Stack direction="column">
                        <InputLabel className="mx-3">Drop-off time </InputLabel>
                        <Stack direction="row">
                          <Stack
                            spacing={0}
                            sx={{
                              marginLeft: {
                                xs: "0px !important",
                                sm: "16px !important",
                                md: "16px !important",
                              },
                            }}
                          >
                            <Select
                              labelId="demo-simple-select-label"
                              id="Select Location"
                              onChange=""
                              sx={{
                                maxWidth: { xs: "100%", sm: "515px" },
                              }}
                              style={{ width: "100px" }}
                            >
                              <MenuItem value={1}>01</MenuItem>
                              <MenuItem value={2}>02</MenuItem>
                              <MenuItem value={3}>03</MenuItem>
                              <MenuItem value={3}>04</MenuItem>
                              <MenuItem value={3}>05</MenuItem>
                              <MenuItem value={3}>06</MenuItem>
                              <MenuItem value={3}>07</MenuItem>
                              <MenuItem value={3}>08</MenuItem>
                              <MenuItem value={3}>09</MenuItem>
                              <MenuItem value={3}>10</MenuItem>
                              <MenuItem value={3}>11</MenuItem>
                              <MenuItem value={3}>12</MenuItem>
                            </Select>
                          </Stack>
                          <Stack
                            spacing={0}
                            sx={{
                              marginLeft: {
                                xs: "0px !important",
                                sm: "16px !important",
                                md: "16px !important",
                              },
                            }}
                          >
                            <Select
                              labelId="demo-simple-select-label"
                              id="Select Location"
                              onChange=""
                              sx={{
                                maxWidth: { xs: "100%", sm: "515px" },
                              }}
                              style={{ width: "100px" }}
                            >
                              <MenuItem value={1}>01</MenuItem>
                              <MenuItem value={2}>02</MenuItem>
                              <MenuItem value={3}>03</MenuItem>
                              <MenuItem value={3}>04</MenuItem>
                              <MenuItem value={3}>05</MenuItem>
                              <MenuItem value={3}>06</MenuItem>
                              <MenuItem value={3}>07</MenuItem>
                              <MenuItem value={3}>08</MenuItem>
                              <MenuItem value={3}>09</MenuItem>
                              <MenuItem value={3}>10</MenuItem>
                              <MenuItem value={3}>11</MenuItem>
                              <MenuItem value={3}>12</MenuItem>
                            </Select>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </div>
                <div>
                  <div>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        flexDirection: { xs: "column", sm: "row", md: "row" },
                      }}
                    >
                      <FormControlLabel
                        value="ReturnToSameLocation"
                        control={
                          <Checkbox style={{ color: "var(--primayColor)" }} />
                        }
                        label="Return to same location"
                      />
                      <FormControlLabel
                        value="DriverAge"
                        control={
                          <Checkbox style={{ color: "var(--primayColor)" }} />
                        }
                        label="Driver aged between 25 -70 years"
                        sx={{
                          marginLeft: {
                            xs: "-11px !important",
                            sm: "16px !important",
                            md: "16px !important",
                          },
                        }}
                      />
                    </Stack>
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-end">
                  <button
                    className="search-travel-btn"
                    onClick={() => {
                      searchCarHandler();
                      // setbannerShow(false);
                    }}
                  // onClick={searchCarHandler}
                  >
                    Search Car
                  </button>
                </div>
              </Stack>
            </Box>
            <Menu />
            <TrendingProducts />
          </div>
        </div>
        <div className="commonw50 mobileBox">
          {bannerShow ? (
            <BannerComponent
              heading={"Welcome to the"}
              text={settingState?.car?.car_banner_title ? settingState?.car?.car_banner_title : "World of Endless Rewards!"}
              banner={carsidebanner}
            />
          ) : (
            <>
              {" "}
              <Box
                className="mobileDetailAppBar"
                sx={{ display: { xs: "block", sm: "none", md: "none" } }}
              >
                <ButtonAppBar initialData={initialData} text="Cars" />
              </Box>
              <CarResult />
            </>
          )}
        </div>
      </div>
    </>
  );
}
