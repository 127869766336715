import {makeStyles} from '@mui/styles';

const useStyle = makeStyles({
    accountInput : {
        margin: '0 0 20px !important',
        position:'relative',
        fontSize: '15px',
    },
    profilePic : {
        background: 'red'
    },
    inputIcon : {
        position: 'absolute',
        top:'30px',
        right: '10px',
        fill: '#CBCDCE !important',
        height: '20px !important'
    },
    borderBtn: {
        fontFamily: 'helvetica_neue65_medium !important',
        fontSize: '16px !important',
        color:'var(--primayColor)  !important',
        fontWeight: 'normal !important',
        border: '1px solid var(--primayColor) !important',
        transition: '.35s all',
        textTransform: 'capitalize !important',
        "&:hover" : {
            backgroundColor:'var(--primayColor) !important',
            color:'#ffffff !important'
        }
    }

}
)
export default useStyle;
