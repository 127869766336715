import React from 'react'
import TravelCard from '../common/TravelCard'
import vouchersidebanner from '../../assets/img/voucher/vouchersidebanner.webp'
import shop from '../../assets/img/Travel/Shop.png'
import activity from '../../assets/img/Travel/webp/Activities.webp'
import flight from '../../assets/img/Travel/webp/airplane.webp'
import hotel from '../../assets/img/Travel/webp/sunset-pool.webp'
import car from '../../assets/img/Travel/webp/car.webp'


const Menu = () => {

    const list = [

        {
            banner: flight,
            title: "Flight",
            state: "Flight",
            link: "/travel"
        },
        {
            banner: hotel,
            title: "Hotel",
            state: "Hotel",
            link: "/travel"
        },
        {
            banner: car,
            title: "Car",
            state: "Car",
            link: "/travel"
        },
        {
            banner: vouchersidebanner,
            title: "Voucher",
            link: '/voucher'
        },
        {
            banner: shop,
            title: "Shop",
            link: '/shop'
        },
        {
            banner: activity,
            title: "Atrraction & Activites",
            link: '/attraction'
        },
    ]

  return (
    <div className='d-flex justify-content-between w-100 my-3 px-3 flex-wrap '>
        {
            list.map((item,index) => <TravelCard state={item.state} banner={item.banner} title={item.title} link={item.link} key={index} />)
        }
    </div>
  )
}


export default Menu