import { RESET_PRODUCT_DETAILS, SET_ORDER_PRICE_OBJECT, SET_PRODUCT_DETAILS, SET_USER_POINTS } from "./OrderType";

const initialWeightState = {
  orderObject: null,
  points: null,
  orderPriceObject: null,
  error: "",
};

const orderReducer = (state = initialWeightState, action) => {
  switch (action.type) {

    case SET_PRODUCT_DETAILS:
      return {
        ...state,
        orderObject: action.payload,
      };

    case RESET_PRODUCT_DETAILS:
      return {
        ...state,
        orderObject: null,
        orderPriceObject: null,
      };

    case SET_USER_POINTS:
      return {
        ...state,
        points: action.payload ? action.payload : null,
      };

    case SET_ORDER_PRICE_OBJECT:
      return {
        ...state,
        orderPriceObject: action.payload,
      };


    default:
      return state;
  }
};
export default orderReducer;
