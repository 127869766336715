import React, { useState } from "react";

import "../travel/flights.css";

import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
function Sorted_By() {
  const [isdepartureselected, setIsDepartureSelected] = useState(false);
  const [isdurationselected, setIsDurationSelected] = useState(false);
  const [isarrivalselected, setIsArrivalSelected] = useState(false);
  const [ispriceselected, setIsPriceSelected] = useState(false);
  const [iconfordeparture, setIconForDeparture] = useState();
  const [iconforduration, setIconForDuration] = useState();
  const [iconforarrival, setIconForArrival] = useState();
  const [iconforprice, setIconForPrice] = useState();

  const handleDepartureClick = () => {
    setIsDepartureSelected(!isdepartureselected);
    setIconForDeparture(!iconfordeparture);
    setIconForDuration();
    setIconForArrival();
    setIconForPrice();
  };

  const handleDurationClick = () => {
    setIsDurationSelected(!isdurationselected);
    setIconForDuration(!iconforduration);
    setIconForDeparture();
    setIconForArrival();
    setIconForPrice();
    setIsDepartureSelected(false);
  };

  const handleArrivalClick = () => {
    setIsArrivalSelected(!isarrivalselected);
    setIconForArrival(!iconforarrival);
    setIconForDeparture();
    setIconForDuration();
    setIconForPrice();
  };

  const handlePriceClick = () => {
    setIsPriceSelected(!ispriceselected);
    setIconForPrice(!iconforprice);
    setIconForDeparture();
    setIconForDuration();
    setIconForArrival();
  };

  return (
    <div className="main">
      <div className="heading">
        <strong>Sorted_By:</strong>
      </div>
      <div className="Sorted_By">
        <div onClick={handleDepartureClick} className="item">
          <span style={{ fontWeight: isdepartureselected ? "bold" : "normal" }}>
            Departure
          </span>
          <span>
            {iconfordeparture && <NorthIcon style={{ fontSize: "small" }} />}
          </span>
          <span>
            {iconfordeparture === false && (
              <SouthIcon style={{ fontSize: "small" }} />
            )}
          </span>
        </div>
        <div onClick={handleDurationClick} className="item">
          <span style={{ fontWeight: isdurationselected ? "bold" : "normal" }}>
            Duration
          </span>
          <span>
            {iconforduration && <NorthIcon style={{ fontSize: "small" }} />}
          </span>
          <span>
            {iconforduration === false && (
              <SouthIcon style={{ fontSize: "small" }} />
            )}
          </span>
        </div>
        <div onClick={handleArrivalClick} className="item">
          <span style={{ fontWeight: isarrivalselected ? "bold" : "normal" }}>
            Arrival
          </span>
          <span>
            {iconforarrival && <NorthIcon style={{ fontSize: "small" }} />}
          </span>
          <span>
            {iconforarrival === false && (
              <SouthIcon style={{ fontSize: "small" }} />
            )}
          </span>
        </div>
        <div onClick={handlePriceClick} className="item">
          <span style={{ fontWeight: ispriceselected ? "bold" : "normal" }}>
            Price
          </span>
          <span>
            {iconforprice && <NorthIcon style={{ fontSize: "small" }} />}
          </span>
          <span>
            {iconforprice === false && (
              <SouthIcon style={{ fontSize: "small" }} />
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Sorted_By;
