import React from 'react';
import "./logoutpopup.css"


function Logoutpopup({ show, onhide ,handleLogout}) {
    if(show){
    return ( 
        <div className="modalBackground container-fluid">
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <h1 className='text-size'>Are you sure ? you want to logout</h1>
                </div>
                <div className="footer">
                    <button className='but' onClick={()=>handleLogout()} >Logout</button>
                    <button className='btn btn-secondary' onClick={()=>onhide()}>Cancel</button>
                </div>
            </div>
        </div>
    );}
    else{
        return("")
    }
    }


export default Logoutpopup;