import React from 'react'
import { Box } from '@mui/system';
import VoucherContainer from './container/VoucherContainer';

const Voucher = () => {
    return (
        <>
            <VoucherContainer />
        </>
    )
}

export default Voucher