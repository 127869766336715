import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";


export default function PriceSlider({ minMaxAmount, setMinMaxAmount }) {


  return (
    <Box sx={{ width: "100%" }}>
      <div className="border px-1">
        <Slider
          getAriaLabel={() => "Temperature range"}
          value={minMaxAmount}
          max={5000}
          onChange={(e) => setMinMaxAmount(e.target.value)}
          // onMouseUp={handleChange}
          valueLabelDisplay="auto"
          style={{ color: "var(--primayColor)" }}
          sx={{
            "& .MuiSlider-thumb": {
              borderRadius: "1px",
              width: "10px",
              height: "15px",
            },
            "& .MuiSlider-rail, .MuiSlider-track": {
              color: "black",
              opacity: "1",
            },
          }}
        />
      </div>
      <div className="d-flex justify-content-between w-100">
        <div>
          {minMaxAmount[0]}
        </div>
        {minMaxAmount[1]}
      </div>
    </Box>
  );
}
