/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box } from '@mui/system';
import VoucherDetail from '../voucherDetail/VoucherDetail';
import VoucherFilter from '../filter/VoucherFilter';
import VoucherCard from './VoucherCard';
import BannerComponent from '../../travel/BannerComponent';
import vouchersidebanner from '../../../assets/img/voucher/vouchersidebanner.webp';
import { useState } from 'react';
import { useEffect } from 'react';
import { CircularProgress, Grid, Skeleton } from '@mui/material';
import { Stack } from '@mui/material';
import ButtonAppBar from '../../common/Appbar';
import { useDispatch, useSelector } from 'react-redux';
import { setFlightDetails, setFlightScreenStep } from '../../../redux/Flight/flightAction';
import { newGetVoucherTotal } from '../../../Api/VoucherApi';
import { useQuery } from 'react-query'
import '../../commonComponents/pagination.css'
import CommonPagination from '../../commonComponents/CommonPagination';
import PointsComponent from '../../commonComponents/PointsComponent';
import { useDebounce } from '../../../utils/constantFunctions';

const VoucherContainer = () => {
    const dispatch = useDispatch()

    const settingState = useSelector((state) => state?.settingState?.settingResponse);

    const [bannerData, setBannerData] = useState(true)
    const [loader, setLoader] = React.useState(false);
    const [initialPage, setInitialPage] = useState("data");
    const [ProductDetail, setProductDetail] = useState();
    const [country, setCountry] = useState();
    const [category, setCategory] = useState();
    const [brand, setBrand] = useState("");

    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const brandDebounceQuery = useDebounce(brand, 1000);

    // Travel module step reset to 0
    useEffect(() => {
        dispatch(setFlightScreenStep(0));
        dispatch(setFlightDetails(null));
    }, [])

    useEffect(() => {
        let sm = (page - 1) * limit
        setSkip(sm)
    }, [page]);

    useEffect(() => {
        setPage(1);
    }, [brandDebounceQuery, category, country]);

    const initialData = () => {
        setInitialPage(true)
        setBannerData(true)
    }



    const { isLoading, isError, error, data, } = useQuery(['vouchers_list', limit, skip, country, category, brandDebounceQuery], () => newGetVoucherTotal(limit, skip, country, category, brandDebounceQuery));


    return (
        <>
            <Stack direction="row" className='mobileStack' spacing={2} sx={{ width: '100%', marginLeft: '0px !important' }} >

                {initialPage &&
                    <Box className={bannerData ? 'commonw50 mobileBox' : 'commonw50 mobileBox mobileIndex'} p={2}>

                        <PointsComponent />

                        <h1 align='center' className="mt-4"> Get the best from your <strong>Favourite Brands!</strong></h1>
                        <VoucherFilter
                            country={country} setCountry={setCountry}
                            category={category} setCategory={setCategory}
                            brand={brand} setBrand={setBrand}
                        />
                        <>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                {(isLoading ? Array.from(new Array(6)) : data?.data?.result)?.map((current, index) => {
                                    return (
                                        <Grid key={index} item xs={12} sm={6} md={6} lg={6}
                                            onClick={() => {
                                                setBannerData(false);
                                                setProductDetail(current)
                                            }} >
                                            <VoucherCard
                                                title={current?.giftcard?.default_title}
                                                imgUrl={current?.giftcard?.images?.[0]?.file_url}
                                                type={current?.giftcard?.giftcard_value_type}
                                                item={current?.giftcard}
                                            />
                                        </Grid>
                                    )
                                })}

                            </Grid>

                            {data?.data?.result?.length > 0 && !isLoading ?
                                <CommonPagination limit={limit} setPage={setPage} setSkip={setSkip} total={data?.data?.count[0]?.count} page={page} setLimit={setLimit} /> :
                                <h5 align='center' className="mt-4 text-error">{!isLoading ? "Product not found" : ""}</h5>}
                        </>
                    </Box>}

                <Box className='commonw50 mobileBox' style={{ marginLeft: '0px' }}>
                    {bannerData ? initialPage &&
                        <BannerComponent
                            heading={"Welcome to the"}
                            text={settingState?.voucher?.voucher_banner_title ? settingState?.voucher?.voucher_banner_title : "World of Endless Rewards!"}
                            banner={vouchersidebanner}
                        /> :
                        <>  <Box className='mobileDetailAppBar' sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
                            <ButtonAppBar initialData={initialData} text="Voucher" />
                        </Box>
                            <VoucherDetail
                                title={ProductDetail?.giftcard?.default_title}
                                imgUrl={ProductDetail?.giftcard?.images?.[0]?.file_url}
                                open={loader}
                                setOpen={setLoader}
                                ProductDetail={ProductDetail?.giftcard}
                                item={ProductDetail}
                            />
                        </>
                    }
                </Box>
            </Stack>
        </>
    )
}

export default VoucherContainer
