import { GET_HOTEL_LIST_FAILURE, GET_HOTEL_LIST_REQUEST, GET_HOTEL_LIST_SUCCESS, SET_HOTEL_DETAILS, SET_HOTEL_SCREEN_STEP, SET_PASSENGER_DETAILS, SET_SELECTED_HOTEL_DETAILS } from "./hotelType";

const initialWeightState = {
  loading: false,
  step: 0,
  hotelDetails: null,
  selectedHotelDetails: null,
  passengerDetails: null,
  hotelsList: [],
  error: "",
};

const hotelReducer = (state = initialWeightState, action) => {
  switch (action.type) {

    case SET_HOTEL_SCREEN_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case SET_HOTEL_DETAILS:
      return {
        ...state,
        hotelDetails: action.payload,
      };

    case SET_SELECTED_HOTEL_DETAILS:
      return {
        ...state,
        selectedHotelDetails: action.payload,
      };

    case SET_PASSENGER_DETAILS:
      return {
        ...state,
        passengerDetails: action.payload,
      };

    case GET_HOTEL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case GET_HOTEL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hotelsList: action.payload,
        error: "",
      };
    case GET_HOTEL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        hotelsList: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
export default hotelReducer;
