
import { Avatar, Box, Button, Stack, FormControl, MenuItem, Select, TextField, Typography, Checkbox } from '@mui/material'

import React, { useState, useEffect } from 'react'
import PaymentTab from "../drawer/PaymentTab";
import CommonDrawer from '../common/CommonDrawer';
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import image1 from '../../assets/img/Travel/Hotel/Castle.jpg'
import image2 from '../../assets/img/Travel/Hotel/01.jpg'
import image3 from '../../assets/img/Travel/Hotel/02.jpg'
import image4 from '../../assets/img/Travel/Hotel/04.jpg'
import image5 from '../../assets/img/Travel/Hotel/05.jpg'
import image6 from '../../assets/img/Travel/Hotel/07.jpg'
import useStyle from '../drawer/PaymentDrawerStyle'
import { Link } from "react-router-dom";
import moment from 'moment'
import { useSelector } from "react-redux";
import { calculate_age, getAge, dateFormat, last18YearRange } from "../../utils/constantFunctions";
import planeLogo from "../../assets/img/Travel/webp/car1.webp"
import { Container } from '@mui/system';


const HotelDetails = ({ setHotelDetails, fields }) => {
    const bgImgArray = [image1, image2, image3, image4, image5, image6];
    const todaysDate = moment(new Date()).format('YYYY-MM-DD');
    const classes = useStyle();
    const hotelDetailss = useSelector((state) => state?.hotelState?.hotelDetailss);
    const [card, setCard] = useState(bgImgArray[0]);
    const [PayNowOpen, setPayNowOpen] = useState(false);
    const [bookNowOpen, setbookNowOpen] = useState(false);
    const [passengerDetails, setPassengerDetails] = useState(false)
    const [passengerReview, setPassengerReview] = useState(false)
    const [headCount, setHeadCount] = useState([]);

    const [error, setError] = useState({
        title: "",
        fname: "",
        lname: "",
        dob: "",
        email: "",
        nationality: "",
        pasport_no: "",
        gender: ""

    });
    useEffect(() => {
        let collectiveCount = []
        for (let i = 1; i <= Number(fields?.adult); i++) {
            collectiveCount.push({
                id: i,
                title: "",
                fname: "",
                lname: "",
                dob: new Date(),
                email: "",
                nationality: "",
                pasport_no: "",
                personType: "Adult",
                gender: ""
            })
        }
        for (let i = 1; i <= Number(fields?.child); i++) {
            collectiveCount.push({
                id: Number(fields?.adult) + i,
                title: "",
                fname: "",
                lname: "",
                dob: new Date(),
                email: "",
                nationality: "",
                pasport_no: "",
                personType: "Child",
                gender: ""
            })
        }
        setHeadCount(collectiveCount)
    }, [hotelDetailss]);

    const titleSelectHandler = (e) => {
        const id = e.target.name;
        const value = e.target.value;
        const listArr = [...headCount];

        listArr && listArr.map((item, index) => {
            if (item.id === id) {
                listArr[index].title = value
            }
        })
        setHeadCount(listArr);
    }
    const fristNameHandler = (e) => {
        const id = e.target.name;
        const value = e.target.value;
        const listArr = [...headCount];

        listArr && listArr.map((item, index) => {
            if (item.id === parseInt(id)) {
                listArr[index].first_name = value
            }
        })
        setHeadCount(listArr);
    };
    const lastNameHandler = (e) => {
        const id = e.target.name;
        const value = e.target.value;
        const listArr = [...headCount];

        listArr && listArr.map((item, index) => {
            if (item.id === parseInt(id)) {
                listArr[index].last_name = value
            }
        })
        setHeadCount(listArr);
    };
    const genderHandler = (e) => {
        const id = e.target.name;
        const value = e.target.value;
        const listArr = [...headCount];

        listArr && listArr.map((item, index) => {
            if (item.id === parseInt(id)) {
                listArr[index].gender = value
            }
        })
        setHeadCount(listArr);
    };

    const birthDateHandler = (e) => {
        const id = e.target.name;
        const value = e.target.value;
        const listArr = [...headCount];

        listArr && listArr.map((item, index) => {
            if (item.id === parseInt(id)) {
                listArr[index].date_of_birth = moment(new Date(value)).format("YYYY-MM-DD");
            }
        })
        setHeadCount(listArr);
    };
    const emailHandler = (e) => {
        const id = e.target.name;
        const value = e.target.value;
        const listArr = [...headCount];

        listArr && listArr.map((item, index) => {
            if (item.id === parseInt(id)) {
                listArr[index].email = value.toLowerCase()
            }
        })
        setHeadCount(listArr);
    }
    const nationalitySelectHandler = (e) => {
        const id = e.target.name;
        const value = e.target.value;
        const listArr = [...headCount];

        listArr && listArr.map((item, index) => {
            if (item.id === parseInt(id)) {
                listArr[index].nationality = value
            }
        })
        setHeadCount(listArr);
    };

    const passportNoNameHandler = (e) => {
        const id = e.target.name;
        const value = e.target.value;
        const listArr = [...headCount];

        listArr && listArr.map((item, index) => {
            if (item.id === parseInt(id)) {
                listArr[index].pasport_no = value.toUpperCase();
            }
        })
        setHeadCount(listArr);
    };
    const validation = () => {
        let arr = [...headCount]
        let valTital = arr && arr.every((v) => v.title);
        let valFirstName = arr && arr.every((v) => v.first_name);
        let valLastName = arr && arr.every((v) => v.last_name);
        let valDoB = arr && arr.every((v) => v.date_of_birth);
        let valEmail = arr && arr.every((v) => v.email);
        let valNationality = arr && arr.every((v) => v.nationality);
        let valPassportNo = arr && arr.every((v) => v.pasport_no);

        if (!valTital) {
            setError({ ...error, title: "Please select title" });
            return false;
        } else if (!valFirstName) {
            setError({ ...error, first_name: "Please enter first name" });
            return false;
        } else if (!valLastName) {
            setError({ ...error, last_name: "Please enter last name" });
            return false;
        } else if (!valDoB) {
            setError({ ...error, date_of_birth: "Please select date of birth" });
            return false;
        } else if (!valEmail) {
            setError({ ...error, email: "Please enter correct email address" });
            return false;
        } else if (!valNationality) {
            setError({ ...error, nationality: "Please select nationality" });
            return false;
        } else if (!valPassportNo) {
            setError({ ...error, pasport_no: "Please enter passport number" });
            return false;
        }

        return true;
    }
    const proceedPayHandler = () => {
        setPayNowOpen(true);
        setPassengerReview(false);
    }

    const passengerReviewHandler = () => {
        let val = validation();
        if (val) {
            setPassengerDetails(false);
            setPassengerReview(true);
        }
    }

    const backButtonHandler = () => {
        setPassengerReview(false);
        setPassengerDetails(true);
    }


    const list = (anchor) => (
        <>
            <Box
                style={{ margin: '20px 0px 20px 0px' }}
                sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
                role="presentation">
                <Container maxWidth="sm">
                    <Stack direction="row" spacing={2} style={{ paddingLeft: '0px' }}>
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                        <Typography
                            variant="h6"
                            component="h6"
                            className={classes.username}
                        >
                            John
                        </Typography>
                    </Stack>
                </Container>
            </Box>
            <Box sx={{ bgcolor: "#ffffff", width: "100%" }}>
                <PaymentTab amount={1000}/>
            </Box>

        </>
    );

    return (
        <div className='hotel-details position-fixed overflow-auto px-md-5 px-3 py-3 mobilemt-3'>
            <div className='d-flex align-items-center back' onClick={() => setHotelDetails(true)}>
                <span className='arrowBack d-flex align-items-center justify-content-center me-2'>
                    <ChevronLeftTwoToneIcon />
                </span>
                <span>Back</span>
            </div>
            <Stack direction="row" sx={{ width: '100%', flexDirection: { xs: 'column', sm: 'row' } }} >
                <Box flex={10} className="mt-4" >
                    <Box flex={12} className="">
                        <div>
                            <img src={
                                card
                            }
                                alt="logo"
                                className="logo" />
                        </div>
                    </Box>
                </Box>
                <Box flex={2}>
                    <Box className='hiddenDiv' sx={{ display: { xs: 'flex', sm: 'block' }, width: { xs: '100%', sm: '200px' } }}>
                        {
                            [1, 2, 3, 4, 5, 6].map((curr, index) => {
                                return (
                                    <Box flex={12} className='subBanner' style={{ marginTop: `${curr === 1} && '0px'` }} onClick={() => setCard(bgImgArray[index])}>
                                        <div>
                                            <img src={
                                                bgImgArray[index]
                                            }
                                                alt="logo"
                                                className='sublogo' />
                                        </div>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Stack>
            <div className='hotel-details-line my-4'></div>
            <div className='d-flex align-items-start justify-content-between mobileHeader'>
                <div>
                    <span className='hotel-details-title'>Atlantic View Boutique Country</span>
                    <div className='d-flex align-items-center'><StarRoundedIcon />4.84 (96)</div>
                    <div className='hotel-details-text'><span className='me-1'>14 guests</span> | <span className='mx-1'>7 bedrooms</span> | <span className='mx-1'>7 beds</span> | <span className='ms-1'>5.5 bathrooms</span></div>
                </div>
                <div className='d-flex flex-column align-itemsEnd marginTop'>
                    <span className='mb-2 hotel-details-price'>AED 315</span>
                    <button className='search-travel-btn' onClick={() => setPassengerDetails(true)}>Buy Now</button>
                </div>
            </div>
            <div className='hotel-details-line my-4'></div>
            <div>
                <div className='d-flex flex-column mb-2'>
                    <span className='hotel-details-text'>Molly and Ward is a Superhost</span>
                    <span className='hotel-details-text2'>Superhosts are experienced, highly rated hosts who are committed to providing great stays for thier guests.</span>
                </div>
                <div className='d-flex flex-column mb-2'>
                    <span className='hotel-details-text'>Furry friends welcome</span>
                    <span className='hotel-details-text2'>Bring your pets along for stay.</span>
                </div>
                <div className='d-flex flex-column mb-2'>
                    <span className='hotel-details-text'>Free cancellation for 48 hours.</span>
                </div>
            </div>
            <div className='hotel-details-line my-4'></div>
            <div className='d-flex flex-column'>
                <span className='hotel-details-text3'>Aircover</span>
                <span className='my-2'>Every booking includes free protection from Host cancellations, listing inaccuracies, and other issues like trouble checking in.</span>
                <Link className='hotel-details-text' to="/travel">Learn More</Link>
            </div>
            <div className='hotel-details-line my-4'></div>
            <div className='d-flex flex-column'>
                <span>200 year old boutique country house set on 9 acers of land overlooking one of Ireland's best surf breaks, Have a relaxing evening by the open fireplace, a glass of wine in the private bar or a game of pool.</span>
                <span className='hotel-details-text'>**please note booking instructions below**</span>
                <span className='hotel-details-text3 my-2'>The Space</span>
                <span>**BOOKING INSTRUCTIONS: please send us a message and indicate whether you are</span>
                <Link className='hotel-details-text' to="/travel">Show More</Link>
            </div>
            <div className='hotel-details-line my-4'></div>
            <div className='d-flex flex-column'>
                <span className='hotel-details-text3'>What this place offers</span>
                <div className='hotel-details-offer mt-3 mb-5 px-3 pt-4'>
                    <div className='d-flex flex-column mb-4'>
                        <span className='hotel-details-text'>Bathroom</span>
                        <div className='mt-3'>
                            <div>
                                <div className='hotel-details-text4'>Hair dryer</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Hot Water</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <span className='hotel-details-text'>Bedroom and laundary</span>
                        <div className='mt-3'>
                            <div>
                                <div className='hotel-details-text4'>Washing Machine</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Dryer</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Essentials</div>
                                <span className='hotel-details-text5'>Towels, bed sheets, soap and toilet paper</span>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Bed linen</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <span className='hotel-details-text'>Entertainment</span>
                        <div className='mt-3'>
                            <div>
                                <div className='hotel-details-text4'>TV</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Suitable for events</div>
                                <span className='hotel-details-text5'>These listing can accomodate gathering of 25 or more attendees</span>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <span className='hotel-details-text'>Heating and cooling</span>
                        <div className='mt-3'>
                            <div>
                                <div className='hotel-details-text4'>Indoor fireplace</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Heating</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <span className='hotel-details-text'>Home Safety</span>
                        <div className='mt-3'>
                            <div>
                                <div className='hotel-details-text4'>Smoke alarm</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Carbon monoxide alarm</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Fire extinguisher</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>First aid kit</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <span className='hotel-details-text'>Internet and office</span>
                        <div className='mt-3'>
                            <div>
                                <div className='hotel-details-text4'>Wifi</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Dedicated workspace</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <span className='hotel-details-text'>Kitchen and dining</span>
                        <div className='mt-3'>
                            <div>
                                <div className='hotel-details-text4'>Kitchen</div>
                                <div className='hotel-details-text5'>Space where guests can cook their own meals</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Refrigerator</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Microwave</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Cooking basics</div>
                                <div className='hotel-details-text5'>Pots and pans, oil, salt and pepper</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Dishes and silverware</div>
                                <div className='hotel-details-text5'>Bowls, chopsticks, plates, cups, etc.</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Dish Washer</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Stove</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                            <div>
                                <div className='hotel-details-text4'>Oven</div>
                                <div className='hotel-details-offer-line my-3'></div>
                            </div>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <span className='hotel-details-text'>Location features</span>
                            <div className='mt-3'>
                                <div>
                                    <div className='hotel-details-text4'>Private entrance</div>
                                    <div className='hotel-details-text5'>Seperate street or building entrance</div>
                                    <div className='hotel-details-offer-line my-3'></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <span className='hotel-details-text'>Outdoor</span>
                            <div className='mt-3'>
                                <div>
                                    <div className='hotel-details-text4'>Garden</div>
                                    <div className='hotel-details-text5'>An open space on the property usually covered in grass</div>
                                    <div className='hotel-details-offer-line my-3'></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <span className='hotel-details-text'>Parking and facilities</span>
                            <div className='mt-3'>
                                <div>
                                    <div className='hotel-details-text4'>Free parking on premises</div>
                                    <div className='hotel-details-offer-line my-3'></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <span className='hotel-details-text'>Services</span>
                            <div className='mt-3'>
                                <div>
                                    <div className='hotel-details-text4'>Pets allowed</div>
                                    <div className='hotel-details-text4'>Assistance animals are always allowed</div>
                                    <div className='hotel-details-offer-line my-3'></div>
                                </div>
                                <div>
                                    <div className='hotel-details-text4'>Breakfast</div>
                                    <div className='hotel-details-text4'>Breakfast is provided</div>
                                    <div className='hotel-details-offer-line my-3'></div>
                                </div>
                                <div>
                                    <div className='hotel-details-text4'>Smoking allowed</div>
                                    <div className='hotel-details-offer-line my-3'></div>
                                </div>
                                <div>
                                    <div className='hotel-details-text4'>Host greets you</div>
                                    <div className='hotel-details-offer-line my-3'></div>
                                </div>
                                <div>
                                    <div className='hotel-details-text4'>Long-term stays allowed</div>
                                    <div className='hotel-details-text5'>Allow stays of 28 days or more</div>
                                    <div className='hotel-details-offer-line my-3'></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <span className='hotel-details-text'>Not included</span>
                            <div className='mt-3'>
                                <div>
                                    <div className='hotel-details-text4'><s>Air conditioning</s></div>
                                    <div className='hotel-details-offer-line my-3'></div>
                                </div>
                                <div>
                                    <div className='hotel-details-text4'><s>Shampoo</s></div>
                                    <div className='hotel-details-offer-line my-3'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CommonDrawer onClose={setPassengerDetails} open={passengerDetails}>

                <div className="bookFlight d-flex flex-column">
                    <h4>Passenger Details</h4>

                    <div className="d-flex w-100 columnReverse">

                        <div className="mt-2 w-60 mobileBox">
                            <div className='mb-4'>
                                {headCount && headCount.map((items, i) => {
                                    return (
                                        <div key={i} >
                                            <span className="fw-bold">{items?.personType} Passenger {i + 1}:</span>
                                            <div className="mobileBlock mt-2">
                                                <FormControl className="me-3" sx={{ minWidth: 120 }} size="small">
                                                    <label className="mb-2">Title</label>
                                                    <Select labelId="demo-select-small" style={{ padding: 2 }}
                                                        id={i}
                                                        value={items.title}
                                                        onChange={titleSelectHandler}
                                                        name={items.id}>
                                                        <MenuItem value={10}>Mr</MenuItem>
                                                        <MenuItem value={20}>Mrs</MenuItem>
                                                        <MenuItem value={30}>Ms</MenuItem>
                                                    </Select>
                                                    <label className="text-error">{!items.title && error.title}</label>
                                                </FormControl>
                                                <FormControl fullWidth className="me-3">
                                                    <label className="mb-2">First Name</label>
                                                    <TextField
                                                        inputProps={{ inputMode: "text", style: { padding: 10 } }} id={i}
                                                        name={items.id}
                                                        onChange={fristNameHandler}
                                                        value={items.first_name}
                                                    />
                                                    <label className="text-error">{!items.first_name && error.first_name}</label>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <label className="mb-2">Last Name</label>
                                                    <TextField
                                                        inputProps={{ inputMode: "text", style: { padding: 10 } }} id={i}
                                                        name={items.id}
                                                        onChange={lastNameHandler}
                                                        value={items.last_name}
                                                    />
                                                    <label className="text-error">{!items.last_name && error.last_name}</label>
                                                </FormControl>
                                            </div>
                                            <div className="mt-2 mobileBlock">
                                                <FormControl fullWidth className="me-3">
                                                    <label className="mb-2">Date of Birth</label>
                                                    <TextField
                                                        labelId="dateDeparture"
                                                        type="date"
                                                        defaultValue={last18YearRange()}

                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ style: { padding: 10 } }}
                                                        name={items.id}
                                                        id={i}
                                                        dateFormat="YYYY-MM-DD"
                                                        onChange={birthDateHandler}
                                                    />
                                                    <label className="text-error">{!items.date_of_birth && error.date_of_birth}</label>
                                                </FormControl>

                                                <FormControl fullWidth className="me-3">
                                                    <label className="mb-2">Email Id</label>
                                                    <TextField
                                                        inputProps={{ inputMode: " email", style: { padding: 10 } }}
                                                        id={i}
                                                        name={items.id}
                                                        value={items.email}
                                                        onChange={emailHandler}
                                                    />
                                                    <label className="text-error">{!items.email && error.email}</label>
                                                </FormControl>
                                                <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                                                    <label className="mb-2">Nationality</label>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        style={{ padding: 2 }}
                                                        id={i}
                                                        name={items.id}
                                                        value={items.nationality}
                                                        onChange={nationalitySelectHandler}
                                                    >
                                                        <MenuItem value={10}>Indian</MenuItem>
                                                        <MenuItem value={20}>Pakistan</MenuItem>
                                                        <MenuItem value={30}>Dubai</MenuItem>
                                                    </Select>
                                                    <label className="text-error">{!items.nationality && error.nationality}</label>
                                                </FormControl>
                                            </div>

                                            <div className="mt-2 mb-3 mobileBlock">
                                                <FormControl fullWidth className="me-3 w-50">
                                                    <label className="mb-2">Passport/ID Number</label>
                                                    <TextField
                                                        inputProps={{ inputMode: "number", style: { padding: 10 } }}
                                                        id={i}
                                                        name={items.id}

                                                        onChange={passportNoNameHandler}
                                                        value={items.pasport_no}
                                                    />
                                                    <label className="text-error">{!items.pasport_no && error.pasport_no}</label>
                                                </FormControl>
                                                <FormControl className="me-3" sx={{ minWidth: 120 }} size="small">
                                                    <label className="mb-2">Gender</label>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        value={items.gender}
                                                        style={{ padding: 2 }}
                                                        id={i}
                                                        name={items.id}
                                                        onChange={genderHandler}
                                                    >
                                                        <MenuItem value={"Male"}>Male</MenuItem>
                                                        <MenuItem value={"Female"}>Female</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    )
                                })
                                }


                            </div>
                            <Button
                                onClick={() => passengerReviewHandler()}
                                variant="contained"
                                className="backcolorPrimary my-4"
                                style={{ textTransform: "initial" }}
                            >
                                Review & Confirm
                            </Button>
                        </div>

                        <div className="w-40  d-flex justify-content-center mobileBox">
                            <div className="border w-90 width300 p-3 mobileBox">
                                <span className="fw-bold">Itinerary</span>

                                <div className="d-flex flex-column  mt-3">
                                    <div className="d-flex"></div>

                                    <table className="mt-3 mx-auto">
                                        <tr className="d-flex">
                                            <td className="w-50">
                                                <span>Stay</span>
                                            </td>
                                            <td className="w-50">
                                                <span>2 nights</span>
                                            </td>
                                        </tr>
                                        <tr className="mt-3 d-flex justify-content-between">
                                            <td className="d-flex px-3 flex-column py-3 bg-grey">
                                                <img
                                                    className="my-2"
                                                    width={100}
                                                    src={image2}
                                                    alt=""
                                                />
                                                <span className="my-2 fsc-5">Atlantic View Boutique Country</span>
                                                <span className="my-2 fsc-5">Room #106</span>
                                            </td>
                                            <td className="d-flex ms-1 px-4 flex-column py-3 bg-grey">
                                                <div>
                                                    <p className="my-2 fsc-5">Deluxe Double Room</p>
                                                    <p className="my-2 fsc-5">
                                                        Check-in Date: 29-09-2022{" "}
                                                    </p>
                                                    <p className="my-2 fsc-5">Check-in Time: 1:05am</p>
                                                    <p className="my-2 fsc-5">
                                                        Check-out Date: 31-09-2022{" "}
                                                    </p>
                                                    <p className="my-2 fsc-5">Check-out Time: 1:05am</p>
                                                </div>
                                                <div className="mt-2">
                                                    <p className="my-2 fsc-5">₹7,740/Night</p>
                                                    <p className="my-2 fsc-5">Free cancellation </p>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonDrawer>
            <CommonDrawer onClose={passengerReviewHandler} open={passengerReview}>
                {console.log(headCount, "hello")}
                <div className="passengerDetailDrwaer m-5 d-flex flex-column">
                    <div className="ms-4">
                        <h4 className="mb-4">Review & Confirm</h4>
                        <span className="fw-bold">Passenger Details</span>
                        <div className="table-responsive">
                            <table className=" table mt-3 w-100 passengertable">
                                <tr>
                                    <td className="bg-dark-grey py-2 px-3 fsc-5"> Details</td>
                                    <td className="bg-dark-grey py-2 px-3 fsc-5">
                                        {" "}
                                        Passenger Type
                                    </td>
                                    <td className="bg-dark-grey py-2 px-3 fsc-5">
                                        {" "}
                                        Passenger Name
                                    </td>
                                    <td className="bg-dark-grey py-2 px-3 fsc-5"> Gender</td>
                                    <td className="bg-dark-grey py-2 px-3 fsc-5"> Age</td>
                                    <td className="bg-dark-grey py-2 px-3 fsc-5">
                                        {" "}
                                        Passport Number
                                    </td>
                                </tr>
                                {
                                    headCount && headCount.map((items, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="bg-grey py-2 px-3 fsc-5">{items.title}</td>
                                                <td className="bg-grey py-2 px-3 fsc-5">{items.personType}</td>
                                                <td className="bg-grey py-2 px-3 fsc-5">{items.first_name} {items.last_name}</td>
                                                <td className="bg-grey py-2 px-3 fsc-5">{items.gender}</td>
                                                <td className="bg-grey py-2 px-3 fsc-5">{getAge(items.date_of_birth)}</td>
                                                <td className="bg-grey py-2 px-3 fsc-5">{items.pasport_no}</td>
                                            </tr>
                                        )
                                    })
                                }

                            </table>
                        </div>
                    </div>
                    <div className="ms-4 mt-3">
                        <div className="d-flex justify-content-between">
                            <span className="fw-bold">Deluxe Double Room</span>
                            {/* <span className="fw-bold">Class: Economy</span> */}
                        </div>
                        <div className="table-responsive">
                            <table className="table mt-3 w-100 passengertable">
                                <tr>
                                    <td className="bg-dark-grey py-2 px-3 fsc-5">Hotel</td>
                                    <td className="bg-dark-grey py-2 px-3 fsc-5"> Room </td>
                                    <td className="bg-dark-grey py-2 px-3 fsc-5"> Check-in</td>
                                    <td className="bg-dark-grey py-2 px-3 fsc-5"> Check-out</td>
                                    <td className="bg-dark-grey py-2 px-3 fsc-5"> Type</td>
                                </tr>
                                <tr>
                                    <td className="bg-grey py-2 px-3 fsc-5">
                                        <img width={50} src={image3} alt="" />
                                        <br />
                                        <span>Atlantic View Boutique Country</span>
                                        <br />
                                        <span>Room #106</span>
                                    </td>
                                    <td className="bg-grey py-2 px-3 fsc-5">
                                        Deluxe Double Room
                                    </td>
                                    <td className="bg-grey py-2 px-3 fsc-5">
                                        29-09-22 <br /> 11:00am
                                    </td>
                                    <td className="bg-grey py-2 px-3 fsc-5">
                                        29-09-22 <br /> 11:00am
                                    </td>
                                    <td className="bg-grey py-2 px-3 fsc-5">
                                        AC
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div className="ms-4 mt-3">
                        <Checkbox
                            className="m-0 p-0"
                            checked={true}
                            style={{ color: "var(--primayColor)" }}
                        />
                        <span className="fsc-5">
                            I have read and agree to the Terms & Conditions & the Booking
                            Cancellation policy of the respective service provide.
                        </span>
                    </div>
                    <div className="ms-4 mt-3">
                        <Button
                            className="my-1 bg-white"
                            style={{
                                color: "var(--primayColor)",
                                borderColor: "var(--primayColor)",
                                fontWeight: "bold",
                                textTransform: "initial",
                            }}
                            variant="outlined"
                            onClick={() => backButtonHandler()}
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => proceedPayHandler()}
                            variant="contained"
                            className="backcolorPrimary my-5 ms-4"
                            style={{ textTransform: "initial" }}
                        >
                            Review & Confirm
                        </Button>
                    </div>
                </div>
            </CommonDrawer>
            <CommonDrawer onClose={setPayNowOpen} open={PayNowOpen}>
                {list("right")}
            </CommonDrawer>
        </div>
    )
}

export default HotelDetails