/* eslint-disable array-callback-return */
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import planeLogo from "../../assets/img/Travel/Qatar_Airways_logo-removebg-preview.png";
import TuneIcon from "@mui/icons-material/Tune";
import CommonDrawer from "../common/CommonDrawer";
import { useState } from "react";
import RangeSlider from "../common/RangeSlider";
import moment from "moment";
import { Container, Stack } from "@mui/system";
import PaymentTab from "../drawer/PaymentTab";
import useStyle from "../drawer/PaymentDrawerStyle";
import tick from "../../assets/img/Travel/tick.png";
import "./flights.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getFlightList,
  setFlightScreenStep,
  setPassengerDetailsRedux,
} from "../../redux/Flight/flightAction";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DatePicker from "../DatePicker/DatePicker";
import {
  calculate_age,
  dateFormat,
  last18YearRange,
} from "../../utils/constantFunctions";
import Sorted_By from "./Sorted_By";

const FlightResult = () => {
  const dispatch = useDispatch();
  const classes = useStyle();

  const List = [1, 2, 3, 4, 5, 6, 7, 8, 9, 7, 8];

  const step = useSelector((state) => state?.flightState?.step);
  const flightDetails = useSelector(
    (state) => state?.flightState?.flightDetails
  );
  const passengerDetailsState = useSelector(
    (state) => state?.flightState?.passengerDetails
  );

  const [filterOpen, setfilterOpen] = useState(false);
  const [bookNowOpen, setbookNowOpen] = useState(false);
  const [proceedPayOpen, setproceedPayOpen] = useState(false);
  const [PayNowOpen, setPayNowOpen] = useState(false);
  const [flightdetails, setflightdetails] = useState("none");
  const [passengerDetails, setPassengerDetails] = useState([]);
  const [termsConditionsFlag, settermsConditionsFlag] = useState(false);

  const todaysDate = moment(new Date()).format("YYYY-MM-DD");
  const [thankYou, setthankYou] = useState(false);

  const [error, setError] = useState({
    termsConditionsFlag: "",
    title: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    nationality: "",
    passport_number: "",
    passport_issue_date: "",
    passport_expiry_date: "",
  });

  useEffect(() => {
    if (step === 2) {
      setbookNowOpen(true);
    } else if (step === 3) {
      setproceedPayOpen(true);
      setbookNowOpen(false);
    } else if (step === 4) {
      setPayNowOpen(true);
      setproceedPayOpen(false);
    }
  }, [step]);

  useEffect(() => {
    let passengerListArr = [];
    for (let i = 1; i <= Number(flightDetails?.adult); i++) {
      passengerListArr.push({
        id: i,
        title: "",
        first_name: "",
        last_name: "",
        date_of_birth: new Date(),
        gender: "",
        nationality: "",
        passport_number: "",
        passport_issue_date: new Date(),
        passport_expiry_date: new Date(),
        passenger_Type: "Adult",
      });
    }
    for (let i = 1; i <= Number(flightDetails?.child); i++) {
      passengerListArr.push({
        id: Number(flightDetails?.adult) + i,
        title: "",
        first_name: "",
        last_name: "",
        date_of_birth: new Date(),
        gender: "",
        nationality: "",
        passport_number: "",
        passport_issue_date: new Date(),
        passport_expiry_date: new Date(),
        passenger_Type: "Child",
      });
    }
    setPassengerDetails(passengerListArr);
  }, [flightDetails]);

  useEffect(() => {
    passengerDetailsState && setPassengerDetails(passengerDetailsState);
  }, [passengerDetailsState]);

  const titleSelectHandler = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    const listArr = [...passengerDetails];

    listArr &&
      listArr.map((item, index) => {
        if (item.id === id) {
          listArr[index].title = value;
        }
      });
    setPassengerDetails(listArr);
  };

  const nationalitySelectHandler = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    const listArr = [...passengerDetails];

    listArr &&
      listArr.map((item, index) => {
        if (item.id === parseInt(id)) {
          listArr[index].nationality = value;
        }
      });
    setPassengerDetails(listArr);
  };

  const genderSelectHandler = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    const listArr = [...passengerDetails];

    listArr &&
      listArr.map((item, index) => {
        if (item.id === parseInt(id)) {
          listArr[index].gender = value;
        }
      });
    setPassengerDetails(listArr);
  };

  const fristNameHandler = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    const listArr = [...passengerDetails];

    listArr &&
      listArr.map((item, index) => {
        if (item.id === parseInt(id)) {
          listArr[index].first_name = value;
        }
      });
    setPassengerDetails(listArr);
  };

  const lastNameHandler = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    const listArr = [...passengerDetails];

    listArr &&
      listArr.map((item, index) => {
        if (item.id === parseInt(id)) {
          listArr[index].last_name = value;
        }
      });
    setPassengerDetails(listArr);
  };

  const passportNoNameHandler = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    const listArr = [...passengerDetails];

    listArr &&
      listArr.map((item, index) => {
        if (item.id === parseInt(id)) {
          listArr[index].passport_number = value.toUpperCase();
        }
      });
    setPassengerDetails(listArr);
  };

  const birthDateHandler = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    const listArr = [...passengerDetails];

    listArr &&
      listArr.map((item, index) => {
        if (item.id === parseInt(id)) {
          listArr[index].date_of_birth = moment(new Date(value)).format(
            "YYYY-MM-DD"
          );
        }
      });
    setPassengerDetails(listArr);
  };

  const passportIssueDateHandler = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    const listArr = [...passengerDetails];

    listArr &&
      listArr.map((item, index) => {
        if (item.id === parseInt(id)) {
          listArr[index].passport_issue_date = value;
        }
      });
    setPassengerDetails(listArr);
  };

  const passportExpiryDateHandler = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    const listArr = [...passengerDetails];

    listArr &&
      listArr.map((item, index) => {
        if (item.id === parseInt(id)) {
          listArr[index].passport_expiry_date = value;
        }
      });
    setPassengerDetails(listArr);
  };

  const bookFlightHandler = () => {
    setbookNowOpen(true);
    dispatch(setFlightScreenStep(2));
  };

  const validation = () => {
    let arr = [...passengerDetails];
    let valTital = arr && arr.every((v) => v.title);
    let valFirstName = arr && arr.every((v) => v.first_name);
    let valLastName = arr && arr.every((v) => v.last_name);
    let valDoB = arr && arr.every((v) => v.date_of_birth);
    let valGender = arr && arr.every((v) => v.gender);
    let valNationality = arr && arr.every((v) => v.nationality);
    let valPassportNo = arr && arr.every((v) => v.passport_number);
    let valIssueDate = arr && arr.every((v) => v.passport_issue_date);
    let valExpiryDate = arr && arr.every((v) => v.passport_expiry_date);

    if (!valTital) {
      setError({ ...error, title: "Please select title" });
      return false;
    } else if (!valFirstName) {
      setError({ ...error, first_name: "Please enter first name" });
      return false;
    } else if (!valLastName) {
      setError({ ...error, last_name: "Please enter last name" });
      return false;
    } else if (!valDoB) {
      setError({ ...error, date_of_birth: "Please select date of birth" });
      return false;
    } else if (!valGender) {
      setError({ ...error, gender: "Select gender" });
      return false;
    } else if (!valNationality) {
      setError({ ...error, nationality: "Please select nationality" });
      return false;
    } else if (!valPassportNo) {
      setError({ ...error, passport_number: "Please enter passport number" });
      return false;
    } else if (!valIssueDate) {
      setError({
        ...error,
        passport_issue_date: "Please select passport issue date",
      });
      return false;
    } else if (!valExpiryDate) {
      setError({
        ...error,
        passport_expiry_date: "Please select passport expiry date",
      });
      return false;
    }

    return true;
  };

  const proceedPayHandler = () => {
    console.log(passengerDetails, "hey");
    let val = validation();
    if (val) {
      dispatch(setFlightScreenStep(3));
      dispatch(setPassengerDetailsRedux(passengerDetails));
      setproceedPayOpen(true);
      setbookNowOpen(false);
    }
  };

  const proceedPayConfirmHandler = () => {
    if (termsConditionsFlag === false) {
      setError({
        ...error,
        termsConditionsFlag: "Please select Terms & Conditions",
      });
      return false;
    } else {
      setPayNowOpen(true);
      setproceedPayOpen(false);
    }
  };

  const backButtonHandler = () => {
    setproceedPayOpen(false);
    dispatch(setFlightScreenStep(2));
  };

  const list = (anchor) => (
    <>
      <Box
        style={{ margin: "20px 0px 20px 0px" }}
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
        role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
      >
        <Container maxWidth="sm">
          <Stack direction="row" spacing={2} style={{ paddingLeft: "0px" }}>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            <Typography
              variant="h6"
              component="h6"
              className={classes.username}
            >
              John
            </Typography>
          </Stack>
        </Container>
      </Box>
      <Box sx={{ bgcolor: "#ffffff", width: "100%" }}>
        <PaymentTab
          amount={1000}
          setthankYou={setthankYou}
          setPayNowOpen={setPayNowOpen}
        />
      </Box>
    </>
  );

  return (
    <div className="mainFlightresult bg-grey mobileBox">
      <div className="d-flex justify-content-center align-items-center mobilemt-5">
        <div className="d-flex justify-content-between align-items-center w-90">
          <h4 className="m-0 w-100">Total Flight(s) found 80</h4>
          <div className="d-flex justify-content-end w-90">
            <Button
              onClick={() => setfilterOpen(true)}
              className="my-1 bg-white"
              style={{
                color: "var(--primayColor)",
                borderColor: "var(--primayColor)",
                fontWeight: "bold",
              }}
              variant="outlined"
            >
              {" "}
              <TuneIcon
                style={{ marginRight: "10px", transform: "rotate(-90deg)" }}
              />{" "}
              Filter
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{ padding: "2.5rem", paddingTop: "1rem", paddingBottom: "1rem" }}
      >
        {" "}
        <Sorted_By />
      </div>
      <div className="d-flex flex-column align-items-center">
        {List.map((item, index) => {
          return (
            <div
              className="d-flex bg-light-blue w-90 my-3 p-3"
              key={index}
              style={{ backgroundColor: "grey !important" }}
            >
              <div className="w-100 d-flex flex-column">
                <div className="w-100 d-flex justify-content-between mobileStack500">
                  <div className="d-flex flex-column width-75 mobileBlock">
                    <div className="my-2 d-flex justify-content-between mobileStack500">
                      <div className="py-2 bg-grey w-24 d-flex align-items-center mobileBlock">
                        <Checkbox
                          checked={true}
                          style={{ color: "var(--primayColor)" }}
                        />
                        <img width={85} height={28.3} src={planeLogo} alt="" />
                      </div>
                      <div className="py-2 d-flex flex-column bg-grey width-50 px-3 mobileBlock ">
                        <span>1:05 am - 5:55 am</span>
                        <span>DXB - LHR, QATAR Airways</span>
                      </div>
                      <div className="py-2 d-flex flex-column bg-grey w-24 px-3 mobileBlock">
                        <span>7 h 50m</span>
                        <span>Non Stop</span>
                      </div>
                    </div>
                    <div className="my-2 d-flex justify-content-between mobileStack500">
                      <div className="py-2 bg-grey w-24 d-flex align-items-center mobileBlock">
                        <Checkbox
                          checked={true}
                          style={{ color: "var(--primayColor)" }}
                        />
                        <img width={85} height={28.3} src={planeLogo} alt="" />
                      </div>
                      <div className="py-2 d-flex flex-column bg-grey width-50 px-3 mobileBlock ">
                        <span>1:05 am - 5:55 am</span>
                        <span>DXB - LHR, QATAR Airways</span>
                      </div>
                      <div className="py-2 d-flex flex-column bg-grey w-24 px-3 mobileBlock">
                        <span>7 h 50m</span>
                        <span>Non Stop</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-grey width-22 my-2 d-flex flex-column justify-content-center align-items-center mobileBlock">
                    <span className="colorPrimary fw-bold my-1">AED 1,520</span>
                    <Button
                      onClick={() => bookFlightHandler()}
                      className="my-1 bg-white"
                      style={{
                        color: "var(--primayColor)",
                        borderColor: "var(--primayColor)",
                        fontWeight: "bold",
                        textTransform: "initial",
                      }}
                      variant="outlined"
                    >
                      Book Now
                    </Button>
                    <span
                      onClick={() =>
                        setflightdetails(
                          flightdetails === index ? "none" : index
                        )
                      }
                      className="text-black my-1 cursor-pointer"
                    >
                      Flight Details
                    </span>
                  </div>
                </div>
                {flightdetails === index ? (
                  <div className="d-flex flex-column mt-4">
                    <div className="w-100 d-flex ">
                      <h3 className="fw-bold-c">Dubai (DXB)</h3>
                      <h3 className="ms-4 fw-bold-c">London (LHR)</h3>
                    </div>

                    <span className="fw-bold-c">
                      Thu. 23/09 - Thu, 06/10, Round Trip, 1 Traveller Economy
                    </span>

                    <div className="d-flex mt-4 justify-content-between">
                      <span className="">
                        Departure <span className="ms-3">Thu, 29/09</span>{" "}
                        Non-Stop{" "}
                      </span>
                      <span>Dubai - london</span>
                    </div>

                    <div className="my-2 d-flex justify-content-between mobileStack500">
                      <div className="py-2 bg-grey w-24 d-flex flex-column justify-content-center ps-3 mobileBlock">
                        <img width={100} src={planeLogo} alt="" />
                        <span>Qatar Airways</span>
                      </div>
                      <div className="py-2 d-flex bg-grey width-50 px-3 justify-content-between align-items-center mobileBlock">
                        <div className="d-flex flex-column text-right-c">
                          <span>1:05 am</span>
                          <span>DXB </span>
                          <span>Dubai Airport</span>
                        </div>
                        <div className="dotted-line"></div>
                        <div className="d-flex flex-column mobileBlock">
                          <span>5:55 am</span>
                          <span>LHR </span>
                          <span>Hearhrow Airways</span>
                        </div>
                      </div>
                      <div className="py-2 d-flex flex-column  justify-content-center bg-grey w-24 px-3 mobileBlock">
                        <span>7 h 50m</span>
                        <span>Non Stop</span>
                      </div>
                    </div>
                    <div className="d-flex mt-4 justify-content-between">
                      <span className="">
                        Return <span className="ms-3">Thu, 06/10 </span>Nonstop{" "}
                      </span>
                      <span>Dubai - London</span>
                    </div>

                    <div className="my-2 d-flex justify-content-between">
                      <div className="py-2 bg-grey w-24 d-flex  justify-content-center flex-column ps-3">
                        <img width={100} src={planeLogo} alt="" />
                        <span>Qatar Airways</span>
                      </div>
                      <div className="py-2 d-flex bg-grey w-50 px-3 justify-content-between align-items-center">
                        <div className="d-flex flex-column text-right-c">
                          <span>1:05 am</span>
                          <span>DXB </span>
                          <span>Dubai Airport</span>
                        </div>
                        <div className="dotted-line"></div>
                        <div className="d-flex flex-column">
                          <span>5:55 am</span>
                          <span>LHR </span>
                          <span>Hearhrow Airways</span>
                        </div>
                      </div>
                      <div className="py-2 d-flex flex-column  justify-content-center bg-grey w-24 px-3">
                        <span>7 h 50m</span>
                        <span>Non Stop</span>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      <CommonDrawer onClose={setfilterOpen} open={filterOpen}>
        <div className="filterFlight m-3 d-flex flex-column">
          <h4 className="m-0 mx-5 mt-4 ">Refine your search</h4>
          <span className="mt-5 mx-5 fw-bold colorPrimary">Stops</span>
          <FormGroup className="mx-5 d-flex flex-row ">
            <FormControlLabel
              className="me-5"
              control={<Checkbox style={{ color: "var(--primayColor)" }} />}
              label="0 Stop"
            />
            <FormControlLabel
              className="me-5"
              control={<Checkbox style={{ color: "var(--primayColor)" }} />}
              label="1 Stop"
            />
            <FormControlLabel
              control={<Checkbox style={{ color: "var(--primayColor)" }} />}
              label="2 Stop"
            />
          </FormGroup>
          <div className="mx-5 my-4 border p-3 d-flex flex-wrap justify-content-between rounded">
            <div className="mb-5">
              <span className="colorPrimary fw-bold">Total AED</span>
              <RangeSlider />
            </div>
            <div className="mb-5">
              <span className="colorPrimary fw-bold">Total Duration</span>
              <RangeSlider />
            </div>
            <div>
              <span className="colorPrimary fw-bold">Departure Time</span>
              <RangeSlider hours="hr" />
            </div>
            <div>
              <span className="colorPrimary fw-bold">Arrival Time</span>
              <RangeSlider hours="hr" />
            </div>
          </div>

          <div className="my-4 mx-5 border p-3 rounded">
            <span className="colorPrimary fw-bold">Airlines</span>
            <div className="d-flex flex-wrap w-100">
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="Select All"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="Gulf Air"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
              <FormControlLabel
                className="w-48"
                control={<Checkbox style={{ color: "var(--primayColor)" }} />}
                label="British Airways"
              />
            </div>
          </div>
        </div>
      </CommonDrawer>
      <CommonDrawer onClose={setbookNowOpen} open={bookNowOpen}>
        <div className="bookFlight d-flex flex-column">
          <h4>Passenger Details</h4>
          <div className="d-flex w-100 columnReverse">
            <div className="mt-2 w-60 mobileBox">
              {passengerDetails &&
                passengerDetails.map((item, i) => (
                  <div key={i}>
                    <span className="fw-bold">
                      {item?.passenger_Type} Passenger {i + 1}:
                    </span>
                    <div className="mobileBlock mt-2">
                      <FormControl
                        className="me-3"
                        sx={{ minWidth: 120 }}
                        size="small"
                      >
                        <label className="mb-2">Title</label>
                        <Select
                          labelId="demo-select-small"
                          style={{ padding: 2 }}
                          id={i}
                          value={item.title}
                          onChange={titleSelectHandler}
                          name={item.id}
                        >
                          <MenuItem value={"Mr"}>Mr</MenuItem>
                          <MenuItem value={"Mrs"}>Mrs</MenuItem>
                          <MenuItem value={"Ms"}>Ms</MenuItem>
                        </Select>
                        <label className="text-error">
                          {!item.title && error.title}
                        </label>
                      </FormControl>
                      <FormControl fullWidth className="me-3">
                        <label className="mb-2">First Name</label>
                        <TextField
                          inputProps={{
                            inputMode: "text",
                            style: { padding: 10 },
                          }}
                          id={i}
                          name={item.id}
                          onChange={fristNameHandler}
                          value={item.first_name}
                        />
                        <label className="text-error">
                          {!item.first_name && error.first_name}
                        </label>
                      </FormControl>
                      <FormControl fullWidth>
                        <label className="mb-2">Last Name</label>
                        <TextField
                          inputProps={{
                            inputMode: "text",
                            style: { padding: 10 },
                          }}
                          id={i}
                          name={item.id}
                          onChange={lastNameHandler}
                          value={item.last_name}
                        />
                        <label className="text-error">
                          {!item.last_name && error.last_name}
                        </label>
                      </FormControl>
                    </div>
                    <div className="mt-2 mobileBlock">
                      <FormControl fullWidth className="me-3">
                        <label className="mb-2">Date of Birth</label>
                        <TextField
                          onChange={birthDateHandler}
                          // selected={item?.date_of_birth}
                          // InputProps={{ inputProps: { min: new Date() } }}
                          name={item.id}
                          id={i}
                          type="date"
                          dateFormat="YYYY-MM-DD"
                          defaultValue={last18YearRange()}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ style: { padding: 10 } }}
                        />
                        <label className="text-error">
                          {!item.date_of_birth && error.date_of_birth}
                        </label>
                      </FormControl>
                      <FormControl
                        className="me-3"
                        sx={{ minWidth: 120 }}
                        size="small"
                      >
                        <label className="mb-2">Gender</label>
                        <Select
                          labelId="demo-select-small"
                          value={item.gender}
                          style={{ padding: 2 }}
                          id={i}
                          onChange={genderSelectHandler}
                          name={item.id}
                        >
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                        </Select>
                        <label className="text-error">
                          {!item.gender && error.gender}
                        </label>
                      </FormControl>
                      <FormControl
                        fullWidth
                        sx={{ minWidth: 120 }}
                        size="small"
                      >
                        <label className="mb-2">Nationality</label>
                        <Select
                          labelId="demo-select-small"
                          value={item.nationality}
                          style={{ padding: 2 }}
                          id={i}
                          onChange={nationalitySelectHandler}
                          name={item.id}
                        >
                          <MenuItem value={"Indian"}>Indian</MenuItem>
                          <MenuItem value={"Pakistan"}>Pakistan</MenuItem>
                          <MenuItem value={"Dubai"}>Dubai</MenuItem>
                        </Select>
                        <label className="text-error">
                          {!item.nationality && error.nationality}
                        </label>
                      </FormControl>
                    </div>
                    <div className="mt-2 mb-3 mobileBlock">
                      <FormControl fullWidth className="me-3">
                        <label className="mb-2">Passport Number</label>
                        <TextField
                          inputProps={{
                            inputMode: "number",
                            style: { padding: 10 },
                          }}
                          id={i}
                          name={item.id}
                          onChange={passportNoNameHandler}
                          value={item.passport_number}
                        />
                        <label className="text-error">
                          {!item.passport_number && error.passport_number}
                        </label>
                      </FormControl>
                      <FormControl fullWidth className="me-3">
                        <label className="mb-2">Passport Issue Date</label>
                        <TextField
                          onChange={passportIssueDateHandler}
                          selected={item?.passport_issue_date}
                          // InputProps={{ inputProps: { min: new Date(), max: todaysDate } }}
                          name={item.id}
                          id={i}
                          type="date"
                          defaultValue={todaysDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ style: { padding: 10 } }}
                        />
                        <label className="text-error">
                          {!item.passport_issue_date &&
                            error.passport_issue_date}
                        </label>
                      </FormControl>
                      <FormControl fullWidth>
                        <label className="mb-2">Passport Expiry Date</label>
                        <TextField
                          onChange={passportExpiryDateHandler}
                          selected={item?.passport_expiry_date}
                          // InputProps={{ inputProps: { min: new Date() } }}
                          name={item.id}
                          id={i}
                          type="date"
                          defaultValue={todaysDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ style: { padding: 10 } }}
                        />
                        <label className="text-error">
                          {!item.passport_expiry_date &&
                            error.passport_expiry_date}
                        </label>
                      </FormControl>
                    </div>
                  </div>
                ))}
              <Button
                onClick={() => proceedPayHandler()}
                variant="contained"
                className="backcolorPrimary my-4"
                style={{ textTransform: "initial" }}
              >
                Review & Confirm
              </Button>
            </div>

            <div className="w-40  d-flex justify-content-center mobileBox">
              <div className="border w-90 width300 p-3 mobileBox">
                <span className="fw-bold">Itinerary</span>

                <div className="d-flex flex-column  mt-3">
                  <div className="d-flex"></div>

                  <table className="mt-3 mx-auto">
                    <tr className="d-flex">
                      <td className="w-40">
                        <span>Departure</span>
                      </td>
                      <td>
                        <span>Total Duration: 3hrs 40min</span>
                      </td>
                    </tr>
                    <tr className="mt-3 d-flex justify-content-between">
                      <td className="d-flex px-3 flex-column py-3 bg-grey">
                        <img
                          className="my-2"
                          width={100}
                          src={planeLogo}
                          alt=""
                        />
                        <span className="my-2 fsc-5">QATAR Airways</span>
                        <span className="my-2 fsc-5">Flight #106</span>
                      </td>
                      <td className="d-flex ms-1 px-4 flex-column py-3 bg-grey">
                        <div>
                          <p className="my-2 fsc-5">DUBAI DXB </p>
                          <p className="my-2 fsc-5">Dubai International </p>
                          <p className="my-2 fsc-5">
                            Departure Date: 29-09-2022{" "}
                          </p>
                          <p className="my-2 fsc-5">Departure Time: 1:05am</p>
                        </div>
                        <div className="mt-2">
                          <p className="my-2 fsc-5">DUBAI DXB </p>
                          <p className="my-2 fsc-5">Dubai International </p>
                          <p className="my-2 fsc-5">
                            Departure Date: 29-09-2022{" "}
                          </p>
                          <p className="my-2 fsc-5">Departure Time: 1:05am</p>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <table className="mt-4 mx-auto">
                    <tr className="d-flex">
                      <td className="w-40">
                        <span>Arrival</span>
                      </td>
                      <td>
                        <span>Total Duration: 3hrs 40min</span>
                      </td>
                    </tr>
                    <tr className="mt-3 d-flex justify-content-between">
                      <td className="d-flex px-3 flex-column py-3 bg-grey">
                        <img
                          className="my-2"
                          width={100}
                          src={planeLogo}
                          alt=""
                        />
                        <span className="my-2 fsc-5">QATAR Airways</span>
                        <span className="my-2 fsc-5">Flight #106</span>
                      </td>
                      <td className="d-flex ms-1 px-4 flex-column py-3 bg-grey">
                        <div>
                          <p className="my-2 fsc-5">DUBAI DXB </p>
                          <p className="my-2 fsc-5">Dubai International </p>
                          <p className="my-2 fsc-5">
                            Departure Date: 29-09-2022{" "}
                          </p>
                          <p className="my-2 fsc-5">Departure Time: 1:05am</p>
                        </div>
                        <div className="mt-2">
                          <p className="my-2 fsc-5">DUBAI DXB </p>
                          <p className="my-2 fsc-5">Dubai International </p>
                          <p className="my-2 fsc-5">
                            Departure Date: 29-09-2022{" "}
                          </p>
                          <p className="my-2 fsc-5">Departure Time: 1:05am</p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonDrawer>
      <CommonDrawer onClose={setproceedPayOpen} open={proceedPayOpen}>
        <div className="passengerDetailDrwaer m-5 d-flex flex-column">
          <div className="ms-4">
            <h4 className="mb-4">Review & Confirm</h4>
            <span className="fw-bold">Passenger Details</span>
            <div className="table-responsive mw-100">
              <table className=" table mt-3 w-100 passengertable">
                <tr>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Title</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">Frist Name</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">Last Name</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Gender</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    Date of Birth
                  </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    Passport Number
                  </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    Passport Issue{" "}
                  </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    Passport Expiry
                  </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">Type</td>
                </tr>

                {passengerDetailsState &&
                  passengerDetailsState.map((item, i) => (
                    <tr key={i}>
                      <td className="bg-grey py-2 px-3 fsc-5">{item?.title}</td>
                      <td className="bg-grey py-2 px-3 fsc-5">
                        {item?.first_name}
                      </td>
                      <td className="bg-grey py-2 px-3 fsc-5">
                        {item?.last_name}{" "}
                      </td>
                      <td className="bg-grey py-2 px-3 fsc-5">
                        {item?.gender}
                      </td>
                      <td className="bg-grey py-2 px-3 fsc-5">
                        {dateFormat(item?.date_of_birth)}
                      </td>
                      <td className="bg-grey py-2 px-3 fsc-5">
                        {item?.passport_number}
                      </td>
                      <td className="bg-grey py-2 px-3 fsc-5">
                        {dateFormat(item?.passport_issue_date)}
                      </td>
                      <td className="bg-grey py-2 px-3 fsc-5">
                        {dateFormat(item?.passport_expiry_date)}
                      </td>
                      <td className="bg-grey py-2 px-3 fsc-5">
                        {item?.passenger_Type}
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
          <div className="ms-4 mt-3">
            <div className="d-flex justify-content-between">
              <span className="fw-bold">Departure</span>
              <span className="fw-bold">Class: Economy</span>
            </div>
            <div className="table-responsive">
              <table className="table mt-3 w-100 passengertable">
                <tr>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Flight</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Depart </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Depart Time</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Arrive</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Arrive Time</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    {" "}
                    Aircraft Type
                  </td>
                </tr>
                <tr>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    <img width={50} src={planeLogo} alt="" />
                    <br />
                    <span>Qatar Airways</span>
                    <br />
                    <span>Flight #106</span>
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    DUBAI DXB <br /> Dubai International
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    29-09-22 <br /> 01:05am
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    LONDON LHR <br /> Heathrow Airways
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    29-09-22 <br /> 05:55am
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5"></td>
                </tr>
              </table>
            </div>
          </div>
          <div className="ms-4 mt-3">
            <div className="d-flex justify-content-between">
              <span className="fw-bold">Arrival</span>
              <span className="fw-bold">Class: Economy</span>
            </div>
            <div className="table-responsive">
              <table className="table mt-3 w-100 passengertable">
                <tr>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Flight</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Depart </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Depart Time</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Arrive</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Arrive Time</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    {" "}
                    Aircraft Type
                  </td>
                </tr>
                <tr>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    <img width={50} src={planeLogo} alt="" />
                    <br />
                    <span>Qatar Airways</span>
                    <br />
                    <span>Flight #106</span>
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    DUBAI DXB <br /> Dubai International
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    06-10-22 <br /> 12:50pm
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    LONDON LHR <br /> Heathrow Airways
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    06-10-22 <br /> 11:05pm
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5"></td>
                </tr>
              </table>
            </div>
          </div>
          <div className="ms-4">
            <div className="mt-3 mb-2">
              <Checkbox
                className="m-0 p-0"
                style={{ color: "var(--primayColor)" }}
                checked={termsConditionsFlag}
                onChange={() => {
                  settermsConditionsFlag(!termsConditionsFlag);
                }}
              />
              <span className="fsc-5 mx-1">
                I have read and agree to the Terms & Conditions & the Booking
                Cancellation policy of the respective service provide.
              </span>
            </div>
            <label className="text-error fsc-4">
              {error.termsConditionsFlag &&
                !termsConditionsFlag &&
                error.termsConditionsFlag}
            </label>
          </div>
          <div className="ms-4 mt-3">
            <Button
              className="my-1 bg-white"
              style={{
                color: "var(--primayColor)",
                borderColor: "var(--primayColor)",
                fontWeight: "bold",
                textTransform: "initial",
              }}
              variant="outlined"
              onClick={() => backButtonHandler()}
            >
              Back
            </Button>
            <Button
              onClick={() => proceedPayConfirmHandler()}
              variant="contained"
              className="backcolorPrimary my-5 ms-4"
              style={{ textTransform: "initial" }}
            >
              Proceed to Pay
            </Button>
          </div>
        </div>
      </CommonDrawer>
      <CommonDrawer onClose={setPayNowOpen} open={PayNowOpen}>
        {list("right")}
      </CommonDrawer>
      <CommonDrawer onClose={setthankYou} open={thankYou}>
        <div className="passengerDetailDrwaer m-5 d-flex flex-column">
          <div className="ms-4">
            <div className="d-flex flex-column align-items-center">
              <CheckCircleIcon
                style={{ color: "var(--primayColor)", fontSize: "80px" }}
              />
              {/* <img width={70} src={tick} alt="" /> */}
              <h2 className="mt-3">Thank you!</h2>
              <span className="fw-bold-c mt-3">
                Your payment was successful
              </span>
              <br />
              <span className="text-center">
                This is your E-tickets. Do present it with a valid photo
                identification at the airport check-in counter. For
                international travel: The check-in counters are open 4 hours
                prior to departure and close strictly 2 hours prior to departure
              </span>
            </div>
            <div className="mt-2">
              <span className="fw-bold">
                Booking reference no: <strong>#12345</strong>
              </span>
            </div>
            <span className="fw-bold">Passenger Details</span>
            <div className="table-responsive">
              <table className="table mt-3 w-100 passengertable">
                <tr>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Details</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    {" "}
                    Passenger Type
                  </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    {" "}
                    Passenger Name
                  </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Gender</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Age</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    {" "}
                    Passport Number
                  </td>
                </tr>
                <tr>
                  <td className="bg-grey py-2 px-3 fsc-5">Mr</td>
                  <td className="bg-grey py-2 px-3 fsc-5">Adult</td>
                  <td className="bg-grey py-2 px-3 fsc-5">Sahil Kumar</td>
                  <td className="bg-grey py-2 px-3 fsc-5">Male</td>
                  <td className="bg-grey py-2 px-3 fsc-5">21</td>
                  <td className="bg-grey py-2 px-3 fsc-5">XR151214511</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="ms-4 mt-4">
            <div className="d-flex justify-content-between">
              <span className="fw-bold">Departure</span>
              <span className="fw-bold">Class: Economy</span>
            </div>
            <div className="table-responsive">
              <table className="table mt-3 w-100 passengertable">
                <tr>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Flight</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Depart </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Depart Time</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Arrive</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Arrive Time</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    {" "}
                    Aircraft Type
                  </td>
                </tr>
                <tr>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    <img width={50} src={planeLogo} alt="" />
                    <br />
                    <span>Qatar Airways</span>
                    <br />
                    <span>Flight #106</span>
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    DUBAI DXB <br /> Dubai International
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    29-09-22 <br /> 1:05am
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    LONDON LHR <br /> Heathrow Airways
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    29-09-22 <br /> 5:55pm
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5"></td>
                </tr>
              </table>
            </div>
          </div>
          <div className="ms-4 mt-4">
            <div className="d-flex justify-content-between">
              <span className="fw-bold">Arrival</span>
              <span className="fw-bold">Class: Economy</span>
            </div>
            <div className="table-responsive">
              <table className="table mt-3 w-100 passengertable">
                <tr>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Flight</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Depart </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Depart Time</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Arrive</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Arrive Time</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    {" "}
                    Aircraft Type
                  </td>
                </tr>
                <tr>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    <img width={50} src={planeLogo} alt="" />
                    <br />
                    <span>Qatar Airways</span>
                    <br />
                    <span>Flight #106</span>
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    LONDON LHR <br /> Heathrow Airways
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    06-11-22 <br />
                    12:50pm
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    DUBAI DXB <br /> Dubai International
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    06-09-22 <br />
                    11:05pm
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5"></td>
                </tr>
              </table>
            </div>
          </div>

          <div className="ms-4 mt-4">
            <div className="d-flex justify-content-between">
              <span className="fw-bold">Additional</span>
            </div>
            <div className="table-responsive">
              <table className="table mt-3 w-100 passengertable">
                <tr>
                  <td className="bg-dark-grey py-2 px-3 fsc-5">
                    {" "}
                    Membership No
                  </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Name </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Mobile </td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Address</td>
                  <td className="bg-dark-grey py-2 px-3 fsc-5"> Email</td>
                </tr>
                <tr>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    <span>0154212155</span>
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">Sahil Kumar</td>
                  <td className="bg-grey py-2 px-3 fsc-5">971561263466</td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    Dubai Lorem ipsum dolorjnjnj
                  </td>
                  <td className="bg-grey py-2 px-3 fsc-5">
                    john.arbharam@gmail.com
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button
              onClick={() => {
                setthankYou(false);
              }}
              variant="contained"
              className="backcolorPrimary my-5 ms-4"
              style={{ textTransform: "initial" }}
            >
              Print
            </Button>
          </div>
        </div>
      </CommonDrawer>
    </div>
  );
};

export default FlightResult;
