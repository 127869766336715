/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../commonComponents/pagination.css";


export default function CommonPagination({ limit, setLimit, setPage, setSkip, page, total }) {

  useEffect(() => {
    let sm = (page - 1) * limit;
    setSkip(sm);
  }, [page]);

  useEffect(() => {
    //setPage(1);
  }, [limit]);

  const textItemRender = (current, type, element) => {
    if (type === "prev") {
      return "Prev";
    }
    if (type === "next") {
      return "Next";
    }
    return element;
  };

  const localeInfo = {
    // Options.jsx
    items_per_page: '/ page',
    jump_to: 'Go to',
    jump_to_confirm: 'confirm',
    page: 'Page',

    // Pagination.jsx
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
    prev_3: 'Previous 3 Pages',
    next_3: 'Next 3 Pages',
    page_size: 'Page Size',
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <select
        className="select-limit"
        name="10"
        defaultValue={10}
        value={limit}
        onChange={(event) => { setLimit(event.target.value); setPage(1) }}
      >
        <option value={6}> 6</option>
        <option value={8}> 8</option>
        <option value={10}>10</option>
        <option value={12}>12</option>
        <option value={14}>14</option>
        <option value={16}>16</option>
      </select>
      <Pagination
        className="pagination-buttons-down"
        total={total}
        current={page}
        defaultCurrent={1}
        pageSize={limit}
        // itemRender={textItemRender}
        onChange={(value) => setPage(value)}
        // showSizeChanger
        showLessItems
        defaultPageSize={10}
        locale={localeInfo}
      />
    </div >
  );
}
