/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Box, Switch, } from "@mui/material";
import useStyle from './PaymentDrawerStyle';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';


const PaymentSummery = ({ disabled, amount, payAmt, usePointsFlag, setUsePointsFlag }) => {

    const classess = useStyle();

    const pointsState = useSelector((state) => state?.orderState?.points);


    useEffect(() => {
        setUsePointsFlag(false);
    }, []);

    return (
        <Box style={{ width: "100%", marginBottom: "20px" }}>
            {payAmt?.disable && pointsState
                && <Stack direction="row" spacing={2} justifyContent={"space-between"} alignItems={'center'}>
                    <span className='fw-semibold'>I agree to redeem {parseFloat(payAmt.redeemPoints)} Points</span>
                    <Switch
                        checked={usePointsFlag}
                        onChange={() => setUsePointsFlag(!usePointsFlag)}
                        style={{ color: 'var(--primayColor)' }}
                        //disabled={parseFloat(payAmt.payAmount) < 5}
                        labelPlacement="start"
                    />
                </Stack>
            }
            <Stack direction="row" spacing={2} justifyContent={"space-between"}  >
                <span>Order Amount</span>
                <span>{parseFloat(amount).toFixed(2)}</span>
            </Stack>
            {payAmt.discountAmt > 0 &&
                <Stack direction="row" spacing={2} justifyContent={"space-between"}  >
                    <span>Discount Amount</span>
                    <span>-{parseFloat(payAmt.discountAmt).toFixed(2)}</span>
                </Stack>}
            {usePointsFlag && <Stack direction="row" spacing={2} justifyContent={"space-between"}  >
                <span>Points</span>
                <span>-{payAmt.payPoints.toFixed(2)} Pts</span>
            </Stack>}
            <Stack direction="row" spacing={2} justifyContent={"space-between"}  >
                <span>Pay Amount</span>
                <span>{parseFloat(payAmt.payAmount).toFixed(2)}</span>
            </Stack>
        </Box>
    )
}

export default PaymentSummery