
export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS"

export const RESET_PRODUCT_DETAILS = "RESET_PRODUCT_DETAILS"

export const SET_USER_POINTS = "SET_USER_POINTS"

export const SET_ORDER_PRICE_OBJECT = "SET_ORDER_PRICE_OBJECT"



