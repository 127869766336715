import { SET_CONTACT_DETAILS } from "./ShopType";

const initialWeightState = {
  productDetails: null,
  contactDetails: null,
  error: "",
};

const shopReducer = (state = initialWeightState, action) => {
  switch (action.type) {


    case SET_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: action.payload,
      };


    default:
      return state;
  }
};
export default shopReducer;
