import { RESET_PRODUCT_DETAILS, SET_ORDER_PRICE_OBJECT, SET_PRODUCT_DETAILS, SET_USER_POINTS } from "./OrderType";



export const setProductOrderDetailsRedux = (data) => {
  return {
    type: SET_PRODUCT_DETAILS,
    payload: data,
  };
};

export const resetProductOrderDetailsRedux = () => {
  return {
    type: RESET_PRODUCT_DETAILS,
  };
};

export const setUserPointsRedux = (data) => {
  return {
    type: SET_USER_POINTS,
    payload: data,
  };
};



export const setOrderPriceObjectRedux = (data) => {
  return {
    type: SET_ORDER_PRICE_OBJECT,
    payload: data,
  };
};
