import {
  GET_FLIGHT_LIST_REQUEST,
  GET_FLIGHT_LIST_SUCCESS,
  GET_FLIGHT_LIST_FAILURE,
  SET_FLIGHT_SCREEN_STEP,
  SET_FLIGHT_DETAILS,
  SET_TRAVEL_MODULE_STEP,
  SET_PASSENGER_DETAILS
} from "./flightType";
import axios from "axios";
import { TRIPXOXO } from "../../utils/prodURL";


export const setFlightScreenStep = (data) => {
  return {
    type: SET_FLIGHT_SCREEN_STEP,
    payload: data,
  };
};

export const setTravelModuleStep = (data) => {
  return {
    type: SET_TRAVEL_MODULE_STEP,
    payload: data,
  };
};

export const setFlightDetails = (data) => {
  return {
    type: SET_FLIGHT_DETAILS,
    payload: data,
  };
};

export const setPassengerDetailsRedux = (data) => {
  return {
    type: SET_PASSENGER_DETAILS,
    payload: data,
  };
};

export const getFlightListRequest = () => {
  return {
    type: GET_FLIGHT_LIST_REQUEST,
  };
};

export const getFlightListSuccess = (data) => {
  return {
    type: GET_FLIGHT_LIST_SUCCESS,
    payload: data,
  };
};

export const getFlightListFailure = (error) => {
  return {
    type: GET_FLIGHT_LIST_FAILURE,
    payload: error,
  };
};

export const getFlightList = () => {
  return (dispatch) => {
    dispatch(getFlightListRequest());
    let config = {
      method: "get",
      url: `${TRIPXOXO}/airportList`,
      headers: {
        //Authorization: `Bearer ${getToken()}`,
        "X-ACCESS-TOKEN": "WNpH33v0KA062631",
        // "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*"
      },
    };
    axios(config)
      .then((response) => {
        dispatch(getFlightListSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getFlightListFailure("Something went wrong!! Please try again!!"));
      });
  };
};
