import React from 'react'
import flightIcon from '../../assets/img/Travel/FlightIcon.svg'
import flightIconWhite from '../../assets/img/Travel/FlightIconWhite.svg'
import carIcon from '../../assets/img/Travel/caricon.svg'
import carIconWhite from '../../assets/img/Travel/cariconWhite.svg'
import hotelIcon from '../../assets/img/Travel/Hotels.svg'
import hotelIconWhite from '../../assets/img/Travel/HotelsWhite.svg'
import './tab.css'
import { Box } from '@mui/system'
import PointsComponent from '../commonComponents/PointsComponent'

const Tabs = ({ setoption, option }) => {

    const clickHandler = (index) => {
        setoption(index)
    }
    return (
        <Box
            p={2}
            style={{ zIndex: 2 }}
        //  className={classess.boxMainDiv || "commonw50 mobileBox"}
        >
            <>
                <PointsComponent />
            </>

            <div className='d-flex flex-column w-100 align-items-center mx-auto mt-4'>
                <div>
                    <span className="travel-header">Go for your</span>
                    <span className="travel-header-bold mx-2">Favourite Destination!</span>
                </div>
                <div className="d-flex width-50 width-70 justify-content-between mt-5 mb-4">
                    <div onClick={() => clickHandler(1)} className={`tab tabHover ${option === 1 ? 'active-tab' : ''} `}><img src={option === 1 ? flightIconWhite : flightIcon} /></div>
                    <div onClick={() => clickHandler(2)} className={`tab tabHover ${option === 2 ? 'active-tab' : ''} `}><img src={option === 2 ? hotelIconWhite : hotelIcon} /></div>
                    <div onClick={() => clickHandler(3)} className={`tab tabHover ${option === 3 ? 'active-tab' : ''} `}><img src={option === 3 ? carIconWhite : carIcon} /></div>
                </div>
            </div>
        </Box>

    )
}

export default Tabs