
import React from 'react';
import SettingContainer from './SettingContainer';

const SettingView = () => {
    return (
        <>
            <SettingContainer />
        </>
    );
};

export default SettingView;