import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import useStyle from "../../account/accountLeft/AccountStyle";
import DragandDrop from "../../DrapandDrop/DragandDrop";

const ContactUs = (props) => {
  const classess = useStyle();
  const {
    inputFieldName,
    fileUploadFieldName,
    inputValue,
    inputFieldHandler,
    fileUploadState,
    setFileUploadState
  } = props;

  useEffect(() => {

  }, [inputFieldName, inputValue])

  const [imgFiles, setImgFiles] = useState([]);



  return (
    <>
      <Box
        style={{ padding: "0 0 0 10px" }}
        className="profileMobile settingLeft"
      >
        <FormControl
          className={classess.accountInput}
          style={{ width: "100%" }}
          variant="outlined"
          size="small"
        >
          Banner title
          <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
          <FormHelperText style={{ marginLeft: "0px" }}></FormHelperText>
          <OutlinedInput
            id="outlined-adornment-password"
            type="text"
            value={inputValue && inputValue}
            name={inputFieldName && inputFieldName}
            onChange={inputFieldHandler}
          />
          <EditOutlinedIcon className={classess.inputIcon} />
        </FormControl>

        <div className="d-flex flex-column mt-3 mb-3 ">
          Banner image
          <DragandDrop files={fileUploadState ? fileUploadState : imgFiles} setFiles={setFileUploadState} />
        </div>
        {/* <div style={{ textAlign: "right" }}>
          <Button variant="outlined" className={classess.borderBtn}>
            Save Changes
          </Button>
        </div> */}
      </Box>
    </>
  );
};

export default ContactUs;
