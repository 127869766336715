/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
    MenuItem,
    IconButton,
    FormHelperText,
    FormControl,
    Select,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Grid
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useStyle from './VoucherFilterStyle';
import { useQuery } from "react-query";
import { getBrandsCategoryList, getCountryList } from "../../../Api/CommonApi";
import { useEffect } from "react";


export default function VoucherFilter({ country, setCountry, category, setCategory, brand, setBrand }) {
    const classess = useStyle();

    const countryList = useQuery('prod_country_list', getCountryList);
    const categoryList = useQuery('shop_brand_list', () => getBrandsCategoryList("giftcard"));

    // useEffect(() => {
    //     countryList?.data?.data?.result && countryList?.data?.data?.result.map((item) => {
    //         if (item?.name === "United Arab Emirates") {
    //             setCountry(item);
    //         }
    //     });
    // }, [countryList?.data?.data?.result]);

    // useEffect(() => { }, [country]);


    return (

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className={
            classess.mb2 && `mt-5 mb-5`}>
            <Grid item xs={6} md={4}  >

                <FormControl className={classess.voucherControll} size="small">
                    <FormHelperText className={classess.voucherLable}>
                        Search Country
                    </FormHelperText>
                    <Select
                        value={country}
                        onChange={(event) => setCountry(event.target.value)}>
                        <MenuItem value={""} >None</MenuItem>
                        {countryList?.data?.data?.result?.map((item, index) => {
                            return (
                                <MenuItem value={item} key={item.id}>{item?.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

            </Grid>
            <Grid item xs={6} md={4}>

                {/* <FormControl className={classess.voucherControll}
                    size="small">
                    <FormHelperText className={
                        classess.voucherLable
                    } style={{ margin: '2px 0px' }}>Search by category</FormHelperText>
                    <Select
                        value={category}
                        onChange={(event) => setCategory(event.target.value)}>
                        <MenuItem value={""} >None</MenuItem>
                        {categoryList?.data?.data?.result?.map((item, index) => {
                            return (
                                <MenuItem value={item} key={item.id}>{item?.default_category_name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl> */}

                <FormControl className={classess.voucherControll}
                    size="small">
                    <FormHelperText className={
                        classess.voucherLable
                    } style={{ margin: '2px 0px' }}>Search by category</FormHelperText>
                    <Select
                        value={category}
                        onChange={(event) => setCategory(event.target.value)}>
                        <MenuItem value={""} >None</MenuItem>
                        {categoryList?.data?.data?.result?.sort((a, b) => a?.default_category_name.localeCompare(b?.default_category_name)).map((item, index) => {
                            return (
                                <MenuItem value={item} key={item.id}>{item?.default_category_name}</MenuItem>
                            )
                        })}
                        {/* console.log(default_category_name); */}
                    </Select>
                </FormControl>

            </Grid>
            <Grid item xs={12} md={4}>

                <FormControl className={classess.voucherControll}
                    variant="outlined"
                    size="small">
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <FormHelperText className={classess.voucherLable}>
                        Search by brand
                    </FormHelperText>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        value={brand}
                        onChange={(event) => setBrand(event.target.value)}
                        inputProps={{ style: { backgroundColor: "#FFF" } }}
                        endAdornment={
                            <InputAdornment position="end" style={{ backgroundColor: "#FFF" }} >
                                <IconButton aria-label="Search by brand" edge="end" >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>

            </Grid>
        </Grid>

    );
}
