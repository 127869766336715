import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';

export const nextYearRange = () => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};

export const last18YearRange = () => {
  let d = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
  return moment(new Date(d)).format("YYYY-MM-DD");

};

export const nextDayDate = (d) => {
  return new Date(new Date().setDate(d.getDate() + 1));
};

export const dateFormat = (d) => {
  return moment(d).format('DD/MM/YYYY');
};

export const todayDate = () => {
  return moment(new Date()).format("YYYY-MM-DD");
};

export const minYearCalculate = (y) => {
  let year = moment().subtract(y, 'year');
  return moment(year).format('YYYY/MM/DD');
};

export const maxYearCalculate = (y) => {
  let year = moment().subtract(y, 'year');
  return moment(year).format("yyyy-mm-dd");
};

export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}


export const getStatus = (e) => {
  if (e === 'successful') {
    return "Successful";
  } else if (e === 'in_progress') {
    return "Progress";
  } else if (e === 'created') {
    return "Created";
  }

}

export const getAccountStatus = () => { let res = Boolean(localStorage.getItem("loggedIn")); return res }

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}


export const printDocument = async (prodType, orderDetails, settingDetails, accounDetails) => {
  let prodName = "";
  if (prodType === "giftcard") {
    prodName = orderDetails?.giftcard?.default_title ? orderDetails?.giftcard?.default_title : orderDetails?.product_name;
  } else {
    prodName = orderDetails?.merchandise?.default_title ? orderDetails?.merchandise?.default_title : orderDetails?.product_name;
  }

  let htmlString = `<!DOCTYPE html>
  <html lang="en">
  
  <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Invoice</title>
  </head>
  
  <body style="font-family: sans-serif">
      <table width="780" border="0" align="center" cellpadding="0" cellspacing="0" style="border: 1px solid #ccc; background-color: #ffffff">
          <tbody>
              <tr>
                  <td>
                      <img src=${settingDetails?.site_logo} alt="" width="200" style="position: absolute; padding: 1.5rem" height="50" />
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="#77126a" fill-opacity="0.5"
                    d="M0,160L30,186.7C60,213,120,267,180,266.7C240,267,300,213,360,197.3C420,181,480,203,540,202.7C600,203,660,181,720,160C780,139,840,117,900,122.7C960,128,1020,160,1080,165.3C1140,171,1200,149,1260,144C1320,139,1380,149,1410,154.7L1440,160L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z">
                  </path>
                  <path fill="#77126a" fill-opacity="1"
                    d="M0,160L30,181.3C60,203,120,245,180,240C240,235,300,181,360,170.7C420,160,480,192,540,202.7C600,213,660,203,720,181.3C780,160,840,128,900,133.3C960,139,1020,181,1080,176C1140,171,1200,117,1260,90.7C1320,64,1380,64,1410,64L1440,64L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z">
                  </path>
                </svg>
                      <h1 style="
                      color: rgb(119, 18, 106);
                      text-align: end;
                      padding-right: 1.5rem;
                      font-size: 2.6rem;
                      margin: 0;
                    ">
                          INVOICE
                      </h1>
                      <tr>
                          <td>
                              <h2 style="
                          text-align: end;
                          padding-right: 1.5rem;
                          font-size: 1.2rem;
                          font-weight: 600;
                          margin: 0;
                         ">
                                  <span style="font-weight: 600; font-size: 1.2rem">
                                  ${accounDetails?.firstname + " " + accounDetails?.lastname}
                                  </span>
                                  </span>
                              </h2>
                          </td>
                      </tr>
                  </td>
  
                  <td>
                      <p style="
                      margin: 0;
                      font-size: 1rem;
                      padding-right: 1.5rem;
                      text-align: end;
                      padding-bottom: 1rem;
                    ">
                    ${accounDetails?.contact_no}<br />${orderDetails?.email_id}
                      </p>
                      <tr>
                          <td style="text-align: end; ">
                              <p style="margin: 0; font-size: 1rem; padding-right: 1.5rem">
                                  Invoice No: ${orderDetails?.id}
                              </p>
                              <p style="margin: 0; font-size: 1rem; padding-right: 1.5rem">
                                  Date:${dateFormat(orderDetails?.created_at)}
                              </p>
                          </td>
                      </tr>
                  </td>
              </tr>
              <tr style="display: flex; justify-content: space-around; padding-top: 3rem;margin-left:1.5rem;
              margin-right:1.5rem;">
                  <td style="color: rgb(119, 18, 106); font-weight: 600">Sr.</td>
                  <td style="color: rgb(119, 18, 106); font-weight: 600; width: 25rem;">
                      Product Name
                  </td>
  
                  <td style="color: rgb(119, 18, 106); font-weight: 600">Qty.</td>
                  <td style="color: rgb(119, 18, 106); font-weight: 600">Total</td>
              </tr>
  
              <tr style="
                  display: flex;
                  justify-content: space-around;
                  padding-top: 1.5rem;
                  padding-bottom: 1rem;
                  margin-left:1.5rem;
                  margin-right:1.5rem;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.593);
                ">
                  <td style="">1.</td>
                  <td style=" width: 25rem;">
                  ${prodName}
                  </td>
                  <td style=" ">
                  ${orderDetails?.quantity}
                  </td>
                  <td style=" ">
                  ${orderDetails?.total_price?.toFixed(2)}
                  </td>
              </tr>
             
              <tr style="
                  padding: 1.5rem;
                  display: flex;
                  flex-direction: column;
                  align-items: end;
                  margin-right:1.5rem;
                ">
                  <td style="padding-bottom: 0.5rem">
                      Sub Total: <span style="padding-left: 1.5rem">
                      ${orderDetails?.total_price?.toFixed(2)}
                      </span>
                  </td>
                  <td style="padding-bottom: 0.5rem; border-bottom: 1px solid rgba(0, 0, 0, 0.593);
                  ">
                      Discount: <span style="padding-left: 1.5rem">
                      ${(orderDetails?.total_price - orderDetails?.order_price)?.toFixed(2)}
                      </span>
                  </td>
                  <td style="
                    padding-top: 1rem;
                     font-weight: 600;
                  ">
                      Total: <span style="padding-left: 1.5rem">
                      ${orderDetails?.order_price?.toFixed(2)}
                      </span>
                  </td>
              </tr>
              <tr>
                  <td>
                      <div style="display: flex; justify-content: end">
                          <div style="
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        padding-right: 1.5rem;
                        padding-top:5rem;
                        color: #ffffff;
                        align-items: center;
                      ">
                              <img src=${settingDetails?.site_logo} alt="" width="150" height="40" />
                              <h2 style="margin: 0">Thank you!</h2>
                              <p style="margin: 0">for shopping with us</p>
                          </div>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="#77126a" fill-opacity="1"
                    d="M0,288L48,288C96,288,192,288,288,272C384,256,480,224,576,218.7C672,213,768,235,864,224C960,213,1056,171,1152,144C1248,117,1344,107,1392,101.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                  </path>
                  <path fill="#77126a" fill-opacity="0.5"
                    d="M0,192L48,186.7C96,181,192,171,288,154.7C384,139,480,117,576,117.3C672,117,768,139,864,128C960,117,1056,75,1152,48C1248,21,1344,11,1392,5.3L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                  </path>
                </svg>
                  </td>
              </tr>
              <tr>
                  <td align="left" valign="top" bgcolor="#77126a" width="100%" style="
                    padding: 10px 0 10px 10px;
                    font-size: 13px;
                    color: #ffffff;
                    font-family: Calibri;
                    text-align: center;
                  ">
                      This is computer generated invoice signature not required.
                  </td>
              </tr>
              <tr>
                  <td align="left" valign="top" bgcolor="#77126a" width="100%" style="
             padding: 10px 0 10px 10px;
             font-size: 13px;
             color: #ffffff;
             font-family: Calibri;
             text-align: center;
           ">
                      &#169; ${new Date().getFullYear()} Ehadaya. All Rights Reserved.
                  </td>
              </tr>
          </tbody>
      </table>
  </body>
  
  </html>`;

  let iframe = document.createElement("iframe");
  iframe.style.visibility = "hidden";
  document.body.appendChild(iframe);
  let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
  iframedoc.body.innerHTML = htmlString;

  let canvas = await html2canvas(iframedoc.body, {});

  // Convert the iframe into a PNG image using canvas.
  let imgData = canvas.toDataURL("image/png");

  // Create a PDF document and add the image as a page.
  const doc = new jsPDF();
  doc.addImage(imgData, "PNG", 0, 0,);
  doc.save(`Invoice_${orderDetails?.id}.pdf`);
  document.body.removeChild(iframe);

}