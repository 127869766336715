import {
  GET_HOTEL_LIST_FAILURE,
  GET_HOTEL_LIST_REQUEST,
  GET_HOTEL_LIST_SUCCESS,
  SET_HOTEL_DETAILS,
  SET_HOTEL_SCREEN_STEP,
} from "./hotelType";

import axios from "axios";

export const setHotelScreenStep = (data) => {
  return {
    type: SET_HOTEL_SCREEN_STEP,
    payload: data,
  };
};

export const setSearchHotelDetails = (data) => {
  return {
    type: SET_HOTEL_DETAILS,
    payload: data,
  };
};

export const getHotelListRequest = () => {
  return {
    type: GET_HOTEL_LIST_REQUEST,
  };
};

export const getHotelListSuccess = (data) => {
  return {
    type: GET_HOTEL_LIST_SUCCESS,
    payload: data,
  };
};

export const getHotelListFailure = (error) => {
  return {
    type: GET_HOTEL_LIST_FAILURE,
    payload: error,
  };
};

export const getHotelsList = () => {
  return (dispatch) => {
    dispatch(getHotelListRequest());
    let config = {
      method: "get",
      // url: `${baseUrl}/weight`,
      headers: {
        //Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(getHotelListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getHotelListFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};
