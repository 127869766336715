import React from 'react';
import { Box } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useStyle from './RightSideSection';
import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import VoucherOrder from './Components/VoucherOrder';
import ShopOrder from './Components/ShopOrder';
import { useSelector } from 'react-redux';

function flightBookingTable(title, type, name, gender, age, passport) {
  return { title, type, name, gender, age, passport };
}

const flightBookingRow = [
  flightBookingTable('1', 'Jon', '20/12/2022', 'Mumbai', 23, 'ERH3312121'),
  flightBookingTable('2', 'Rocky', '22/12/2022', 'Goa', 21, 'ERH3312453')
];

const hotelBookingRow = [
  flightBookingTable('1', '15/12/2022', '20/12/2022', 'Mumbai', 23, 'ERH3312121'),
  flightBookingTable('2', '02/12/2022', '22/12/2022', 'Goa', 21, 'ERH3312453')
];

const carBookingRow = [
  flightBookingTable('1', '15/12/2022 12:30 PM', '3', 'Mumbai', 23, 'Booked'),
  flightBookingTable('2', '02/12/2022 12:30 PM', '2', 'Goa', 21, 'Delivered')
];

const attractionBookingRow = [
  flightBookingTable('1', 'Jon', 'Male', 23, 'GJK6546HB', 'Goa'),
  flightBookingTable('2', 'Rocky', 'Male', 21, 'HDK546HB', 'Goa')
];

const AccountDetails = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const settingState = useSelector((state) => state?.settingState?.settingResponse?.site_details);
  console.log(settingState);

  const classess = useStyle();

  return (
    <>
      <Box p={2} className={
        classess.rightSideSection
      }>
        {/* <span className={
          classess.cornerTopLeft
        }></span> */}
        <h2 style={{ fontSize: '20px', marginBottom: '15px' }}>My Order</h2>
        <div>
          <Accordion className={
            classess.accordionCard
          } expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary className={
              classess.accordionTitle
            }
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={
                classess.accordionText
              }>
                Flight Booking
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={
              classess.accordionDetails
            }>
              <TableContainer className={
                classess.tableBox
              } >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={
                        classess.tableTitle
                      }>Sr.</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Name</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Booking Date</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Journey Details</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Booking Reference No</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Trip ID</TableCell>
                      <TableCell className={
                        classess.tableTitleLast
                      }>Print</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {flightBookingRow.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell className={
                          classess.tableTD
                        } component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.type}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.name}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.gender}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.age}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.passport}</TableCell>
                        <TableCell className={
                          classess.tableTDLast
                        }><Button variant="text" className={classess.tableBtn}><PrintIcon /></Button></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion className={
            classess.accordionCard
          } expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary className={
              classess.accordionTitle
            }
              expandIcon={<ExpandMoreIcon style={{ color: '#565656' }} />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={
                classess.accordionText
              }>Hotel Booking</Typography>
            </AccordionSummary>
            <AccordionDetails className={
              classess.accordionDetails
            }>
              <TableContainer className={
                classess.tableBox
              } >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={
                        classess.tableTitle
                      }>Sr.</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Check-in Date</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Check-out Date</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Journey Details</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Booking Reference No</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Status (E.g. Booked)</TableCell>
                      <TableCell className={
                        classess.tableTitleLast
                      }>Print</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hotelBookingRow.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell className={
                          classess.tableTD
                        } component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.type}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.name}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.gender}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.age}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.passport}</TableCell>
                        <TableCell className={
                          classess.tableTDLast
                        }><Button variant="text" className={classess.tableBtn}><PrintIcon /></Button></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion className={
            classess.accordionCard
          } expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary className={
              classess.accordionTitle
            }
              expandIcon={<ExpandMoreIcon style={{ color: '#565656' }} />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography className={
                classess.accordionText
              }>
                Car Booking
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={
              classess.accordionDetails
            }>
              <TableContainer className={
                classess.tableBox
              } >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={
                        classess.tableTitle
                      }>Sr.</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Pick-up Date & Time</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Pick-up Location</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Passanger</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Booking Reference No</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Status (E.g. Booked)</TableCell>
                      <TableCell className={
                        classess.tableTitleLast
                      }>Print</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {carBookingRow.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell className={
                          classess.tableTD
                        } component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.type}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.name}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.gender}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.age}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.passport}</TableCell>
                        <TableCell className={
                          classess.tableTDLast
                        }><Button variant="text" className={classess.tableBtn}><PrintIcon /></Button></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <Accordion
            className={classess.accordionCard}
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
          >
            <AccordionSummary className={classess.accordionTitle}
              expandIcon={<ExpandMoreIcon style={{ color: '#565656' }} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classess.accordionText}>Vouchers</Typography>
            </AccordionSummary>
            <AccordionDetails className={classess.accordionDetails}>
              <VoucherOrder />
            </AccordionDetails>
          </Accordion>

          <Accordion
            className={classess.accordionCard}
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}
          >
            <AccordionSummary className={classess.accordionTitle}
              expandIcon={<ExpandMoreIcon style={{ color: '#565656' }} />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography className={classess.accordionText}>Shop</Typography>
            </AccordionSummary>
            <AccordionDetails className={classess.accordionDetails}>
              <ShopOrder />
            </AccordionDetails>

          </Accordion>
          <Accordion className={
            classess.accordionCard
          } expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary className={
              classess.accordionTitle
            }
              expandIcon={<ExpandMoreIcon style={{ color: '#565656' }} />}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <Typography className={
                classess.accordionText
              }>Attraction & Activities</Typography>
            </AccordionSummary>
            <AccordionDetails className={
              classess.accordionDetails
            }>
              <TableContainer className={
                classess.tableBox
              } >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={
                        classess.tableTitle
                      }>Sr.</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Passanger Name</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Gender</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Age</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>Passport Number</TableCell>
                      <TableCell className={
                        classess.tableTitle
                      }>City</TableCell>
                      <TableCell className={
                        classess.tableTitleLast
                      }>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attractionBookingRow.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell className={
                          classess.tableTD
                        } component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.type}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.name}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.gender}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.age}</TableCell>
                        <TableCell className={
                          classess.tableTD
                        }>{row.passport}</TableCell>
                        <TableCell className={
                          classess.tableTDLast
                        }><Button variant="text" className={classess.tableBtn}><PrintIcon /></Button></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </div>


      </Box>
    </>
  )
}

export default AccountDetails;  