import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import "./sidebar.css";
import ehadayaLogo from "../../../assets/img/sidebar/ehadaya.svg";
import defaultLogo from "../../../assets/img/svg/sample-logo.png";
import travelIcon from "../../../assets/img/sidebar/travel.svg";
import voucherIcon from "../../../assets/img/sidebar/voucher.svg";
import shopIcon from "../../../assets/img/sidebar/shop.svg";
import attractionIcon from "../../../assets/img/sidebar/attraction.svg";
import accountIcon from "../../../assets/img/sidebar/account.svg";
import userIcon from "../../../assets/img/sidebar/user.svg";
import setting from "../../../assets/img/sidebar/setting.svg";
import { List, ListItem, ListItemButton } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useEffect } from "react";
import CommonDrawer from "../../common/CommonDrawer";
import LoginScreen from "../../signin/LoginScreen";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "../../../Api/SettingsApi";
import { setSettingsData } from "../../../redux/Settings/settingsAction";
import { getUserPointsApi } from "../../../Api/Points";
import { setUserPointsRedux } from "../../../redux/Order/OrderAction";
import Logoutpopup from "../../Modal/logoutpopup";

export default function Sidebar() {
    // const accountDetailsState = useSelector((state) => state?.settingState?.accountDetails);


  const settingState = useSelector((state) => state?.settingState?.settingResponse?.site_details);

  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();

  const pathname = location.pathname;
  const [sideBarShow, setSideBarShow] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [loginFlag, setLoginFlag] = useState(false);
  const [show, setShow] = useState(false);
  const onhide = () => setShow(false);
  function handlePopup() { setShow(true) }


  useEffect(() => {
    setLoginStatus(Boolean(localStorage.getItem("loggedIn")));
    getSettingsData();

  }, []);

  useEffect(() => {
    if (loginStatus) {
      getUserPoints();
      setLoginFlag(false);
    } else {
      //  setLoginFlag(true);
    }
  }, [loginStatus, settingState]);


  const handleLogout = () => {
    if (loginStatus === true) {
      history("/");
      localStorage.clear();
      window.location.reload();
      onhide();
    }
  };

  const handleLogin = () => {
    if (!loginStatus) {
      setLoginFlag(true);
    }
  };

  const getSettingsData = async () => {
    const res = await getSettings();
    dispatch(setSettingsData(res.data?.result));
  }

  const getUserPoints = async () => {
    const res = await getUserPointsApi();
    dispatch(setUserPointsRedux(res.data?.result?.Table1[0]?.AvailablePoints));
  }
  return (
    <>
      <Box
        className="mobileAppBar"
        sx={{ display: { xs: "block", sm: "block", md: "none" } }}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                setSideBarShow(!sideBarShow);
              }}
            >
              <MenuIcon />
            </IconButton>
            <div style={{ margin: "0 auto" }}>
              <img className="main-logo" src={settingState?.site_logo||defaultLogo} alt="ehadaya" />
              {/* <img className="main-logo" src={defaultLogo} alt="ehadaya" /> */}
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        id="primaryColor"
        className={
          sideBarShow
            ? "sibeBar-nav mobileSideShow"
            : "sibeBar-nav mobileSideHide"
        }
      >
        {/* <div className="sideBar-logo-container">
        <img className="main-logo" src={ehadayaLogo} alt="ehadaya" />
        </div> */}
        <nav aria-label="main mailbox folders">
          <List>
            <Link className="nav-label-txt" to="/">
              <ListItem
                disablePadding
                sx={{
                  display: { xs: "none", sm: "none", md: "block" },
                  textAlign: "center",
                }}
              >
                <img className="main-logo" src={settingState?.site_logo || defaultLogo} alt="ehadaya" />
                {/* <img className="main-logo" src={defaultLogo} alt="ehadaya" /> */}
              </ListItem>
            </Link>

            <Link className="nav-label-txt" to="/travel">
              <ListItem
                className={`${pathname=== "/travel" ? "active" : ""}`}
                disablePadding
              >
                <ListItemButton className="flex-column">
                  <Avatar
                    className="ImgBox"
                    alt="Travel"
                    src={travelIcon}
                    variant="square"
                  />
                  <p>Travel</p>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link className="nav-label-txt" to="/voucher">
              <ListItem
                className={'${pathname === "/voucher" ? "active" : ""}'}
                disablePadding
              >
                <ListItemButton className="flex-column">
                  <Avatar
                    className="ImgBox"
                    alt="Voucher"
                    src={voucherIcon}
                    variant="square"
                  />
                  <p>Voucher</p>
                </ListItemButton>
              </ListItem>
            </Link>

            <Link className="nav-label-txt" to="/shop">
              <ListItem
                className={'${pathname === "/shop" ? "active" : ""}'}
                disablePadding
              >
                <ListItemButton className="flex-column">
                  <Avatar
                    className="ImgBox"
                    alt="shop"
                    src={shopIcon}
                    variant="square"
                  />
                  <p>Shop</p>
                </ListItemButton>
              </ListItem>
            </Link>

            <Link className="nav-label-txt" to="/attraction">
              <ListItem
                className={'${pathname === "/attraction" ? "active" : ""}'}
                disablePadding
              >
                <ListItemButton className="flex-column">
                  <Avatar
                    className="ImgBox"
                    alt="Attraction"
                    src={attractionIcon}
                    variant="square"
                  />
                  <p className="text-center">Attraction &#38; Activities</p>
                </ListItemButton>
              </ListItem>
            </Link>

            {loginStatus === true && <Link className="nav-label-txt" to="/account">
              <ListItem
                className={'${pathname === "/account" ? "active" : ""}'}
                disablePadding
              >
                <ListItemButton className="flex-column">
                  <Avatar
                    className="ImgBox"
                    alt="Account"
                    src={accountIcon}
                    variant="square"
                  />
                  <p>My Account</p>
                </ListItemButton>
              </ListItem>
            </Link>}

            {/* {loginStatus === true && <Link className="nav-label-txt" to="/setting">
              <ListItem disablePadding
                className={'${pathname === "/setting" ? "active" : ""}'}
              >
                <ListItemButton className="flex-column">
                  <Avatar
                    className="ImgBox"
                    alt="User"
                    src={setting}
                    variant="square"
                  />
                  <p>Settings</p>
                </ListItemButton>
              </ListItem>
            </Link>  } */}
            <Link
              className="nav-label-txt"
              // to={loginStatus === true ? "" : "/login"}
              onClick={() => { loginStatus ? handlePopup() : handleLogin() }}
            >
              <ListItem disablePadding>
                <ListItemButton className="flex-column">
                  <Avatar
                    className="ImgBox"
                    alt="User"
                    src={userIcon}
                    variant="square"
                  />
                  <p>{loginStatus === true ? "Logout" : "Login"}</p>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </nav>
      </Box>
      <CommonDrawer onClose={setLoginFlag} open={loginFlag}>
        <Box sx={{ bgcolor: "#ffffff", width: "100%" }}>
          <LoginScreen open={loginFlag} onClose={setLoginFlag} />
        </Box>

      </CommonDrawer>
    
      <Logoutpopup onhide={onhide} show={show} handleLogout={handleLogout} />
    
    </>
  );
}
