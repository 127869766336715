import React from "react";
import user from "../../assets/img/voucher/user.png";
import Avatar from "@mui/material/Avatar";
import { Button, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useState } from "react";
import { useQuery } from "react-query";
import { getCountryList } from "../../Api/CommonApi";
import { registerUserApi } from "../../Api/Auth";
import { CLIENT_ID } from "../../utils/constantData";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';


function RegisterScreen({ setModelShow }) {

  const [isLoading, setIsLoding] = useState(false);
  const [passShowHide, setPassShowHide] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [message, setMessage] = useState("");

  const [registerAuth, setRegisterAuth] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    gender: "",
    age: "",
    password: "",
    message: "",
  });

  const [registerError, setRegisterError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    message: "",
    country: "",
  });

  const countryList = useQuery('prod_country_list', getCountryList);

  const togglePassword = () => {
    setPassShowHide((prevState) => !prevState);
  };

  // Register input handler
  const registerAuthHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setRegisterAuth({ ...registerAuth, [name]: value });
    setRegisterError({ ...registerError, [name]: "" });
  };

  // Register validation
  const registerValidate = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var passRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,}$)"
    );

    if (!registerAuth.firstName) {
      setRegisterError({ ...registerError, firstName: "Please enter first name", });
      return false;
    } else if (!registerAuth.lastName) {
      setRegisterError({ ...registerError, lastName: "Please enter last name", });
      return false;
    } else if (!registerAuth.email) {
      setRegisterError({ ...registerError, email: "Please enter email" });
      return false;
    } else if (emailRegex.test(registerAuth.email) === false) {
      setRegisterError({ ...registerError, email: "Enter valid email" });
      return false;
    } else if (!registerAuth.phone) {
      setRegisterError({ ...registerError, phone: "Please enter phone", });
      return false;
    } else if (!registerAuth.country) {
      setRegisterError({ ...registerError, country: "Please select country", });
      return false;
      // } else if (registerAuth.phone.length !== 10 || !Number(registerAuth.phone)) {
      //   setRegisterError({ ...registerError, phone: "Enter a valid 10-digit phone number", });
      //   return false;
    } else if (!registerAuth.password) {
      setRegisterError({ ...registerError, password: "Please enter password", });
      return false;
    } else if (!registerAuth.password || passRegex.test(registerAuth.password) === false) {
      setRegisterError({ ...registerError, password: `Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character`, });
      return false;
    }
    return true;
  };

  const submitHandler = () => {
    let val = registerValidate();
    if (val) {
      setIsLoding(true);
      registerUser();
    }
  };

  const registerUser = async () => {
    let userName = registerAuth.firstName + registerAuth.lastName;
    let payload = {
      "user_type": "client",
      "type_id": CLIENT_ID,
      "user_role_id": "63fdd8270d4ae2a0de7fe168",
      "email": registerAuth.email,
      "password": registerAuth.password,
      "username": userName.toLowerCase(),
      "firstname": registerAuth.firstName,
      "lastname": registerAuth.lastName,
      "contact_no": registerAuth.phone,
      "is_loyality_user": true
    }
    let res = await registerUserApi(payload);
    if (res?.data?.code === 200) {
      setIsLoding(false);
      setMessage("Account successfully created.");
    } else if (res?.data?.code === 409) {
      setIsLoding(false);
      setMessage("Account already exists.");
    } else {
      setIsLoding(false);
      setMessage("Something went wrong. please try again.");
    }
  }

  const handleChange = (event) => {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (e) => {
      let img = e.target.result;
      setProfileImg(img);
    };
  };

  return (
    <>
      <div style={{ padding: "36px 30px" }}>
        <div className="d-flex flex-row align-items-center justify-content-between">
          {profileImg ?
            <div className="profilePic-login">
              <img src={profileImg} className="img" alt="" />
            </div> :
            <Avatar className="mui-useravtar" src={user} />}
          <div style={{ position: 'relative', textAlign: 'center' }}>
            <input className="profileChangeBtn" type="file" onChange={(e) => handleChange(e)} />
            <span className="profileBtn">
              {profileImg ? "Change Profile" : "Upload Profile"}
              <EditOutlinedIcon />
            </span>
          </div>
        </div>
        <div className="my-3">
          <InputLabel>First Name<span className="text-danger">*</span></InputLabel>
          <OutlinedInput
            className="InputBox"
            id=""
            type="text"
            size="small"
            sx={{
              minWidth: "400px",
              height: "40px",
            }}
            name="firstName"
            onChange={registerAuthHandler}
            value={registerAuth.firstName}
          />
          {registerError.firstName ? (
            <InputLabel className="text-danger">
              {registerError.firstName}
            </InputLabel>
          ) : null}
        </div>
        <div className="my-3">
          <InputLabel>Last Name<span className="text-danger">*</span></InputLabel>
          <OutlinedInput
            id=""
            className="InputBox"
            type="text"
            size="small"
            sx={{
              minWidth: "400px",
              height: "40px",
            }}
            name="lastName"
            onChange={registerAuthHandler}
            value={registerAuth.lastName}
          />
          {registerError.lastName ? (
            <InputLabel className="text-danger">
              {registerError.lastName}
            </InputLabel>
          ) : null}
        </div>

        <div className="my-3 d-flex justify-content-between">
          <div>
            <InputLabel>Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{
                minWidth: "180px",
                height: "40px"
              }}
            >
              <MenuItem value={10}>Male</MenuItem>
              <MenuItem value={20}>Female</MenuItem>
              <MenuItem value={30}>Prefer Not to respond</MenuItem>
            </Select>
          </div>
          <div>
            <InputLabel>Age</InputLabel>
            <OutlinedInput
              id=""
              className="InputBox"
              type="text"
              size="small"
              sx={{
                maxWidth: "180px",
                height: "40px",
              }}
            />
          </div>
        </div>
        <div className="my-3">
          <InputLabel>Email Address<span className="text-danger">*</span></InputLabel>
          <OutlinedInput
            id=""
            className="InputBox"
            type="text"
            size="small"
            sx={{
              minWidth: "400px",
              height: "40px",
            }}
            name="email"
            onChange={(e) => {
              setRegisterAuth({ ...registerAuth, "email": e.target.value.toLowerCase() });
              setRegisterError({ ...registerError, email: "" });
            }}
            value={registerAuth.email}
          />
          {registerError.email ? (
            <InputLabel className="text-danger">
              {registerError.email}
            </InputLabel>
          ) : null}
        </div>
        <div className="my-3">
          <InputLabel>Phone Number<span className="text-danger">*</span></InputLabel>
          <OutlinedInput
            id=""
            className="InputBox"
            type="text"
            size="small"
            sx={{
              minWidth: "400px",
              height: "40px",
            }}
            name="phone"
            onChange={registerAuthHandler}
            value={registerAuth.phone}
          />
          {registerError.phone ? (
            <InputLabel className="text-danger">
              {registerError.phone}
            </InputLabel>
          ) : null}
        </div>
        <div className="my-3">
          <div>
            <InputLabel>Country<span className="text-danger">*</span></InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{
                minWidth: "400px",
                height: "40px"
              }}
              name="country"
              onChange={registerAuthHandler}
              value={registerAuth.country}
            >
              <MenuItem value={10}>India</MenuItem>
              <MenuItem value={20}>UAE</MenuItem>
              <MenuItem value={30}>USA</MenuItem>
              {/* {countryList?.data?.data?.result?.map((item, index) => {
                return (
                  <MenuItem value={item} key={index}>{item?.name}</MenuItem>
                )
              })} */}
            </Select>
            {registerError.country ? (
              <InputLabel className="text-danger">
                {registerError.country}
              </InputLabel>
            ) : null}
          </div>
        </div>


        <div className="my-3">
          <InputLabel>Password<span className="text-danger">*</span></InputLabel>
          <OutlinedInput
            className="InputBox"
            id="outlined-adornment-password"
            type={passShowHide ? "text" : "password"}
            size="small"
            sx={{
              minWidth: "400px",
              height: "40px",
            }}
            endAdornment={
              <InputAdornment
                style={{ marginLeft: "0" }}
                position="end"
              >
                <IconButton
                  aria-label="Search by brand"
                  edge="end"
                  onClick={() => togglePassword()}
                >
                  {passShowHide ? (
                    <RemoveRedEyeIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
            name="password"
            onChange={registerAuthHandler}
            value={registerAuth.password}
          />
          {registerError.password ? (

            <InputLabel className="text-danger loginErrorLable" >
              {registerError.password}
            </InputLabel>
          ) : null}
        </div>
        <div className="my-3">
          <Stack spacing={3} direction="row">
            <Button
              variant="outlined"
              className={`borderButton`}
              onClick={() => setModelShow("signin")}
            >
              Back
            </Button>
            <LoadingButton
              className={isLoading ? "fillBtn-loader-true" : "fillBtn-loader"}
              loading={isLoading}
              variant="outlined"
              onClick={() => submitHandler()}
            >
              {isLoading ? "" : "Register"}
            </LoadingButton>
          </Stack>
        </div>
        {message &&
          <div className="loginErrorLable" >
            <span className="font-14 font-bold ">
              {message && message}
            </span>
          </div>
        }
      </div>
    </>
  );
}

export default RegisterScreen;
