import { Skeleton } from '@mui/material';
import React from 'react'
import bgImgArray from "../../../assets/img/index";

const Shopcard = ({ title, imgUrl, hardisk, currency, price }) => {


    return (
        title ?
            <div class="shop-card shop-card--1 ">

                <div class="shop-card__img"></div>
                <div classname="card_link">
                    <div class="shop-card__img--hover" style={{ backgroundImage: `url(${bgImgArray[imgUrl] || imgUrl})` }}>
                    </div>
                </div>
                <div class="shop-card__info">
                    {/* <span class="shop-card__category"> Recipe</span> */}
                    <h3 class="shop-card__title">{title?.slice(0, 50)}</h3>
                    <span class="shop-card__by">{currency + " " + price} </span>

                </div>
            </div> :
            <div>
                <Skeleton animation="wave" height={"220px"} />
                <Skeleton animation="wave" height={"50px"} />
                <Skeleton animation="wave" height={"30px"} />
            </div>

    )
}

export default Shopcard