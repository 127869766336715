/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import Tabs from "./Tabs";
import BannerComponent from "./BannerComponent";
import Menu from "./Menu";
import TrendingProducts from "./TrendingProducts";
import FlightResult from "./FlightResult";
import ButtonAppBar from "../common/Appbar";
import { useDispatch, useSelector } from "react-redux";
import {
  setFlightDetails,
  setFlightScreenStep,
} from "../../redux/Flight/flightAction";
import { nextYearRange } from "../../utils/constantFunctions";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "../DatePicker/DatePicker";
import { Button } from "@mui/material";
import flightsidebanner from "../../assets/img/Travel/webp/airplane.webp";

export default function FlightComponent({ setoption, option }) {
  const dispatch = useDispatch();
  const inputElementFrom = useRef(null);
  const settingState = useSelector(
    (state) => state?.settingState?.settingResponse
  );
  const flightDetails = useSelector(
    (state) => state?.flightState?.flightDetails
  );
  const step = useSelector((state) => state?.flightState?.step);

  const [bannerShow, setbannerShow] = useState(true);
  const [sarchFlightBtn, setSarchFlightBtn] = useState(true);

  const [fields, setFields] = useState({
    travelType: flightDetails?.travelType
      ? flightDetails?.travelType
      : "Return",
    from: flightDetails ? flightDetails?.from : "",
    to: flightDetails ? flightDetails?.to : "",
    departure: flightDetails ? new Date(flightDetails.departure) : new Date(),
    arrival: flightDetails ? new Date(flightDetails.arrival) : new Date(),
    adult: flightDetails?.adult ? flightDetails?.adult : "",
    child: flightDetails?.child ? flightDetails?.child : "0",
    belowTwoYears: flightDetails?.belowTwoYears
      ? flightDetails?.belowTwoYears
      : "0",
    travelClass: flightDetails?.travelClass ? flightDetails?.travelClass : "",
    airlinesPreference: flightDetails?.airlinesPreference
      ? flightDetails?.airlinesPreference
      : "",
  });

  const [error, setError] = useState({
    from: "",
    to: "",
    departure: "",
    arrival: "",
    adult: "",
    child: "",
    belowTwoYears: "",
    travelClass: "",
    airlinesPreference: "",
  });

  const initialData = () => {
    setbannerShow(true);
  };

  useEffect(() => {
    if (step >= 1) {
      setbannerShow(false);
      setSarchFlightBtn(true);
    }
  }, [step]);

  // checking the fields value fill or not
  useEffect(() => {
    if (!fields.adult || fields.adult === "0") {
      setSarchFlightBtn(true);
    } else {
      setSarchFlightBtn(false);
    }

    if (flightDetails && flightDetails.adult === fields.adult) {
      setSarchFlightBtn(true);
    }
  }, [fields && fields.adult]);

  const SelectHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields({ ...fields, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const dateHandler = (item, name) => {
    if (name === "departure") {
      setFields({ ...fields, departure: item, arrival: item });
    } else if (name === "arrival") {
      setFields({ ...fields, arrival: item });
    }
  };

  const inputHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields({ ...fields, [name]: value });
    setError({ ...error, [name]: "" });

    if (Number(fields.adult) > 0) {
      setSarchFlightBtn(false);
    }
  };

  const plusButtonHandler = (e) => {
    const name = e.target.name;

    if (name === "adult") {
      setSarchFlightBtn(false);

      setFields({ ...fields, [name]: Number(fields.adult) + 1 });
      setError({ ...error, adult: "" });
    } else if (name === "child") {
      setFields({ ...fields, [name]: Number(fields.child) + 1 });
      setError({ ...error, child: "" });
    } else if (name === "belowTwoYears") {
      setFields({ ...fields, [name]: Number(fields.belowTwoYears) + 1 });
      setError({ ...error, belowTwoYears: "" });
    }
  };

  const minusButtonHandler = (e) => {
    const name = e.target.name;
    if (name === "adult") {
      setFields({ ...fields, [name]: Number(fields.adult) - 1 });
    } else if (name === "child") {
      setFields({ ...fields, [name]: Number(fields.child) - 1 });
    } else if (name === "belowTwoYears") {
      setFields({ ...fields, [name]: Number(fields.belowTwoYears) - 1 });
    }
  };

  const validation = () => {
    if (!fields.from) {
      setError({ ...error, from: "Please select the From destination" });
      if (inputElementFrom.current) {
        inputElementFrom.current.focus();
      }
      return false;
    } else if (!fields.to) {
      setError({ ...error, to: "Please select the To destination" });
      return false;
    } else if (!fields.departure) {
      setError({ ...error, departure: "Please select the departure" });
      return false;
    } else if (!fields.arrival && fields?.travelType !== "One Way") {
      setError({ ...error, arrival: "Please select the arrival" });
      return false;
    } else if (!fields.adult) {
      setError({ ...error, adult: "Please enter the adult" });
      return false;
    } else if (Number(fields.adult) === 0) {
      setError({ ...error, adult: "Please enter the value greater than 0 " });
      return false;
    } else if (!fields.travelClass) {
      setError({ ...error, travelClass: "Please select the travel class" });
      return false;
    } else if (!fields.airlinesPreference) {
      setError({
        ...error,
        airlinesPreference: "Please select the airlines preference",
      });
      return false;
    }

    return true;
  };

  const searchFlightHandler = () => {
    let val = validation();

    if (val) {
      setbannerShow(true);
      setbannerShow(false);
      dispatch(setFlightScreenStep(1));
      dispatch(setFlightDetails(fields));
    }

    // if (true) {
    //   setbannerShow(false);
    //   dispatch(setFlightScreenStep(1));
    // }
  };

  return (
    <div className="w-100 d-flex mobileStack">
      <div
        className={
          bannerShow
            ? "commonw50 mainLeft mobileBox"
            : "commonw50 mainLeft mobileBox mobileIndex"
        }
      >
        <Tabs setoption={setoption} option={option} />
        <div className="d-flex flex-column align-items-center">
          <Box
            sx={{
              width: { xs: "100%", sm: "auto" },
              padding: { xs: "0px 15px", sm: "0px" },
            }}
          >
            <div className="my-3">
              <RadioGroup
                aria-labelledby="travel type"
                defaultValue={fields && fields.travelType}
                name="travelType"
                className="iconspace"
                sx={{
                  "&.Mui-checked": {
                    color: "#4B8DF8",
                  },
                }}
                row
                onChange={SelectHandler}
              >
                <FormControlLabel
                  value="One Way"
                  control={<Radio style={{ color: "var(--primayColor)" }} />}
                  label="One Way"
                  className="iconspace"
                />
                <FormControlLabel
                  value="Return"
                  control={<Radio style={{ color: "var(--primayColor)" }} />}
                  label="Return"
                  className="iconspace"
                />
                <FormControlLabel
                  value="Multicity"
                  control={<Radio style={{ color: "var(--primayColor)" }} />}
                  label="Multicity"
                  className="iconspace"
                />
              </RadioGroup>
            </div>
            <div className="my-3">
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={0}>
                  <InputLabel>From</InputLabel>
                  <Select
                    ref={inputElementFrom}
                    id="selectFrom"
                    sx={{
                      minWidth: { xs: "100%", sm: "250px" },
                    }}
                    onChange={SelectHandler}
                    name={"from"}
                    defaultValue={fields && fields.from}
                  >
                    <MenuItem value={"Sharjah"}>Sharjah</MenuItem>
                    <MenuItem value={"Al Ain"}>Al Ain</MenuItem>
                    <MenuItem value={"Ras Al Khaimah"}>Ras Al Khaimah</MenuItem>
                  </Select>

                  {error && error.from ? (
                    <label className="text-error">{error.from}</label>
                  ) : null}
                </Stack>
                <Stack
                  spacing={0}
                  sx={{
                    marginLeft: {
                      xs: "0px !important",
                      sm: "16px !important",
                      md: "16px !important",
                    },
                  }}
                >
                  <InputLabel>To</InputLabel>
                  <Select
                    id="selectTo"
                    // onChange=""
                    sx={{
                      minWidth: { xs: "100%", sm: "250px" },
                    }}
                    onChange={SelectHandler}
                    name={"to"}
                    defaultValue={fields && fields.to}
                  >
                    <MenuItem value={"Sharjah"}>Sharjah</MenuItem>
                    <MenuItem value={"Al Ain"}>Al Ain</MenuItem>
                    <MenuItem value={"Ras Al Khaimah"}>Ras Al Khaimah</MenuItem>
                  </Select>
                  {error && error.to ? (
                    <label className="text-error">{error.to}</label>
                  ) : null}
                </Stack>
              </Stack>
            </div>
            <div className="my-3">
              <Stack
                direction="row"
                spacing={2}
                sx={{ flexDirection: { xs: "column", sm: "row", md: "row" } }}
                style={{ justifyContent: "space-between" }}
              >
                <Stack spacing={0} style={{ width: "100%" }}>
                  <InputLabel>Departure</InputLabel>
                  <DatePicker
                    onChange={dateHandler}
                    selected={fields?.departure}
                    minDate={new Date()}
                    maxDate={nextYearRange()}
                    name={"departure"}
                    id="dateDeparture"
                    className="datepicker-input-style-without-border"
                  />
                  {error && error.departure ? (
                    <label className="text-error">{error.departure}</label>
                  ) : null}
                </Stack>

                <Stack
                  spacing={0}
                  sx={{
                    marginLeft: {
                      xs: "0px !important",
                      sm: "16px !important",
                      md: "16px !important",
                    },
                  }}
                  style={{ width: "100%" }}
                >
                  {fields?.travelType !== "One Way" && (
                    <>
                      <InputLabel>Arrival</InputLabel>
                      <DatePicker
                        onChange={dateHandler}
                        selected={fields?.arrival}
                        minDate={fields?.departure}
                        maxDate={nextYearRange()}
                        name={"arrival"}
                        id="arrival"
                        className="datepicker-input-style-without-border"
                        disabled={
                          fields && fields.travelType === "One Way"
                            ? true
                            : false
                        }
                      />
                      {error && error.arrival ? (
                        <label className="text-error">{error.arrival}</label>
                      ) : null}
                    </>
                  )}
                </Stack>
              </Stack>
            </div>
            <div className="my-3">
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={0}>
                  <InputLabel>Adult(s)</InputLabel>
                  <div className="d-flex" c>
                    <Button
                      onClick={plusButtonHandler}
                      disabled={
                        fields && Number(fields.adult) === 9 ? true : false
                      }
                      style={{
                        borderRadius: "2px 0px 0px 2px",
                        borderColor: "lightgrey",
                        fontSize: "24px",
                        backgroundColor: "white",
                        color: "#58595B",
                      }}
                      variant="outlined"
                      className={
                        Number(fields && fields.adult) === 9
                          ? `mobileWidthFlight mobileWidthFlightDisabled`
                          : `mobileWidthFlight`
                      }
                      name="adult"
                    >
                      +
                    </Button>
                    <TextField
                      required
                      id="textAdult"
                      type="text"
                      value={fields && fields.adult ? fields.adult : "0"}
                      // disabled="true"
                      className="data mobileFlightAdultInput"
                      sx={{
                        textAlign: "center",
                        fontSize: "24px",
                        borderRadius: "0px",
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "0",
                        },
                      }}
                      inputProps={{ maxLength: 1 }}
                      onChange={inputHandler}
                      name={"adult"}
                    />
                    <Button
                      onClick={minusButtonHandler}
                      style={{
                        borderRadius: "0px 2px 2px 0px",
                        borderColor: "lightgrey",
                        backgroundColor: "white",
                        fontSize: "24px",
                        color: "#58595B",
                      }}
                      variant="outlined"
                      disabled={
                        fields && Number(fields.adult) === 0 ? true : false
                      }
                      className={
                        fields && Number(fields.adult) === 0
                          ? `mobileWidthFlight mobileWidthFlightDisabled`
                          : `mobileWidthFlight`
                      }
                      name={"adult"}
                    >
                      -
                    </Button>
                  </div>
                  {error && error.adult ? (
                    <label className="text-error" style={{ width: "155px" }}>
                      {error.adult}
                    </label>
                  ) : null}
                </Stack>

                <Stack spacing={0} className="childContainer">
                  <InputLabel>Child(s)</InputLabel>
                  <div className="d-flex" c>
                    <Button
                      onClick={plusButtonHandler}
                      disabled={
                        fields && Number(fields.child) === 9 ? true : false
                      }
                      style={{
                        borderRadius: "2px 0px 0px 2px",
                        borderColor: "lightgrey",
                        fontSize: "24px",
                        backgroundColor: "white",
                        color: "#58595B",
                      }}
                      variant="outlined"
                      className={
                        fields && Number(fields.child) === 9
                          ? `mobileWidthFlight mobileWidthFlightDisabled`
                          : `mobileWidthFlight`
                      }
                      name={"child"}
                    >
                      +
                    </Button>
                    <TextField
                      required
                      id="textAdult"
                      type="text"
                      className="data mobileFlightAdultInput"
                      sx={{
                        textAlign: "center",
                        fontSize: "24px",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "0",
                        },
                      }}
                      inputProps={{ maxLength: 1 }}
                      onChange={inputHandler}
                      name={"child"}
                      value={fields && fields.child}
                    />
                    <Button
                      onClick={minusButtonHandler}
                      style={{
                        borderRadius: "0px 2px 2px 0px",
                        borderColor: "lightgrey",
                        backgroundColor: "white",
                        fontSize: "24px",
                        color: "#58595B",
                      }}
                      variant="outlined"
                      className={
                        fields && Number(fields.child) === 0
                          ? `mobileWidthFlight mobileWidthFlightDisabled`
                          : `mobileWidthFlight`
                      }
                      name={"child"}
                    >
                      -
                    </Button>
                  </div>
                </Stack>

                <Stack spacing={0} className="twoYearsContainer">
                  <InputLabel>Infant below 2 years</InputLabel>
                  <div className="d-flex" c>
                    <Button
                      onClick={plusButtonHandler}
                      disabled={
                        fields && Number(fields.belowTwoYears) === 9
                          ? true
                          : false
                      }
                      style={{
                        borderRadius: "2px 0px 0px 2px",
                        borderColor: "lightgrey",
                        backgroundColor: "white",
                        fontSize: "24px",
                        color: "#58595B",
                      }}
                      variant="outlined"
                      className={
                        fields && Number(fields.belowTwoYears) === 9
                          ? `mobileWidthFlight mobileWidthFlightDisabled`
                          : `mobileWidthFlight`
                      }
                      name={"belowTwoYears"}
                    >
                      +
                    </Button>
                    <TextField
                      required
                      id="textAdult"
                      type="text"
                      className="data mobileFlightAdultInput"
                      sx={{
                        textAlign: "center",
                        fontSize: "24px",
                        borderRadius: "0px",
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "0",
                        },
                      }}
                      inputProps={{ maxLength: 1 }}
                      onChange={inputHandler}
                      name={"belowTwoYears"}
                      value={fields && fields.belowTwoYears}
                    />
                    <Button
                      onClick={minusButtonHandler}
                      disabled={
                        fields && Number(fields.belowTwoYears) === 0
                          ? true
                          : false
                      }
                      style={{
                        borderRadius: "0px 2px 2px 0px",
                        borderColor: "lightgrey",
                        fontSize: "24px",
                        backgroundColor: "white",
                        color: "#58595B",
                      }}
                      variant="outlined"
                      className={
                        fields && Number(fields.belowTwoYears) === 0
                          ? `mobileWidthFlight mobileWidthFlightDisabled`
                          : `mobileWidthFlight`
                      }
                      name={"belowTwoYears"}
                    >
                      -
                    </Button>
                  </div>
                </Stack>
              </Stack>
            </div>
            <div className="my-3">
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={0}>
                  <InputLabel>Travel Class</InputLabel>
                  <Select
                    id="selectTravelClass"
                    // onChange=""
                    sx={{
                      minWidth: { xs: "100%", sm: "250px" },
                    }}
                    onChange={SelectHandler}
                    name={"travelClass"}
                    defaultValue={fields && fields.travelClass}
                  >
                    <MenuItem value={"Economy"}>Economy</MenuItem>
                    <MenuItem value={"Business"}>Business</MenuItem>
                    <MenuItem value={"First"}>First</MenuItem>
                  </Select>
                  {error && error.travelClass ? (
                    <label className="text-error">{error.travelClass}</label>
                  ) : null}
                </Stack>
                <Stack
                  spacing={0}
                  sx={{
                    marginLeft: {
                      xs: "0px !important",
                      sm: "16px !important",
                      md: "16px !important",
                    },
                  }}
                >
                  <InputLabel>Airlines Preference</InputLabel>
                  <Select
                    id="selectAirlinesPref"
                    // onChange=""
                    sx={{
                      minWidth: { xs: "100%", sm: "250px" },
                    }}
                    onChange={SelectHandler}
                    name={"airlinesPreference"}
                    defaultValue={fields && fields.airlinesPreference}
                  >
                    <MenuItem value={"All Airlines"}>All Airlines</MenuItem>
                  </Select>
                  {error && error.airlinesPreference ? (
                    <label className="text-error">
                      {error.airlinesPreference}
                    </label>
                  ) : null}
                </Stack>
              </Stack>
            </div>
            <div className="marginTop-2 d-flex justify-content-end">
              <button
                className={
                  sarchFlightBtn === true
                    ? "search-travel-btn-disabled"
                    : "search-travel-btn"
                }
                onClick={searchFlightHandler}
              >
                Search Flight
              </button>
            </div>
          </Box>
          <Menu />
          <TrendingProducts />
        </div>
      </div>

      <div className="commonw50 mobileBox">
        {bannerShow ? (
          <BannerComponent
            heading={"Welcome to the"}
            text={
              settingState?.flight?.flight_banner_title
                ? settingState?.flight?.flight_banner_title
                : "World of Endless Rewards!"
            }
            banner={flightsidebanner}
          />
        ) : (
          <>
            <Box
              className="mobileDetailAppBar"
              sx={{ display: { xs: "block", sm: "none", md: "none" } }}
            >
              <ButtonAppBar initialData={initialData} text="Flights" />
            </Box>
            <FlightResult />
          </>
        )}
      </div>
    </div>
  );
}
