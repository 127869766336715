export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID

export const TRIPXOXO_CUSTOMER_ID = 104388

export const NAME_TITTLE = [
    { "id": 1, "name": "Mr", "value": "mr" },
    { "id": 2, "name": "Mrs", "value": "Mrs" },
    { "id": 3, "name": "Ms", "value": "ms" },
]

export const GENDER = [
    { "id": 1, "name": "Male", "value": "male" },
    { "id": 2, "name": "Female", "value": "female" },
]


export const MIN_MAX_FILTER_AMOUNT = [
    { "id": 1, "name": "0-50", "min": 0, "max": 100 },
    { "id": 2, "name": "101-500", "min": 101, "max": 500 },
    { "id": 3, "name": "501-1000", "min": 501, "max": 1000 },
    { "id": 4, "name": "1001-2000", "min": 1001, "max": 2000 },
    { "id": 5, "name": "2001-3000", "min": 2001, "max": 3000 },
    { "id": 6, "name": "3001-5000", "min": 3001, "max": 5000 },

]