/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import {
  Button,
  IconButton,
  FormHelperText,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Box
} from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from "@mui/material/Typography";
import AppsIcon from '@mui/icons-material/Apps';
import useStyle from './PaymentDrawerStyle';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const Redeem = ({ amount, payAmt, usePointsFlag, setUsePointsFlag, disabled }) => {
  const history = useNavigate();
  const classess = useStyle();

  useEffect(() => {
    setUsePointsFlag(true);
  }, []);

  const submitOrder = () => {
    history("/success");
  }
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={usePointsFlag}
              onChange={() => setUsePointsFlag(!usePointsFlag)}
              style={{ color: 'var(--primayColor)' }}
            //disabled={disabled}
            />
          }
          label={'I agree to redeem ' + parseFloat(payAmt.redeemPoints) + ' Points'}
        />
        <Typography
          variant="p"
          component="p"
          className={classess.redeemchecktext}
        >
          I also understand accept that the redeemed points <br></br>cannot be refunded or credited upon cancellation<br></br> of a voucher booking.
        </Typography>
      </FormGroup>
      {/* <Stack direction="row" spacing={2} style={{ paddingLeft: '0px' }}>
        <FormControl className={classess.redeemForm}>
          <FormHelperText style={{ fontFamily: 'helvetica_neue65_medium', fontSize: '15px', marginLeft: '0px' }}>Password</FormHelperText>
          <Typography
            variant="p"
            component="p"
            className={classess.redeemchecktext1}
          >
            Enter your dynamic password
          </Typography>
        </FormControl>
        <FormControl className={classess.redeemformInput}
          variant="outlined"
          size="small">
          <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
          <FormHelperText style={{ marginLeft: '0px' }}></FormHelperText>
          <OutlinedInput id="outlined-adornment-password" type="password" value={password} onChange={(e) => { setPassword(e.target.value) }}
            endAdornment={
              <InputAdornment
                position="end">
                <IconButton
                  aria-label="Search by brand" edge="end"><AppsIcon /></IconButton></InputAdornment>
            }
          />
        </FormControl>
      </Stack> */}
      <Box
        m={1}
        //margin
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"

      >
        {usePointsFlag &&
          <Button
            onClick={() => submitOrder()}
            variant="contained"
            className={classess.redeemButton} >Reedeem Now</Button>}
      </Box>
    </>
  )
}

export default Redeem