/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
    IconButton,
    FormHelperText,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Select,
    MenuItem,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useStyle from './ShopFilterStyle';
import { useQuery } from "react-query";
import { Stack } from "@mui/system";
import CommonDrawer from "../../common/CommonDrawer";
import PriceSlider from "../../common/PriceSlider";
import { getBrandsCategoryList, getCountryList } from "../../../Api/CommonApi";


export default function ShopFilter({ filterFlag, setFilterFlag, country, setCountry, category, setCategory, brand, setBrand, minMaxAmount, setMinMaxAmount }) {
    const classess = useStyle();

    const countryList = useQuery('prod_country_list', getCountryList);
    const categoryList = useQuery('prod_brand_list', () => getBrandsCategoryList("merchandise"));

    return (

        <>
            <Stack
                // spacing={2}
                // sx={{ width: "100%", justifyContent: "end" }}
                direction="horizontal"
                gap={2}
            >
                <FormControl variant="outlined" size="small">
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <FormHelperText style={{ marginLeft: "0px" }}>
                        Search by brand
                    </FormHelperText>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        value={brand}
                        onChange={(event) => setBrand(event.target.value)}
                        style={{ width: "20ch" }}
                        className={classess.inputValue}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="Search by brand" edge="end">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <FormControl
                    variant="outlined"
                    size="small"
                    className="ms-auto"
                >
                    <Button
                        onClick={() => setFilterFlag(true)}
                        className="my-1 bg-white"
                        size="small"
                        style={{
                            color: "var(--primayColor)",
                            borderColor: "var(--primayColor)",
                            fontWeight: "bold",
                        }}
                        variant="outlined"
                    >
                        <TuneIcon
                            style={{
                                marginRight: "10px",
                                transform: "rotate(-90deg)",
                            }}
                        />
                        Filter
                    </Button>
                </FormControl>
            </Stack>

            <CommonDrawer onClose={setFilterFlag} open={filterFlag}>
                <div className="filterCar m-3 d-flex flex-column">
                    <div className="d-flex flex-column mx-5">
                        <h4 className="m-0 mt-4 ">Refine your search</h4>
                        <div className="mt-5">
                            <span className="colorPrimary mt-4 fw-bold">Price</span>
                            <Stack
                                direction="horizontal"
                                gap={2}
                            //   className="d-flex space-between"
                            >
                                <span className="colorPrimary">Min</span>
                                <span className="colorPrimary ms-auto">Max</span>
                            </Stack>
                            <PriceSlider
                                minMaxAmount={minMaxAmount}
                                setMinMaxAmount={setMinMaxAmount}
                            />
                        </div>
                        <span className='my-4 hotel-filter-line'></span>

                        <FormControl className={classess.shopControll} size="large">
                            <span className="colorPrimary fw-bold mb-1">Select Country</span>
                            <Select
                                value={country}
                                onChange={(event) => setCountry(event.target.value)}>
                                <MenuItem value={""} >None</MenuItem>
                                {countryList?.data?.data?.result?.map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={item.id}>{item?.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <span className='my-4 hotel-filter-line'></span>

                        <FormControl className={classess.shopControll} size="large">
                            <span className="colorPrimary fw-bold mb-1">Select Category</span>
                            <Select
                                value={category}
                                onChange={(event) => setCategory(event.target.value)}>
                                <MenuItem value={""} >None</MenuItem>
                                {/* {categoryList?.data?.data?.result?.map((item, index) => {
                                    return (
                                        <MenuItem value={item.id} key={item.id}>{item?.default_category_name}</MenuItem>
                                    )
                                })} */}
                                {categoryList?.data?.data?.result?.sort((a, b) => a?.default_category_name.localeCompare(b?.default_category_name)).map((item, index) => {
                                    return (
                                        <MenuItem value={item.id} key={item.id}>{item?.default_category_name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                    </div>
                </div>
            </CommonDrawer>
        </>
    );
}
