import React, { useState } from "react";
import BannerComponent from "./BannerComponent";
import vouchersidebanner from "../../assets/img/voucher/romantic-surpri.webp";
import Menu from "./Menu";
import TrendingProducts from "./TrendingProducts";
import FlightResult from "./FlightResult";
import "./home.css";
import PointsComponent from "../commonComponents/PointsComponent";
export default function Home() {
  const [bannerShow, setbannerShow] = useState(true);
  return (
    <div className="w-100 d-flex mobileStack ">
      <div className="commonw50 mobileBox mainLeft">
        <div className="pe-3 pt-3">
          <PointsComponent />
        </div>

        <div className="d-flex flex-column align-items-center">
          <h1 align="center" className="mt-4">
            Give yourself the <strong>Freedom to Choose!</strong>
          </h1>
          <Menu />
          <TrendingProducts />
        </div>
      </div>
      <div className="commonw50 mobileBox">
        {bannerShow ? (
          <BannerComponent
            heading={"Welcome to the"}
            text={"World of Endless Rewards!"}
            banner={vouchersidebanner}
            button="true"
          />
        ) : (
          <FlightResult />
        )}
      </div>
    </div>
  );
}
