/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import useStyle from './ShopDetailStyle.js';
import Rating from '@mui/material/Rating';
import { shopImgArray } from '../../../assets/img/shop/index';
import {
  Container,
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Avatar,
} from '@mui/material';
import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import CommonDrawer from '../../common/CommonDrawer.js';
import { COUNRY_CODES } from '../../../utils/CountryCode.js';
import { useDispatch, useSelector } from 'react-redux';
import PaymentTab from "../../drawer/PaymentTab";
import { setContactDetailsRedux } from '../../../redux/Shop/ShopAction.js';
import { setProductOrderDetailsRedux } from '../../../redux/Order/OrderAction.js';


const ShopDetail = ({ item, currency, variants_flag }) => {
  const classess = useStyle();
  const dispatch = useDispatch();

  const accountDetailsState = useSelector((state) => state?.settingState?.accountDetails);
  const contactDetailsState = useSelector((state) => state?.shopState?.contactDetails);
  const userPointState = useSelector((state) => state?.orderState?.points);

  const [prodImg, setProdImg] = React.useState([]);
  const [card, setCard] = React.useState();
  const [persnalDetailsFlag, setPersnalDetailsFlag] = useState(false);
  const [payNowFlag, setPayNowFlag] = useState(false);
  const [selectedVeriant, setSelectedVeriant] = React.useState(null);

  const [persnalDetails, setPersnalDetails] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    pincode: "",
    email: "",
    countryCode: "",
    mobileNo: "",
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    pincode: "",
    email: "",
    countryCode: "",
    mobileNo: "",
  });

  useEffect(() => {
    if (variants_flag) {
      let arr = [];
      item?.merchandise_variant && item?.merchandise_variant.map((imgObj, i) => {
        imgObj?.images && imgObj?.images.map((img) => {
          arr.push(img)
        });
        if (i === 0) {
          setSelectedVeriant(imgObj);
        }
      });
      setCard(item?.merchandise_variant?.[0]?.images?.[0]?.file_url);
      setProdImg(arr);
    } else {
      setCard(item?.merchandise?.images && item?.merchandise?.images?.[0]?.file_url);
      setProdImg(item?.merchandise?.images);
    }
  }, [item])

  useEffect(() => {
    if (!contactDetailsState) {
      setPersnalDetails({
        ...persnalDetails,
        firstName: accountDetailsState?.firstname,
        lastName: accountDetailsState?.lastname,
        email: accountDetailsState?.email,
        countryCode: "",
        mobileNo: "",
      });
    } else {
      setPersnalDetails(contactDetailsState);
    }
  }, [accountDetailsState, contactDetailsState]);

  const productDescription = [
    {
      name: 'Product Name',
      desc: item && item?.merchandise?.default_title,
    },
    {
      name: 'Availability',
      desc: item && item?.merchandise?.availability === 'in_stock' ? 'In Stock' : 'Out Of Stock',
    },
    {
      name: 'Brand',
      desc: item?.brand?.brand_name?.EN
    },
    {
      name: 'Modal Name',
      desc: 'iPhone 11'
    },
    {
      name: 'Network Service Provide',
      desc: 'Unlocked for all devices'
    },
    {
      name: 'Operating System',
      desc: 'IOS 14'
    },
    {
      name: 'Cellular Technology',
      desc: '4G'
    },
  ]

  const validation = () => {
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!persnalDetails?.firstName) {
      setError({ ...error, firstName: "Please enter first name" });
      return false;
    } else if (!persnalDetails?.lastName) {
      setError({ ...error, lastName: "Please enter last name" });
      return false;
    } else if (!persnalDetails?.address) {
      setError({ ...error, address: "Please enter address" });
      return false;
    } else if (!persnalDetails?.city) {
      setError({ ...error, city: "Please enter city" });
      return false;
    } else if (!persnalDetails?.pincode) {
      setError({ ...error, pincode: "Please enter pincode" });
      return false;
    } else if (!persnalDetails?.email) {
      setError({ ...error, email: "Please enter email" });
      return false;
    } else if (!persnalDetails.email || emailRegex.test(persnalDetails.email) === false) {
      setError({ ...error, email: "Enter valid email" });
      return false;
    } else if (!persnalDetails?.countryCode) {
      setError({ ...error, countryCode: "Please select country code" });
      return false;
    } else if (!persnalDetails?.mobileNo) {
      setError({ ...error, mobileNo: "Please enter mobile number" });
      return false;
    }

    return true;
  };

  const nextButtonHandler = () => {
    const val = validation();
    if (val) {
      setPayNowFlag(true);
      setPersnalDetailsFlag(false);
      dispatch(setContactDetailsRedux(persnalDetails));

      // send object to order creation and store in redux
      let obj = {
        prodType: "merchandise",
        prodDetails: item,
        persnalDetails: persnalDetails,
        quantity: 1,
        amount: item?.product_class === "merchandise_variant" ? item?.merchandise_variant[0]?.selling_price : item?.selling_price,
        productVariant: item?.product_class === "merchandise_variant" ? item?.merchandise_variant[0]?.variant?.merchandise_id : "",
        discount: 0
      }
      dispatch(setProductOrderDetailsRedux(obj));
    }

  }
  return (
    <>
      <div className={
        classess.cardDatail
      } style={{ width: '-webkit-fill-available' }}>
        <div className={
          classess.cardConcave
        }></div>
        <Container maxWidth="lg">
          <>

            {/* Product Banner and subBanner Start */}

            <Stack
              direction="row"
              sx={{ width: '100%', marginTop: '0px', flexDirection: { xs: 'column', sm: 'row' } }}>

              <Box flex={10} className={classess.mainBanner} >
                <Box flex={12} className={classess.hero} >
                  <div className='bg-white pb-5'>
                    <img src={card}
                      alt="logo" className={classess.logo} />
                  </div>
                </Box>
              </Box>
              <Box flex={2}>
                <Box
                  className={classess.hiddenDiv}
                  sx={{ display: { xs: 'flex', sm: 'block' }, width: { xs: '100%', sm: '200px' } }}>
                  {prodImg && prodImg.map((curr, index) => {
                    return (
                      <Box key={index} flex={12}
                        className={classess.subBanner}
                        style={{ marginTop: `${curr === 1} && '0px'` }}
                        onClick={() => setCard(curr?.file_url)}
                      >
                        <div>
                          <img
                            src={curr?.file_url}
                            alt="logo"
                            className={classess.sublogo}
                          />
                        </div>
                      </Box>
                    )
                  })}
                </Box>
              </Box>

            </Stack>


            {/* Product Description Start */}

            <Box flex={12} style={{ margin: '25px 0px' }}>
              <Divider style={{ border: '1px solid #707070' }} />
              <Stack direction="row" sx={{ width: '100%' }} >
                <Box flex={8} >
                  <Typography variant="h6"
                    className={
                      classess.voucherText
                    }>
                    {item?.merchandise?.default_title}  </Typography>

                  <Rating name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly size="small" style={{ color: '#000000' }} />
                </Box>
                <Box flex={4} style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <Typography variant="h2" style={{ fontWeight: 'bold' }}
                    className={classess.voucherText}>

                    {currency} {item?.selling_price}
                  </Typography>

                  {/* <Typography variant="h2"
                    className={classess.voucherData}>
                    <small style={{ fontSize: '12px', margin: '0px !important' }}>(MRP 2,269) 16% Discount</small>
                  </Typography> */}

                  <div className='my-3'>
                    <Button
                      onClick={() => setPersnalDetailsFlag(true)}
                      variant="contained"
                      className="backcolorPrimary my-4"
                      style={{ textTransform: "initial" }}
                    >
                      Buy Now
                    </Button>
                  </div>
                </Box>
              </Stack>

              <Divider style={{ border: '1px solid #707070' }} />
            </Box>

            {/* Product Description End */}

            {/* Product Offers Start */}

            <Box flex={12} style={{ margin: '25px 0px' }}>

              <Box flex={12} >
                <Typography variant="h6"
                  className={
                    classess.voucherHeading
                  }>
                  Save Extra with 4 Offers </Typography>
                <dl className={classess.listData}> <dd className={classess.listItem}><strong>Cashback:</strong> Sign up for Flipkart Pay Later and get Flipkart Gift Card worth upto ₹1000</dd> </dl>
                <dl className={classess.listData}> <dd className={classess.listItem}><strong>Exchange Offer:</strong> 5% Cashback on Flipkart Axis Bank Card </dd></dl>
                <dl className={classess.listData}><dd className={classess.listItem}> <strong>No Cost EMI:</strong> Buy this product and get upto ₹500 off on Flipkart Furniture</dd> </dl>
                <dl className={classess.listData}> <dd className={classess.listItem}><strong>Partners Offers:</strong> Stand a chance to win Google Mini - #unbox with Canon</dd> </dl>



              </Box>

              <Divider style={{ border: '1px solid #707070' }} />
            </Box>
            {/* Product Offers End */}


            {item && variants_flag ?
              <>
                <Box flex={12} style={{ margin: '25px 0px' }}>

                  <Box flex={12} >
                    <Typography variant="h6"
                      className={
                        classess.voucherHeading
                      }>
                      7 Days replacement only </Typography>
                    <Typography variant="h6"
                      className={classess.voucherHeading} style={{ color: "#282828" }}>
                      {item && item?.merchandise_variant?.[0] && item?.merchandise_variant?.[0]?.variant && item?.merchandise_variant?.[0]?.variant?.[0]?.variant_keys}
                    </Typography>

                    {variants_flag === true &&
                      item && item?.merchandise_variant?.[0] && item?.merchandise_variant?.[0]?.variant &&
                      item?.merchandise_variant?.[0]?.variant?.map((value, index) => (
                        <Button variant="outlined"
                          className={item?.merchandise_variant?.[index]?.variant?.[0]?.id === selectedVeriant?.variant?.[0]?.id ? classess.shopSecondButton : classess.shopButton}
                          style={{ marginRight: '10px' }}
                          onClick={() => setSelectedVeriant(item?.merchandise_variant?.[index])}
                        >
                          {value?.variant_values}
                        </Button>
                      ))}

                    {/* <Typography variant="h6"
                      className={
                        classess.voucherHeading
                      } style={{ color: "#282828" }}>
                      Color: Black </Typography>

                    {['#000000', '#bb0b2e', '#abdfcc', '#e8e6eb', '#ffffff', '#fde77f'].map((color) => {
                      return (
                        <Button variant="outlined" className={classess.shopcolor || 'mb-1'} style={{ marginRight: '10px', marginBottom: '10px', backgroundColor: `${color}`, border: 'none' }}>
                        </Button>
                      )
                    })} */}

                  </Box>
                </Box>
                <Divider style={{ border: '1px solid #707070' }} />
              </>
              : null}


            <Box flex={12} style={{ margin: '15px 0px' }}>
              {
                productDescription.map((curr, index) => {
                  return (
                    <Stack key={index} direction="row" sx={{ width: '100%', lineHeight: '0.6px' }} >
                      <Box flex={4}>
                        <Typography variant="h6"
                          className={classess.productHeading}
                          style={{ color: "#282828", margin: '0px 0px !important' }}>
                          {curr.name}: </Typography>
                      </Box>

                      <Box flex={6}>
                        <Typography variant="h6"
                          className={classess.productHeadingSection}
                          style={{ color: '#282828', fontFamily: 'helvetica_neue45_light !important', margin: '0px 0px !important' }}>
                          {curr.desc} </Typography>
                      </Box>
                    </Stack>
                  )
                })
              }
            </Box>
            <Divider style={{ border: '1px solid #707070' }} />


            <Box flex={12} style={{ margin: '25px 0px' }}>

              <Box flex={12} >
                <Typography variant="h6"
                  className={
                    classess.voucherHeading
                  } style={{ color: 'black' }}>
                  About this item </Typography>
                <dl className={classess.listData} style={{ paddingBottom: '40px' }}>
                  {/* <dd className={classess.listItem}>- {item?.about_the_product[0].description_text}</dd> */}
                  <dd className={classess.listItem}>- Sensor Type: CMOS</dd>
                  <dd className={classess.listItem}>- WiFi Available</dd>
                  <dd className={classess.listItem}>- MP4</dd>
                  <dd className={classess.listItem}>- Memory Card is not included in this package. It needs to be purchased separately.</dd>
                  <dd className={classess.listItem}>- 7 day seller replacement policy/brand assistance for device issues</dd>
                  <dd className={classess.listItem}>- GST invoice available</dd>
                  <dd className={classess.listItem}>- Camera, Camera Strap, Charger, Battery, Charging Cable</dd>

                </dl>
              </Box>

            </Box>
          </>
          <CommonDrawer onClose={setPersnalDetailsFlag} open={persnalDetailsFlag}>
            <div className="bookFlight d-flex flex-column">
              <h4>Contact Details</h4>

              <div className="d-flex w-100 columnReverse">

                <div className=" w-100 mobileBox">
                  <div className="mt-4">
                    <div className="mobileBlock mt-1">
                      <FormControl fullWidth className="me-3">
                        <label className="mb-1">First Name</label>
                        <TextField
                          onChange={(e) => setPersnalDetails({ ...persnalDetails, firstName: e.target.value })}
                          value={persnalDetails?.firstName}
                          // name={"firstName"}
                          // id={item?.visitorsId}
                          inputProps={{ inputMode: "text", style: { padding: 10 } }}
                        />
                        <label className="text-error t-error w-100 my-1">{!persnalDetails?.firstName && error.firstName ? error.firstName : ""}</label>

                      </FormControl>
                      <FormControl fullWidth>
                        <label className="mb-1">Last Name</label>
                        <TextField
                          onChange={(e) => setPersnalDetails({ ...persnalDetails, lastName: e.target.value })}
                          value={persnalDetails?.lastName}
                          // name={"lastName"}
                          // id={item?.visitorsId}
                          inputProps={{ inputMode: "text", style: { padding: 10 } }}
                        />
                        <label className="text-error t-error w-100 my-1">{!persnalDetails?.lastName && error.lastName ? error.lastName : ""}</label>

                      </FormControl>
                    </div>
                    <div className="mobileBlock mt-1">
                      <FormControl fullWidth className="">
                        <label className="mb-1">Address</label>
                        <TextField
                          onChange={(e) => {
                            setPersnalDetails({ ...persnalDetails, address: e.target.value });
                            setError({ ...error, address: "" });
                          }}
                          value={persnalDetails?.address}
                          // name={"address"}
                          inputProps={{ inputMode: "text", style: { padding: 10 } }}
                        />
                        <label className="text-error t-error w-100 my-1">{error.address ? error.address : ""}</label>

                      </FormControl>
                    </div>
                    <div className="mt-1 mobileBlock">
                      <FormControl fullWidth className='me-3 '>
                        <label className="mb-1">City</label>
                        <TextField
                          onChange={(e) => setPersnalDetails({ ...persnalDetails, city: e.target.value })}
                          value={persnalDetails?.city}
                          // name={"lastName"}
                          // id={item?.visitorsId}
                          inputProps={{ inputMode: "text", style: { padding: 10 } }}
                        />

                        <label className="text-error t-error w-100 my-1">{!persnalDetails?.city && error.city ? error.city : ""}</label>
                      </FormControl>
                      <FormControl fullWidth className="w-50" sx={{ minWidth: 120 }} size="small">
                        <label className="mb-1">Pincode</label>
                        <TextField
                          onChange={(e) => {
                            let regex = /^\d{0,10}?$/;
                            if (regex.test(e.target.value)) {
                              setPersnalDetails({ ...persnalDetails, pincode: e.target.value })
                            }
                          }}
                          value={persnalDetails?.pincode}
                          // name={"lastName"}
                          // id={item?.visitorsId}
                          inputProps={{ inputMode: "text", style: { padding: 10 } }}
                        />

                        <label className="text-error t-error w-100 my-1">{!persnalDetails?.pincode && error.pincode ? error.pincode : ""}</label>
                      </FormControl>

                    </div>
                    <div className="mobileBlock mt-1">
                      <FormControl fullWidth className="">
                        <label className="mb-1">Email</label>
                        <TextField
                          onChange={(e) => {
                            setPersnalDetails({ ...persnalDetails, email: e.target.value.toLowerCase() });
                            setError({ ...error, email: "" });
                          }}
                          value={persnalDetails?.email}
                          // name={"firstName"}
                          // id={item?.visitorsId}
                          inputProps={{ inputMode: "text", style: { padding: 10 } }}
                        />
                        <label className="text-error t-error w-100 my-1">{error.email ? error.email : ""}</label>

                      </FormControl>
                    </div>
                    <div className="mt-1 mobileBlock">
                      <FormControl fullWidth className="me-3 w-50" sx={{ minWidth: 120 }} size="small">
                        <label className="mb-1">Country Code</label>
                        <Select
                          labelId="demo-select-small"
                          style={{ padding: 2 }}
                          // name={item?.visitorsId}
                          // id={item?.visitorsId}
                          onChange={(e) => setPersnalDetails({ ...persnalDetails, countryCode: e.target.value })}
                          value={persnalDetails?.countryCode}
                        >
                          {COUNRY_CODES && COUNRY_CODES.map((c, i) => (
                            <MenuItem key={i} value={c}>{c?.code + "   " + c?.dial_code}</MenuItem>
                          ))}
                        </Select>
                        <label className="text-error t-error w-100 my-1">{!persnalDetails?.countryCode && error.countryCode ? error.countryCode : ""}</label>
                      </FormControl>
                      <FormControl fullWidth>
                        <label className="mb-1">Mobile Number</label>
                        <TextField
                          onChange={(e) => {
                            let regex = /^\d{0,15}?$/;
                            if (regex.test(e.target.value)) {
                              setPersnalDetails({ ...persnalDetails, mobileNo: e.target.value })
                            }
                          }}
                          value={persnalDetails?.mobileNo}
                          // name={"lastName"}
                          // id={item?.visitorsId}
                          inputProps={{ inputMode: "text", style: { padding: 10 } }}
                        />

                        <label className="text-error t-error w-100 my-1">{!persnalDetails?.mobileNo && error.mobileNo ? error.mobileNo : ""}</label>
                      </FormControl>

                    </div>
                  </div>

                  <div className='my-2'>
                    <Button
                      onClick={() => nextButtonHandler()}
                      variant="contained"
                      className="backcolorPrimary my-4"
                      style={{ textTransform: "initial" }}
                    >
                      Next
                    </Button>
                  </div>
                </div>

              </div>
            </div>
          </CommonDrawer>
          <CommonDrawer onClose={setPayNowFlag} open={payNowFlag}>
            <Box sx={{ bgcolor: "#ffffff", width: "100%" }}>
              <Box
                style={{ margin: '20px 0px 20px 0px', width: "100%" }}
                sx={{ width: "right" === "top" || "right" === "bottom" ? "auto" : 250 }}
                role="presentation">
                <Container maxWidth="sm">
                  <Stack direction="row" spacing={2} justifyContent={"space-between"} style={{ paddingLeft: '0px' }}>
                    <Stack direction="row" justifyContent="space-evenly" spacing={2}>
                      <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                      <Typography
                        variant="h6"
                        component="h6"
                      >
                        {accountDetailsState?.firstname + " " + accountDetailsState?.lastname}
                      </Typography>
                    </Stack>
                    {userPointState && <Typography
                      variant="h6"
                      component="h6"
                    >
                      {userPointState} Pts
                    </Typography>}

                  </Stack>
                </Container>
              </Box>
              <PaymentTab
                amount={item?.product_class === "merchandise_variant" ? item?.merchandise_variant[0]?.selling_price : item?.selling_price}
                discount={0}
              />
            </Box>
          </CommonDrawer>
        </Container>
      </div>
    </>
  )
}

export default ShopDetail
