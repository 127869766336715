import image1 from '../../img/shop/Offers.svg'
import image2 from '../../img/shop/Group.svg'
import image3 from '../../img/shop/Grocery.svg'
import image4 from '../../img/shop/Fashion.svg'
import image5 from '../../img/shop/Electronics.svg'
import image6 from '../../img/shop/Home.svg'

import shop1 from '../../img/shop/01.jpg'
import shop2 from '../../img/shop/02.jpg'
import shop3 from '../../img/shop/03.jpg'
// import shop4 from  '../../img/shop/04.jpg'
import shop5 from '../../img/shop/05.jpg'
import shop6 from '../../img/shop/06.jpg'

import prod1 from '../../img/shop/iPhone.jpg'
import prod2 from '../../img/shop/MacPro.jpg'
import prod3 from '../../img/shop/ProtableBattery.jpg'
import prod4 from '../../img/shop/LEDTV.jpg'
import prod5 from '../../img/shop/JuicerMixer.jpg'
import prod6 from '../../img/shop/WashingMachine.jpg'



const bgImgArray = [image1, image2,
    // image3, 

    // image4,
    //image5,
    image6];

export const shopImgArray = [shop1, shop2, shop3, shop5, shop6];

export const prodImgArray = [prod1, prod2, prod3, prod4, prod5, prod6];

export default bgImgArray;
