import {
  SET_ACCOUNT_DETAILS,
  SET_SETTINGS_DATA,
} from "./settingsType";

export const setSettingsData = (data) => {
  return {
    type: SET_SETTINGS_DATA,
    payload: data,
  };
};


export const setAccountDetailsRedux = (data) => {
  return {
    type: SET_ACCOUNT_DETAILS,
    payload: data,
  };
};
