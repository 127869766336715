import image1 from  './voucher/bella.jpg'
import image2 from  './voucher/almeera.png'
import image3 from  './voucher/careem.png'
import image4 from  './voucher/mall-of-qatar.png'
import image5 from  './voucher/joyalukkas.png'
import image6 from  './voucher/6thstreet.png'


const bgImgArray = [image1, image2, image3, image4, image5, image6];

export default bgImgArray;
