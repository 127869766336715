import ApiToken from "./apiToken";

export const getMerchandiseProducts = async (
  limit,
  skip,
  country,
  category,
  brand
) => {
  const response = await ApiToken.get(
    `/v1/merchandise/client/63beeb010b1e9902ec22f4c1/mappingProducts?is_detailed=true&skip=${skip}&limit=${limit}${
      category ? "&category_id=" + category : ""
    }${brand ? "&brand_name=" + brand : ""}${
      country?.id ? "&country_id=" + country?.id : ""
    }`
  );
  return response;
};
