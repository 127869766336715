import { Skeleton } from '@mui/material';
import React from 'react';
import banner from "../../../assets/img/Attraction/banner.png";


const AttractionCard = ({ item, title, imgUrl, currency }) => {


    return (
        item ?
            <div class="card card--1 ">
                <div class="card__img"></div>
                <div classname="card_link">
                    <div class="card__img--hover" style={{ backgroundImage: `url(${imgUrl ? imgUrl : banner})` }}>
                    </div>
                </div>
                <div class="card__info">
                    {/* <span class="card__category"> Recipe</span> */}
                    <h3 class="card__title">{title?.slice(0, 50)}</h3>
                    <span class="card__by">{currency?.currency + " " + item?.price} </span>

                </div>
            </div> :
            <div>
                <Skeleton animation="wave" height={"220px"} />
                <Skeleton animation="wave" height={"50px"} />
                <Skeleton animation="wave" height={"30px"} />
            </div>
    )
}

export default AttractionCard