
// DEV
export const TRIPXOXO = "https://apistaging.tripxoxo.com"


export const getToken = () => {
    let token = localStorage.getItem("token");
    const removed = token.replace(/"/g, '');
    return removed;
}
