import React, { useState } from "react";
import {
  Box, Button,
} from "@mui/material";
import defaultLogo from "../../../assets/img/svg/sample-logo.png";
import DragandDrop from "../../DrapandDrop/DragandDrop";
import { SketchPicker } from "react-color";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLIENT_ID } from "../../../utils/constantData";
import { putSettings } from "../../../Api/SettingsApi";
import { setSettingsData } from "../../../redux/Settings/settingsAction";
import { Snackbar } from "@mui/material";

import ehadayaLogo from "../../../assets/img/sidebar/ehadaya.svg";

const SettingRight = (props) => {
  const [siteLogoImgFiles, setSiteLogoImgFiles] = useState([]);
  const [siteDarkLogoImgFiles, setSiteDarkLogoImgFiles] = useState([]);
  const [color, setColor] = useState("#721166");
  const [secondaryTextColor, setSecondaryTextColor] = useState("#FFFFFF");
  const settingState = useSelector((state) => state?.settingState?.settingResponse?.site_details);
  console.log(useSelector((state) => state?.settingState?.settingResponse))
  const [imgFiles, setImgFiles] = useState();
  const [darkImgFiles, setDarkImgFiles] = useState();
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch()
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };



  useEffect(() => {
    document.documentElement.style.setProperty('--primayColor', color.hex ? color.hex : color);
    document.documentElement.style.setProperty('--primaryTextColor', color.hex ? color.hex : color);
    document.documentElement.style.setProperty('--secondaryTextColor', secondaryTextColor.hex ? secondaryTextColor.hex : secondaryTextColor);


  }, [color, secondaryTextColor])


  useEffect(() => {
    setColor({
      ...color,
      hex: settingState?.primary_color
    })
    setImgFiles(settingState?.logo)
    // setDarkImgFiles(settingState?.logo)
  }, [settingState])

  useEffect(() => {
    if (siteLogoImgFiles?.[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(siteLogoImgFiles?.[0]);
      reader.onload = function (e) {
        console.log(e.target.result)
        setImgFiles(e.target.result)
      };
    }
  }, [siteLogoImgFiles])

  useEffect(() => {
    if (siteDarkLogoImgFiles?.[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(siteDarkLogoImgFiles?.[0]);
      reader.onload = function (e) {
        console.log(e.target.result)
        setDarkImgFiles(e.target.result)
      };
    }
  }, [siteDarkLogoImgFiles])

  // const submit = async () => {
  //   let payload = {
  //     site_details: {
  //       primary_color: color.hex,
  //       site_logo: imgFiles,
  //       dark_logo: darkImgFiles
  //     }
  //   }

  //   const res = await putSettings(payload)
  //   dispatch(setSettingsData(res.data?.result));
  // }

  const submit = async () => {
    setFormErrors(validate());
    if (Object.keys(formErrors).length === 0) {
      let payload = {
        site_details: {
          primary_color: color.hex,
          site_logo: imgFiles,
          dark_logo: darkImgFiles
        }
      }
      const res = await putSettings(payload)
      dispatch(setSettingsData(res.data?.result));
      handleOpenSnackbar(); // show success message
    }
  };


  const validate = () => {
    let errors = {};
    if (!imgFiles) {
      errors.logo = "Please upload a logo";
    }
    if (!darkImgFiles) {
      errors.darkLogo = "Please upload a dark logo";
    }
    return errors;
  };



  return (
    <>
      <Box
        style={{ padding: "0 0 0 10px" }}
        className="profileMobile settingRight"
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column mt-3 mb-3 ">
            Primary color
            <SketchPicker
              color={color}
              onChange={(updatedColor) => setColor(updatedColor)}
            />
          </div>

          <div className="d-flex flex-column mt-3 mb-3 ">
            Text color
            <SketchPicker
              color={secondaryTextColor}
              onChange={(updatedColor) => setSecondaryTextColor(updatedColor)}
            />
          </div>
        </div>

        <div className="d-flex flex-column mt-3 mb-3 ">
          Light Logo Image
          <div style={{ margin: "0 auto" }}>
            <img className="main-logo" src={settingState?.site_logo || defaultLogo} alt="ehadaya" />
          </div>
          <DragandDrop files={siteLogoImgFiles || imgFiles} setFiles={setSiteLogoImgFiles} />
          {formErrors.logo && (
            <span className="text-danger">{formErrors.logo}</span>
          )}
        </div>

        <div className="d-flex flex-column mt-3 mb-3 ">
          Dark Logo Image
          <div style={{ margin: "0 auto" }}>
            <img className="main-logo" src={settingState?.dark_logo} alt="ehadaya" />
          </div>
          <DragandDrop files={siteDarkLogoImgFiles || imgFiles} setFiles={setSiteDarkLogoImgFiles} />
          {formErrors.darkLogo && (
            <span className="text-danger">{formErrors.darkLogo}</span>
          )}
        </div>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message="Settings updated successfully"
        />

        {/* <div style={{ textAlign: "right" }}>
          <Button variant="outlined" className={classess.borderBtn} onClick={saveHandler}>
            Save Changes
          </Button>
        </div> */}
        <div style={{ textAlign: "right" }}>
          <Button
            id="primaryBorderColor"
            variant="outlined"
            className={`borderButton`}
            onClick={() => submit()}
          >
            Save Changes
          </Button>
        </div>
      </Box>
    </>
  );
};

export default SettingRight;
