import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./Travelcard.css"
const TravelCard = ({ banner, color, title, link, state }) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


  return (

    <div className='mainTravelcard my-3'>
      <Link
        to={link}
        state={{ state }}
      >
        <div
          className={`travelCard ${color === "grey" ? 'travelCardGrey' : ''} `}
          style={{ background: `url(${banner}) no-repeat center center` }}
        >
        </div>

        <div className={`textcard ${color === "grey" ? 'textcardGrey' : ''}`}>
          {title}
        </div>
      </Link>

    </div >
  )
}

export default TravelCard