import { combineReducers } from "redux";
import flightReducer from "./Flight/flightReducer";
import settingsReducer from "./Settings/settingsReducer";
import hotelReducer from "./Hotel/hotelReducer";
import attractionReducer from "./Attraction/AttractionReducer";
import shopReducer from "./Shop/ShopReducer";
import orderReducer from "./Order/OrderReducer";


const rootReducer = combineReducers({

    flightState: flightReducer,
    hotelState: hotelReducer,
    settingState: settingsReducer,
    attractionState: attractionReducer,
    shopState: shopReducer,
    orderState: orderReducer

});

export default rootReducer;