/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react'
import useStyle from './AttractionDetailStyle';
import { Container, Box, Typography, Grid, Button, ButtonGroup, Stack, Rating, CircularProgress, FormControl, Select, MenuItem, TextField, FormControlLabel, Checkbox, Avatar, } from '@mui/material';
//import bgImgArray from "../../../assets/img/index";
import TemporaryDrawer from '../../drawer/PaymentDrawer'
import { useState } from 'react';
import DatePicker from '../../DatePicker/DatePicker';
import moment from "moment";
import banner from "../../../assets/img/Attraction/banner.png";
import SnackbarAlert from '../../common/SnackbarAlert';
import CommonDrawer from '../../common/CommonDrawer';
import { getAttractionsAvailability } from '../../../Api/AttractionsApi';
import { GENDER, NAME_TITTLE, TRIPXOXO_CUSTOMER_ID } from '../../../utils/constantData';
import { COUNRY_CODES } from '../../../utils/CountryCode';
import { maxYearCalculate, minYearCalculate } from '../../../utils/constantFunctions';
import PaymentTab from "../../drawer/PaymentTab";
import { useNavigate } from 'react-router-dom';
import LoginScreen from '../../signin/LoginScreen';
import { useDispatch, useSelector } from 'react-redux';
import { setAvailabilityRedux, setContactDetailsRedux, setTicketsRedux, setVisitorsDetailsRedux } from '../../../redux/Attraction/AttractionAction';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const AttractionDetail = ({ productDetails, loader }) => {
    const bgImgArray = [banner, banner, banner, banner, banner, banner];
    const classess = useStyle();
    const history = useNavigate();
    const dispatch = useDispatch();

    const accountDetailsState = useSelector((state) => state?.settingState?.accountDetails);
    const ticketsState = useSelector((state) => state?.attractionState?.tickets);
    const availabilityState = useSelector((state) => state?.attractionState?.availability);
    const visitorsDetailsState = useSelector((state) => state?.attractionState?.visitorsDetails);
    const contactDetailsState = useSelector((state) => state?.attractionState?.contactDetails);

    const [availabilityLoader, setAvailabilityLoader] = useState(false);

    const [card, setCard] = useState("");
    const [date, setDate] = useState(new Date());
    const [tickets, setTickets] = useState(null);
    const [availabilityDetails, setAvailabilityDetails] = useState(null);
    const [visitorsFlag, setVisitorsFlag] = useState(false);
    const [persnalDetailsFlag, setPersnalDetailsFlag] = useState(false);
    const [loginFlag, setLoginFlag] = useState(false);

    const [payNowFlag, setPayNowFlag] = useState(false);
    const [paymentSucessFlag, setPaymentSucessFlag] = useState(false);

    const [loginStatus, setLoginStatus] = useState(false);

    const [persnalDetails, setPersnalDetails] = useState({
        firstName: "",
        lastName: "",
        email: "",
        countryCode: "",
        mobileNo: "",
    });

    const [visitorsDetails, setVisitorsDetails] = useState(null);
    const [paymentDetalis, setPaymentDetalis] = useState(null);

    const [snackbar, setSnackbar] = useState(false);

    const [error, setError] = useState({
        date: "",
        visitor: "",
        firstName: "",
        lastName: "",
        email: "",
        countryCode: "",
        mobileNo: "",
        dob: "",
        gender: "",
        leadTraveller: ""

    });

    useEffect(() => {
        setLoginStatus(Boolean(localStorage.getItem("loggedIn")));
        setError({ ...error, date: "", visitor: "", firstName: "", lastName: "", email: "", countryCode: "", mobileNo: "", dob: "", gender: "", leadTraveller: "" });

    }, []);

    useEffect(() => {
        if (!contactDetailsState) {
            setPersnalDetails({
                ...persnalDetails,
                firstName: accountDetailsState?.firstname,
                lastName: accountDetailsState?.lastname,
                email: accountDetailsState?.email,
                countryCode: "",
                mobileNo: "",
            });
        }
    }, [accountDetailsState]);

    useEffect(() => {
        if (ticketsState?.date) {
            setDate(new Date(ticketsState?.date));
        }
        if (availabilityState) {
            setAvailabilityDetails(availabilityState);
        } else {
            setAvailabilityDetails(null);
        }
        if (contactDetailsState) {
            setPersnalDetails(contactDetailsState);
        }
    }, [ticketsState, availabilityState, contactDetailsState]);


    useEffect(() => {
        const ticketsArr = productDetails?.tickets ? [...productDetails?.tickets] : [];
        const arr = ticketsArr && ticketsArr.map((object, i) => {
            return { ...object, "qty": 0 };
        });

        if (ticketsState?.tickets) {
            setTickets(ticketsState?.tickets);
        } else {
            productDetails?.tickets && arr && setTickets(arr);
        }
        setError({ ...error, firstName: "", lastName: "", dob: "", gender: "", leadTraveller: "" });
    }, [productDetails, ticketsState]);

    useEffect(() => {
        let visitorListArr = [];
        tickets && tickets.map((item, index) => {
            if (item.qty) {
                for (let i = 1; i <= Number(item?.qty); i++) {
                    let visitors = {
                        "visitorsId": (item?.name + "-" + i + 1),
                        "name": "",
                        "title": "",
                        "firstName": "",
                        "lastName": "",
                        "leadTraveller": false,
                        "dob": "",
                        "gender": "",
                        "type": item
                    }
                    visitorListArr.push(visitors)
                }
            }
        });
        if (visitorsDetailsState) {
            setVisitorsDetails(visitorsDetailsState);
        } else {
            setVisitorsDetails(visitorListArr);
        }
    }, [availabilityDetails, visitorsDetailsState]);


    const addHandler = (qty, id) => {
        const ticketsArr = [...tickets];
        ticketsArr && ticketsArr.map((item, i) => {
            if (item?.id === id) {
                tickets[i].qty = qty;
            }
        });
        setTickets(ticketsArr);
        setAvailabilityDetails(null);
        setError({ ...error, visitor: "" });
    };

    const minusHandler = (qty, id) => {
        const ticketsArr = [...tickets];
        ticketsArr && ticketsArr.map((item, i) => {
            if (item?.id === id) {
                tickets[i].qty = qty;
            }
        });
        setTickets(ticketsArr);
        setAvailabilityDetails(null);
        setError({ ...error, visitor: "" });

    };

    const dateHandler = (item) => {
        setDate(item);
        setAvailabilityDetails(null);
    };

    const handleClose = (item) => {
        setSnackbar(false);
    };

    const titleSelectHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const listArr = [...visitorsDetails];
        listArr && listArr.map((item, index) => {
            if (item.visitorsId === name) {
                listArr[index].title = value
            }
        });
        setVisitorsDetails(listArr);
    };

    const firstNameHandler = (e) => {
        const id = e.target.id;
        // const name = e.target.name;
        const value = e.target.value;
        const listArr = [...visitorsDetails];

        listArr && listArr.map((item, index) => {
            if (item.visitorsId === id) {
                listArr[index].firstName = value
            }
        });
        setVisitorsDetails(listArr);
    };

    const lastNameHandler = (e) => {
        const id = e.target.id;
        // const name = e.target.name;
        const value = e.target.value;
        const listArr = [...visitorsDetails];

        listArr && listArr.map((item, index) => {
            if (item.visitorsId === id) {
                listArr[index].lastName = value
            }
        });
        setVisitorsDetails(listArr);
    };

    const dobDateHandler = (e) => {
        const id = e.target.id;
        //const name = e.target.name;
        const value = e.target.value;
        const listArr = [...visitorsDetails];
        listArr && listArr.map((item, index) => {
            if (item.visitorsId === id) {
                listArr[index].dob = moment(new Date(value)).format("YYYY-MM-DD");
            }
        });
        setVisitorsDetails(listArr);
        setError({ ...error, dob: "" });

    };

    const genderSelectHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const listArr = [...visitorsDetails];
        listArr && listArr.map((item, index) => {
            if (item.visitorsId === name) {
                listArr[index].gender = value
            }
        });
        setVisitorsDetails(listArr);
    };

    const leadTravellerHandleChange = (e) => {
        const name = e.target.name;
        //  const value = e.target.value;
        const listArr = [...visitorsDetails];
        listArr && listArr.map((item, index) => {
            if (item.visitorsId === name) {
                listArr[index].leadTraveller = !item?.leadTraveller
            }
        });
        setVisitorsDetails(listArr);
    };

    const validation = () => {
        //  let arr = productDetails.availableOnlyOnDates

        let valPrice = tickets && tickets.every((v) => v.id === "1" ? v.qty > 0 : true);

        if (!valPrice) {
            setError({ ...error, visitor: "Please select visitor" });
            return false;
        }


        // else if (arr.includes(moment(date).format('YYYY-MM-DD'))) {
        //     return true
        // } else {
        //     setSnackbar(true);
        //     return false
        // }
        return true;
    };

    const checkAvailabilityHandler = async () => {
        const val = validation();
        let ticketsArr = [];
        tickets && tickets.map(item => {
            ticketsArr.push({ "id": item.id, "qty": item.qty });
        })
        let obj = {
            productId: productDetails?.productId,
            date: moment(date).format('YYYY/MM/DD'),
            tickets: ticketsArr
        }
        if (val) {
            setAvailabilityLoader(true);
            const res = await getAttractionsAvailability(obj);
            setAvailabilityDetails(res.data.data);
            setAvailabilityLoader(false);
            let ticketsObj = {
                date: date,
                tickets: tickets
            }
            dispatch(setTicketsRedux(ticketsObj));
            dispatch(setAvailabilityRedux(res.data.data));
            dispatch(setVisitorsDetailsRedux(null));


        }
    };

    const visitorHandler = () => {
        const val = validation();
        if (val && loginStatus) {
            setVisitorsFlag(true);
        } else if (!loginStatus) {
            setLoginFlag(true);
        }


    };

    const visitorsDetailsValidation = () => {
        let titleVal = visitorsDetails && visitorsDetails.every((v) => v.firstName);
        let firstNameVal = visitorsDetails && visitorsDetails.every((v) => v.firstName);
        let lastNameVal = visitorsDetails && visitorsDetails.every((v) => v.lastName);
        let dobDateVal = visitorsDetails && visitorsDetails.every((v) => v.dob);
        let genderVal = visitorsDetails && visitorsDetails.every((v) => v.gender);
        let leadTravellerVal = visitorsDetails && visitorsDetails.every((v) => v.leadTraveller);
        if (!titleVal || !firstNameVal || !lastNameVal || !dobDateVal || !genderVal || !leadTravellerVal) {
            setError({
                ...error,
                title: "Please select title",
                firstName: "Please enter first name",
                lastName: "Please enter last name",
                dob: "Please select date of birth",
                gender: "Please select gender",
                leadTraveller: "Please check lead traveller"
            });
            return false

        } else {
            setError({
                ...error,
                firstName: "",
                lastName: "",
                dob: "",
                gender: "",
                leadTraveller: ""
            });
            return true;

        }
    }

    const Next = () => {
        const val = visitorsDetailsValidation();
        if (val) {
            setVisitorsFlag(false);
            setPersnalDetailsFlag(true);
            dispatch(setVisitorsDetailsRedux(visitorsDetails));
        }

    };

    const persnalDetailsValidation = () => {
        const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (!persnalDetails.firstName) {
            setError({ ...error, firstName: "Please enter first name" });
            return false
        } else if (!persnalDetails.lastName) {
            setError({ ...error, lastName: "Please enter last name" });
            return false
        } else if (!persnalDetails.email) {
            setError({ ...error, email: "Please enter email address" });
            return false
        } else if (!persnalDetails.email || emailRegex.test(persnalDetails.email) === false) {
            setError({ ...error, email: "Enter valid email" });
            return false;
        } else if (!persnalDetails.countryCode) {
            setError({ ...error, countryCode: "Please select country code" });
            return false
        } else if (!persnalDetails.mobileNo) {
            setError({ ...error, mobileNo: "Please enter mobile number" });
            return false
        }
        return true;
    }

    const bookNow = () => {
        const obj = {
            "cartId": 0,
            "customerId": TRIPXOXO_CUSTOMER_ID,
            "currency": "",
            "product": {
                "productId": productDetails?.productId,
                "date": "2023-01-10",
                "time": "",
                "ticketType": "",
                "sessionKey": "",
                "sessionToken": "",
                "packages": "",
                "tourOption": {
                    "id": 0,
                    "name": "string",
                    "tickets": {
                        "id": "TG2",
                        "name": "Entrance + E-Guided Tour",
                        "qty": 2,
                        "deliveryOptions": "",
                        "visitors": {
                            "id": 1,
                            "name": "abc",
                            "title": "Mr",
                            "firstName": "Shahrukh",
                            "lastName": "Shaikh",
                            "leadTraveller": true,
                            "dob": "1996-01-09",
                            "gender": "Male"
                        }
                    }
                }
            }
        }
        const val = persnalDetailsValidation();
        if (val) {
            setPersnalDetailsFlag(true);
        }
    };

    const payNow = () => {

        const val = persnalDetailsValidation();
        if (val) {
            setPersnalDetailsFlag(false);
            setPayNowFlag(true);
            dispatch(setContactDetailsRedux(persnalDetails));
        }

    };


    return (

        <>
            <div className={classess.cardDatail}
                style={{ width: '-webkit-fill-available' }}>
                {loader ?
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        marginTop={'10%'}
                    >
                        <CircularProgress color="secondary" />
                    </Stack> :
                    <Container maxWidth="lg">
                        <>
                            <Stack direction="row" sx={{ width: '100%', flexDirection: { xs: 'column', sm: 'row' } }} >
                                <Box flex={10} className="mt-5" >
                                    <Box flex={12} className="">
                                        <div>
                                            <img src={card ? card : bgImgArray[0]}
                                                alt="logo"
                                                className={classess.attractionlogo}
                                            />
                                        </div>
                                    </Box>
                                </Box>
                                <Box flex={2}>
                                    <Box className='hiddenDiv mt-5' sx={{ display: { xs: 'flex', sm: 'block' }, width: { xs: '100%', sm: '200px' } }}>
                                        {
                                            [1, 2, 3, 4, 5, 6].map((curr, index) => {
                                                return (
                                                    <Box flex={12} className='subBanner' style={{ marginTop: `${curr === 1} && '0px'` }} onClick={() => setCard(bgImgArray[index])}>
                                                        <div>
                                                            <img src={bgImgArray[index]}
                                                                alt="logo"
                                                                className='sublogo' />
                                                        </div>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                </Box>
                            </Stack>
                            <div className='hotel-details-line my-4'></div>
                            <div className='d-flex align-items-start justify-content-between mobileHeader'>
                                <div>
                                    <span className={classess.voucherText}>{productDetails?.name}</span>
                                    <div className='d-flex align-items-center'>
                                        <Rating name="read-only" value={productDetails?.overallRating} readOnly />
                                    </div>
                                    {/* <div className='hotel-details-text'>
                                        <span className='me-1'>14 guests</span> | <span className='mx-1'>7 bedrooms</span> | <span className='mx-1'>7 beds</span> | <span className='ms-1'>5.5 bathrooms</span>
                                    </div> */}
                                </div>
                                {/* <div className='d-flex flex-column align-itemsEnd marginTop'>
                                    <span className='mb-2 hotel-details-price'>{productDetails?.price}</span>
                                </div> */}
                            </div>

                            <div className='hotel-details-line my-4'></div>

                            <Stack spacing={0} style={{ width: "40%" }}>

                                <Box style={{ marginRight: '15px' }} className={classess.voucherQtyText}>
                                    <Typography variant="p"
                                        className={classess.voucherQtyText}>
                                        Date
                                    </Typography>
                                    <div className=' bg-light'>
                                        <DatePicker
                                            onChange={dateHandler}
                                            selected={date}
                                            minDate={new Date()}
                                            // maxDate={nextYearRange()}
                                            highlightDates={productDetails?.availableOnlyOnDates}
                                            includeDates={productDetails?.availableOnlyOnDates}
                                            name={"departure"}
                                            id="dateDeparture"
                                            className="datepicker-input-style-without-border"
                                        />
                                    </div>
                                    {!date && error.date ? (
                                        <label className="text-error t-error w-100">{error.date}</label>
                                    ) : null}
                                </Box>
                            </Stack>
                            {tickets &&
                                <Grid item xs={6} className={classess.voucherQtyMain}>
                                    {tickets && tickets.map((item, index) => (
                                        <Box style={{ marginRight: '15px' }}>
                                            <Typography variant="p" className={classess.voucherQtyText}>
                                                {item?.name + " (" + item?.minAge + "-" + item?.maxAge + ")"}
                                            </Typography>
                                            <ButtonGroup variant="outlined" aria-label="outlined button group">
                                                <Button
                                                    className={classess.voucherQtyValue}
                                                    style={{ color: '#7A7A7A' }}
                                                    onClick={() => addHandler(item?.qty + 1, item?.id)}
                                                    disabled={item?.qty <= -1 ? true : false}
                                                >
                                                    +
                                                </Button>
                                                <Button
                                                    className={classess.voucherQtyValue}
                                                    style={{ color: '#7A7A7A' }} disabled>
                                                    {item?.qty}
                                                </Button>
                                                <Button
                                                    className={classess.voucherQtyValue}
                                                    style={{ color: '#7A7A7A' }}
                                                    onClick={() => minusHandler(item?.qty - 1, item?.id)}
                                                    disabled={item?.qty > 0 ? false : true}
                                                >
                                                    -
                                                </Button>
                                            </ButtonGroup>
                                        </Box>
                                    ))}

                                </Grid>}
                            <label className="text-error t-error w-100">{error.visitor ? error.visitor : ""}</label>

                            {availabilityDetails && availabilityDetails?.tourOptions && availabilityDetails?.tourOptions[0]?.price &&
                                <Box className='pt-1'>
                                    <Typography variant="p" className={classess.voucherQtyText}
                                    >
                                        Total AED
                                    </Typography>

                                    <Button className={classess.voucherQtyValue} disabled
                                        style={{
                                            color: 'var(--primayColor)',
                                            width: '120px',
                                            border: "1px solid #C2C2C2",
                                            backgroundColor: '#ffffff',
                                            borderRadius: '6px',
                                            fontFamily: 'helveticaneuebold_',
                                            justifyContent: 'start',
                                        }}
                                    >
                                        {availabilityDetails?.tourOptions && availabilityDetails?.tourOptions[0]?.price}
                                    </Button>
                                </Box>}

                            <div className='d-flex gap-3 my-4'>
                                {availabilityDetails ?
                                    <Button
                                        id="primaryBorderColor"
                                        variant="outlined"
                                        className={`borderButton`}
                                        onClick={() => { availabilityDetails?.flag?.visitors ? visitorHandler() : bookNow() }}
                                    >
                                        Book Now
                                    </Button> :
                                    <Button
                                        id="primaryBorderColor"
                                        variant="contained"
                                        className={`fillButton`}
                                        onClick={() => checkAvailabilityHandler()}
                                    >
                                        Check Availability
                                    </Button>}

                            </div>

                            <div className='hotel-details-line my-4'></div>
                            {/* <TemporaryDrawer buttonHandler={buttonHandler} /> */}

                            {productDetails?.highlights &&
                                <>

                                    <div className='d-flex flex-column'>
                                        <span className='hotel-details-text3'>Highlights</span>
                                        <span
                                            className={classess.conditionList}
                                            dangerouslySetInnerHTML={{ __html: productDetails?.highlights }}
                                        />
                                    </div>
                                    <div className='hotel-details-line my-4'></div>
                                </>
                            }

                            {productDetails?.shortDescription &&
                                <>
                                    <div className='d-flex flex-column'>
                                        <span className='hotel-details-text3'>Description</span>
                                        <span
                                            className={classess.conditionList}
                                            dangerouslySetInnerHTML={{ __html: productDetails?.shortDescription }}
                                        />
                                    </div>
                                    <div className='hotel-details-line my-4'></div>
                                </>
                            }

                            {productDetails?.inclusions &&
                                <>
                                    <div className='d-flex flex-column'>
                                        <span className='hotel-details-text3'>Inclusions</span>
                                        <span
                                            className={classess.conditionList}
                                            dangerouslySetInnerHTML={{ __html: productDetails?.inclusions }}
                                        />
                                    </div>
                                    <div className='hotel-details-line my-4'></div>
                                </>
                            }

                            {productDetails?.exclusions &&
                                <>
                                    <div className='d-flex flex-column'>
                                        <span className='hotel-details-text3'>Exclusions</span>
                                        <span
                                            className={classess.conditionList}
                                            dangerouslySetInnerHTML={{ __html: productDetails?.exclusions }}
                                        />
                                    </div>
                                    <div className='hotel-details-line my-4'></div>
                                </>
                            }

                            {productDetails?.additionalInformation &&
                                <>
                                    <div className='d-flex flex-column'>
                                        <span className='hotel-details-text3'>Additional Information</span>
                                        <span
                                            className={classess.conditionList}
                                            dangerouslySetInnerHTML={{ __html: productDetails?.additionalInformation }}
                                        />
                                    </div>
                                    <div className='hotel-details-line my-4'></div>
                                </>
                            }


                            <SnackbarAlert
                                open={snackbar}
                                vertical={"bottom"}
                                horizontal={"center"}
                                handleClose={handleClose}
                                severity="error"
                                message={"Not available this date"}
                            />

                        </>

                    </Container>}

                <CommonDrawer onClose={setVisitorsFlag} open={visitorsFlag}>
                    <div className="bookFlight d-flex flex-column">
                        <h4>Visitors Details</h4>

                        <div className="d-flex w-100 columnReverse">

                            <div className=" w-100 mobileBox">
                                {visitorsDetails && visitorsDetails.map((item, index) => (
                                    <div key={index} className="mt-4">
                                        <span className="fw-bold">Visitor ({item?.type?.name + ") " + (index + 1)} :</span>
                                        <div className="mobileBlock mt-2">
                                            <FormControl className="me-3" sx={{ minWidth: 120 }} size="small">
                                                <label className="mb-2">Title</label>
                                                <Select
                                                    labelId="demo-select-small"
                                                    style={{ padding: 2 }}
                                                    name={item?.visitorsId}
                                                    id={item?.visitorsId}
                                                    value={item?.title}
                                                    onChange={titleSelectHandler}
                                                >
                                                    {NAME_TITTLE && NAME_TITTLE.map((title, i) => (
                                                        <MenuItem key={i} value={title}>{title?.name}</MenuItem>
                                                    ))}
                                                </Select>
                                                {!item?.title && error.title ? (
                                                    <label className="text-error t-error w-100">{error.title}</label>
                                                ) : null}
                                            </FormControl>
                                            <FormControl fullWidth className="me-3">
                                                <label className="mb-2">First Name</label>
                                                <TextField
                                                    onChange={firstNameHandler}
                                                    value={item?.firstName}
                                                    name={"firstName"}
                                                    id={item?.visitorsId}
                                                    inputProps={{ inputMode: "text", style: { padding: 10 } }}
                                                />
                                                {!item?.firstName && error.firstName ? (
                                                    <label className="text-error t-error w-100">{error.firstName}</label>
                                                ) : null}
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <label className="mb-2">Last Name</label>
                                                <TextField
                                                    onChange={lastNameHandler}
                                                    value={item?.lastName}
                                                    name={"lastName"}
                                                    id={item?.visitorsId}
                                                    inputProps={{ inputMode: "text", style: { padding: 10 } }}
                                                />
                                                {!item?.lastName && error.lastName ? (
                                                    <label className="text-error t-error w-100">{error.lastName}</label>
                                                ) : null}
                                            </FormControl>
                                        </div>
                                        <div className="mt-2 mobileBlock">
                                            <FormControl fullWidth className="me-3">
                                                <label className="mb-2">Date of Birth
                                                    {/* ({item?.type?.minAge}-{item?.type?.maxAge})  */}
                                                </label>
                                                <TextField
                                                    onChange={dobDateHandler}
                                                    value={item?.dob}
                                                    name={item?.type?.name}
                                                    id={item?.visitorsId}
                                                    type="date"
                                                    inputProps={{
                                                        // min: maxYearCalculate(item?.type?.minAge),
                                                        // max: maxYearCalculate(item?.type?.maxAge),
                                                        style: { padding: 10 }
                                                    }}
                                                    // defaultValue={todaysDate}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                {!item?.dob && error.dob ? (
                                                    <label className="text-error t-error w-100">{error.dob}</label>
                                                ) : null}
                                            </FormControl>

                                            <FormControl fullWidth className="me-3" sx={{ minWidth: 120 }} size="small">
                                                <label className="mb-2">Gender</label>
                                                <Select
                                                    labelId="demo-select-small"
                                                    style={{ padding: 2 }}
                                                    name={item?.visitorsId}
                                                    id={item?.visitorsId}
                                                    value={item.gender}
                                                    onChange={genderSelectHandler}
                                                >
                                                    {GENDER && GENDER.map((gender, i) => (
                                                        <MenuItem key={i} value={gender}>{gender?.name}</MenuItem>
                                                    ))}
                                                </Select>
                                                {!item?.gender && error.gender ? (
                                                    <label className="text-error t-error w-100">{error.gender}</label>
                                                ) : null}
                                            </FormControl>
                                            {item?.type?.name === "Adult" && index === 0 ?
                                                <div className='d-flex flex-column w-100 align-self-end'>
                                                    <FormControlLabel fullWidth
                                                        control={<Checkbox
                                                            style={{ color: "var(--primayColor)" }}
                                                            name={item?.visitorsId}
                                                            id={item?.visitorsId}
                                                            checked={item?.leadTraveller}
                                                            onChange={leadTravellerHandleChange}
                                                        />}
                                                        label="Lead Traveller"
                                                    />
                                                    <label className="text-error t-error w-100 my-1 ">{!item?.leadTraveller && error.leadTraveller ? error.leadTraveller : ""}</label>

                                                </div> : <div className='w-100 align-self-end'></div>}
                                        </div>
                                        <div className='hotel-details-line my-4'></div>
                                    </div>

                                ))}
                                <Button
                                    onClick={() => Next()}
                                    variant="contained"
                                    className="backcolorPrimary my-4"
                                    style={{ textTransform: "initial" }}
                                >
                                    Next
                                </Button>
                            </div>

                        </div>
                    </div>
                </CommonDrawer>
                <CommonDrawer onClose={setPersnalDetailsFlag} open={persnalDetailsFlag}>
                    <div className="bookFlight d-flex flex-column">
                        <h4>Contact Details</h4>

                        <div className="d-flex w-100 columnReverse">

                            <div className=" w-100 mobileBox">
                                <div className="mt-4">
                                    <div className="mobileBlock mt-2">
                                        <FormControl fullWidth className="me-3">
                                            <label className="mb-2">First Name</label>
                                            <TextField
                                                onChange={(e) => setPersnalDetails({ ...persnalDetails, firstName: e.target.value })}
                                                value={persnalDetails?.firstName}
                                                // name={"firstName"}
                                                // id={item?.visitorsId}
                                                inputProps={{ inputMode: "text", style: { padding: 10 } }}
                                            />
                                            <label className="text-error t-error w-100 my-1">{!persnalDetails?.firstName && error.firstName ? error.firstName : ""}</label>

                                        </FormControl>
                                        <FormControl fullWidth>
                                            <label className="mb-2">Last Name</label>
                                            <TextField
                                                onChange={(e) => setPersnalDetails({ ...persnalDetails, lastName: e.target.value })}
                                                value={persnalDetails?.lastName}
                                                // name={"lastName"}
                                                // id={item?.visitorsId}
                                                inputProps={{ inputMode: "text", style: { padding: 10 } }}
                                            />
                                            <label className="text-error t-error w-100 my-1">{!persnalDetails?.lastName && error.lastName ? error.lastName : ""}</label>

                                        </FormControl>
                                    </div>
                                    <div className="mobileBlock mt-2">
                                        <FormControl fullWidth className="">
                                            <label className="mb-2">Email</label>
                                            <TextField
                                                onChange={(e) => {
                                                    setPersnalDetails({ ...persnalDetails, email: e.target.value.toLowerCase() });
                                                    setError({ ...error, email: "" });
                                                }}
                                                value={persnalDetails?.email}
                                                // name={"firstName"}
                                                // id={item?.visitorsId}
                                                inputProps={{ inputMode: "text", style: { padding: 10 } }}
                                            />
                                            <label className="text-error t-error w-100 my-1">{error.email ? error.email : ""}</label>

                                        </FormControl>
                                        {/* <FormControl fullWidth>
                                            <label className="mb-2">Last Name</label>
                                            <TextField
                                                // onChange={lastNameHandler}
                                                // selected={item?.lastName}
                                                // name={"lastName"}
                                                // id={item?.visitorsId}
                                                inputProps={{ inputMode: "text", style: { padding: 10 } }}
                                            />
                                        </FormControl> */}
                                    </div>
                                    <div className="mt-2 mobileBlock">
                                        <FormControl fullWidth className="me-3 w-50" sx={{ minWidth: 120 }} size="small">
                                            <label className="mb-2">Country Code</label>
                                            <Select
                                                labelId="demo-select-small"
                                                style={{ padding: 2 }}
                                                // name={item?.visitorsId}
                                                // id={item?.visitorsId}
                                                onChange={(e) => setPersnalDetails({ ...persnalDetails, countryCode: e.target.value })}
                                                value={persnalDetails?.countryCode}
                                            >
                                                {COUNRY_CODES && COUNRY_CODES.map((c, i) => (
                                                    <MenuItem key={i} value={c}>{c?.code + "   " + c?.dial_code}</MenuItem>
                                                ))}
                                            </Select>
                                            <label className="text-error t-error w-100 my-1">{!persnalDetails?.countryCode && error.countryCode ? error.countryCode : ""}</label>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <label className="mb-2">Mobile Number</label>
                                            <TextField
                                                onChange={(e) => {
                                                    let regex = /^\d{0,15}?$/;
                                                    if (regex.test(e.target.value)) {
                                                        setPersnalDetails({ ...persnalDetails, mobileNo: e.target.value })
                                                    }
                                                }}
                                                value={persnalDetails?.mobileNo}
                                                // name={"lastName"}
                                                // id={item?.visitorsId}
                                                inputProps={{ inputMode: "text", style: { padding: 10 } }}
                                            />

                                            <label className="text-error t-error w-100 my-1">{!persnalDetails?.mobileNo && error.mobileNo ? error.mobileNo : ""}</label>
                                        </FormControl>

                                    </div>
                                </div>

                                <div className='my-3'>
                                    <Button
                                        onClick={() => payNow()}
                                        variant="contained"
                                        className="backcolorPrimary my-4"
                                        style={{ textTransform: "initial" }}
                                    >
                                        Pay Now
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </CommonDrawer>
                <CommonDrawer onClose={setPayNowFlag} open={payNowFlag}>

                    <Box
                        style={{ margin: '20px 0px 20px 0px', width: "100%" }}
                        sx={{ width: "right" === "top" || "right" === "bottom" ? "auto" : 250 }}
                        role="presentation">
                        <Container maxWidth="sm">
                            <Stack direction="row" spacing={2} style={{ paddingLeft: '0px' }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                <Typography
                                    variant="h6"
                                    component="h6"
                                //className={classes.username}
                                >
                                    {accountDetailsState?.firstname + " " + accountDetailsState?.lastname}
                                </Typography>
                            </Stack>
                        </Container>
                    </Box>
                    <Box sx={{ bgcolor: "#ffffff", width: "100%" }}>
                        <PaymentTab
                            amount={1000}
                        // amount={availabilityDetails?.tourOptions && availabilityDetails?.tourOptions[0]?.price}
                        />
                    </Box>

                </CommonDrawer>
                <CommonDrawer onClose={setPaymentSucessFlag} open={paymentSucessFlag}>
                    <div className="passengerDetailDrwaer m-5 d-flex flex-column">
                        <div className="ms-4">
                            <div className="d-flex flex-column align-items-center">
                                <CheckCircleIcon style={{ color: 'var(--primayColor)', fontSize: '80px' }} />
                                {/* <img width={70} src={tick} alt="" /> */}
                                <h2 className="mt-3">Thank you!</h2>
                                <span className="fw-bold-c mt-3">
                                    Your payment was successful
                                </span>
                                <br />
                                <span className="text-center">
                                    This is your E-tickets. Do present it with a valid photo
                                    identification at the airport check-in counter. For
                                    international travel: The check-in counters are open 4 hours
                                    prior to departure and close strictly 2 hours prior to departure
                                </span>
                            </div>
                            <div className="mt-2">
                                <span className="fw-bold">
                                    Booking reference no: <strong>#12345</strong>
                                </span>
                            </div>
                            <span className="fw-bold">Passenger Details</span>
                            <div className="table-responsive">
                                <table className="table mt-3 w-100 passengertable">
                                    <tr>
                                        <td className="bg-dark-grey py-2 px-3 fsc-5"> Details</td>
                                        <td className="bg-dark-grey py-2 px-3 fsc-5">
                                            {" "}
                                            Passenger Type
                                        </td>
                                        <td className="bg-dark-grey py-2 px-3 fsc-5">
                                            {" "}
                                            Passenger Name
                                        </td>
                                        <td className="bg-dark-grey py-2 px-3 fsc-5"> Gender</td>
                                        <td className="bg-dark-grey py-2 px-3 fsc-5"> Age</td>
                                        <td className="bg-dark-grey py-2 px-3 fsc-5">
                                            {" "}
                                            Passport Number
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-grey py-2 px-3 fsc-5">Mr</td>
                                        <td className="bg-grey py-2 px-3 fsc-5">Adult</td>
                                        <td className="bg-grey py-2 px-3 fsc-5">Sahil Kumar</td>
                                        <td className="bg-grey py-2 px-3 fsc-5">Male</td>
                                        <td className="bg-grey py-2 px-3 fsc-5">21</td>
                                        <td className="bg-grey py-2 px-3 fsc-5">XR151214511</td>
                                    </tr>
                                </table>
                            </div>
                        </div>


                        <div className="d-flex justify-content-end mt-3">
                            <Button
                                onClick={() => {
                                    setPaymentSucessFlag(false);
                                }}
                                variant="contained"
                                className="backcolorPrimary my-5 ms-4"
                                style={{ textTransform: "initial" }}
                            >
                                Print
                            </Button>
                        </div>
                    </div>
                </CommonDrawer>
                <CommonDrawer onClose={setLoginFlag} open={loginFlag}>
                    <Box sx={{ bgcolor: "#ffffff", width: "100%" }}>
                        <LoginScreen open={loginFlag} onClose={setLoginFlag} />
                    </Box>
                </CommonDrawer>

            </div>
            <SnackbarAlert
                open={availabilityLoader}
                vertical={"bottom"}
                horizontal={"center"}
                handleClose={handleClose}
                severity="info"
                message={"Please wait"}
            />
        </>
    )
}

export default AttractionDetail
