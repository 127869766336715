import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

const handleLogout = () => {
  localStorage.clear();
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      handleLogout();
      window.location.reload(false);
    }
    return error;
  }
);

ReactDOM.render(<App />, document.querySelector("#root"));
