import {
  SET_ACCOUNT_DETAILS,
  SET_SETTINGS_DATA,
} from "./settingsType";

const initialState = {
  settingResponse: [],
  accountDetails: null,
  loading: false,
  error: "",
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_SETTINGS_DATA:
      return {
        ...state,
        settingResponse: action.payload,
      };

    case SET_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: action.payload,
      };



    default:
      return state;
  }
};

export default settingsReducer;
