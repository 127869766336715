import React from 'react'
import ShopContainer from './container/ShopContainer'

const Shop = () => {
    return (
        <>
            <ShopContainer />
        </>
    )
}

export default Shop