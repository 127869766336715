import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { Button, CircularProgress } from "@mui/material";
import useStyle from './PaymentDrawerStyle';

export default function CheckoutForm() {
    const classess = useStyle()
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            setMessage("")
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/success`,
            },
        });
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>

            {/* Show any error or success messages */}
            {message === null ?
                <div className="loader">
                    <CircularProgress color="secondary" />
                </div>
                : message ? <div id="payment-message" className="d-flex w-100 justify-content-center h-c-00">
                    <h2>{message}</h2>
                </div>
                    :
                    <>  <PaymentElement id="payment-element" options={paymentElementOptions} />
                        <div className="my-3">
                            <button
                                disabled={isLoading || !stripe || !elements}
                                id="submit"
                                className="search-travel-btn"
                            >
                                <span id="button-text">
                                    {isLoading ? <div >
                                        <CircularProgress color="secondary" style={{ width: "20px", height: "20px" }} />
                                    </div> : "Pay now"}
                                </span>
                            </button>
                        </div></>
            }
        </form>
    );
}