import React from 'react'
import { Link } from 'react-router-dom'
import vouchersidebanner from '../../assets/img/Travel/pngegg.png'
import iphone from '../../assets/img/Travel/webp/iPhone.webp'
import battery from '../../assets/img/Travel/webp/ProtableBattery.webp'
import mac from '../../assets/img/Travel/webp/MacPro.webp'
import WashingMachine from '../../assets/img/Travel/webp/WashingMachine.webp'
import Juicer from '../../assets/img/Travel/webp/JuicerMixer.webp'
import Tv from '../../assets/img/Travel/webp/LEDTV.webp'
import TravelCard from '../common/TravelCard'
import CommonPagination from '../commonComponents/CommonPagination'
const TrendingProducts = ({ paddingX }) => {

    const list = [
        {
            banner: iphone,
            title: "iPhone"
        },
        {
            banner: mac,
            title: "Mac Pro"
        },
        {
            banner: battery,
            title: "Portable Battery"
        },
        {
            banner: Tv,
            title: "LED TV"
        },
        {
            banner: Juicer,
            title: "Juicer Mixer"
        },
        {
            banner: WashingMachine,
            title: "Washing Machie"
        },
    ]
    return (
        <div className={paddingX === '0' ? 'w-100 px-0 mt-4' : 'w-100 px-3'}>
            <div className='d-flex w-100 justify-content-between'>
                <span>Trending Products</span>
                <Link to="/shop"> See All</Link>
            </div>
            <div className='d-flex justify-content-between w-100 mb-3 flex-wrap '>
                {
                    list.map((item, index) => <TravelCard color="grey" link='/shop' banner={item.banner} title={item?.title} key={index} />)
                }
            </div>

        </div>
    )
}

export default TrendingProducts