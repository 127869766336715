import { Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import planeLogo from "../../assets/img/Travel/webp/car1.webp"
import planeLogo1 from "../../assets/img/Travel/Car2.png"
import TuneIcon from '@mui/icons-material/Tune';
import CommonDrawer from '../common/CommonDrawer';
import { useState } from 'react';
import RangeSlider from '../common/RangeSlider';
import moment from 'moment'
import { Link } from "react-router-dom";
import { Container, Stack } from '@mui/system';
import PaymentTab from "../drawer/PaymentTab";
import useStyle from '../drawer/PaymentDrawerStyle';

const CarResult = () => {
    const List = [1, 2, 3, 4, 5, 6, 7, 8, 9, 7, 8]
    const classes = useStyle()
    const [filterOpen, setfilterOpen] = useState(false)
    const [bookNowOpen, setbookNowOpen] = useState(false)
    const [proceedPayOpen, setproceedPayOpen] = useState(false)
    const [PayNowOpen, setPayNowOpen] = useState(false)

    const todaysDate = moment(new Date()).format('YYYY-MM-DD');

    const list = (anchor) => (


        <>
            <Box
                style={{ margin: '20px 0px 20px 0px' }}
                sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
                role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
            >
                <Container maxWidth="sm">
                    <Stack direction="row" spacing={2} style={{ paddingLeft: '0px' }}>
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                        <Typography
                            variant="h6"
                            component="h6"
                            className={classes.username}
                        >
                            John
                        </Typography>
                    </Stack>
                </Container>
            </Box>
            <Box sx={{ bgcolor: "#ffffff", width: "100%" }}>
                <PaymentTab amount={1000}/>
            </Box>

        </>
    );


    return (
        <div className='mainFlightresult bg-grey mobileBox'>
            <div className='d-flex justify-content-center align-items-center mobilemt-5'>
                <div className="d-flex justify-content-between align-items-center w-90">
                    <h4 className="m-0 w-100">Total Car(s) found 80</h4>
                    <div className='d-flex justify-content-end w-90'>
                        <Button onClick={() => setfilterOpen(true)} className='my-1 bg-white' style={{ color: 'var(--primayColor)', borderColor: 'var(--primayColor)', fontWeight: 'bold' }} variant="outlined"> <TuneIcon style={{ marginRight: '10px', transform: 'rotate(-90deg)' }} /> Filter</Button>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column align-items-center'>
                {
                    List.map((item, index) => {
                        return <div className='d-flex bg-light-blue w-90 my-3 p-3 '>
                            <div className='w-100 d-flex justify-content-between mobileStack500'>
                                <div className='d-flex flex-column width-75 mobileBlock justify-content-center '>
                                    <div className='my-2 d-flex justify-content-between align-item-center h-100 mobileStack500'>
                                        <div className='py-3 bg-grey width-35 mobileBlock d-flex justify-content-center margin-right'>
                                            <img width={150} src={index % 2 === 0 ? planeLogo : planeLogo1} alt="" />
                                        </div>
                                        <div className='py-2 d-flex flex-column justify-content-center bg-grey width-75 mobileBlock px-3 '>
                                            <span className='fw-bold-c'>Hyundai Palisade (Manual)</span>
                                            <span>Class: Mini | AC | 7 Seater | Petrol | 0.0 km Away</span>
                                            <span> <Link>Terms & Conditions</Link> </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-grey width-22 mobileBlock my-2 d-flex flex-column justify-content-center align-items-center'>
                                    <span className='colorPrimary fw-bold my-1'>AED 1,520</span>
                                    <Button onClick={() => setbookNowOpen(true)} className='my-1 bg-white' style={{ color: 'var(--primayColor)', borderColor: 'var(--primayColor)', fontWeight: 'bold', textTransform: 'initial' }} variant="outlined">Book Now</Button>
                                    <Link className='text-black my-1'>Car Details</Link>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            <CommonDrawer onClose={setfilterOpen} open={filterOpen} >
                <div className="filterCar m-3 d-flex flex-column">

                    <div className='mx-5 mt-4 d-flex flex-wrap justify-content-between'>
                    <h4 className="m-0 mt-4 ">Refine your search</h4>

                        <div className='mb-5 mt-5'>
                            <span className='colorPrimary fw-bold'>Total AED</span>
                            <RangeSlider />
                        </div>
                    </div>

                    <div className=" mx-5 border-top p-3">
                        <span className='colorPrimary fw-bold'>Car class</span>
                        <div className="d-flex flex-wrap w-100">
                            <FormControlLabel className='w-100' control={<Checkbox style={{ color: 'var(--primayColor)', height: "35px" }} />} label="Select All" />
                            <FormControlLabel className='w-100' control={<Checkbox style={{ color: 'var(--primayColor)', height: "35px" }} />} label="Mini " />
                            <FormControlLabel className='w-100' control={<Checkbox style={{ color: 'var(--primayColor)', height: "35px" }} />} label="Special" />
                        </div>
                    </div>
                    <div className=" mx-5 border-top p-3">
                        <span className='colorPrimary fw-bold'>Car conditioning</span>
                        <div className="d-flex flex-wrap w-100">
                            <FormControlLabel className='w-100' control={<Checkbox style={{ color: 'var(--primayColor)', height: "35px" }} />} label="Select All" />
                            <FormControlLabel className='w-100' control={<Checkbox style={{ color: 'var(--primayColor)', height: "35px" }} />} label="Air condition " />
                            <FormControlLabel className='w-100' control={<Checkbox style={{ color: 'var(--primayColor)', height: "35px" }} />} label="Non Air condition" />
                        </div>
                    </div>
                    <div className=" mx-5 border-top p-3">
                        <span className='colorPrimary fw-bold'>Car Transmission</span>
                        <div className="d-flex flex-wrap w-100">
                            <FormControlLabel className='w-100' control={<Checkbox style={{ color: 'var(--primayColor)' }} />} label="Manual" />
                        </div>
                    </div>

                </div>
            </CommonDrawer >
            <CommonDrawer onClose={setbookNowOpen} open={bookNowOpen}>
                <div className="bookFlight d-flex flex-column">
                    <h4>Excess Protection</h4>

                    <div className="d-flex w-100 columnReverse">
                        <div className='mt-4 w-60 mobileBox'>
                            <div className='p-3 d-flex flex-column border'>
                                <span className='fw-bold-c pb-2'>Damage Excess Refund: AED 1,500</span>
                                <span className='pb-3'> <span className='fw-bold-c'>Excess Protection -</span> Excellent Value, Best Cover, Peace of mind</span>
                                <span>When you pick-up your car a deposit is usually held on your card for your excess which may be charged if the vehicle is damaged.</span>
                                <span className='mt-3'>Take our Damage Excess Refund and if you have any bumps or scrapes, we will refund any deductions from your excess.</span>
                                <span className='mt-3'> - You're protected: Damage Excess Refund protects you when things go wrong and you are required to pay out from your excess.</span>
                                <span> - Accidents covered: Your excess will be protected if your vehicle is damaged in an accident </span>
                                <span>- Damage cover: Damage to bodywork, vehicle parts and accessories incurred during most driving incidents is covered.</span>

                            </div>
                            <div className='mt-4'>
                                <Button onClick={() => {
                                    setproceedPayOpen(true)
                                    setbookNowOpen(false)
                                }} style={{ backgroundColor: 'var(--primayColor)', textTransform: 'initial' }} variant="contained" className='width114'>Yes I'll take it</Button>
                                <Button className='ms-3' style={{ color: 'var(--primayColor)', borderColor: 'var(--primayColor)', fontWeight: 'bold', textTransform: 'initial' }} variant="outlined">No Continue</Button>
                            </div>
                        </div>

                        <div className='w-40  d-flex justify-content-center mobileBox mobilemt-1'>
                            <div className='border w-90 p-3 mobileBox'>
                                <span className='fw-bold'>Itinerary</span>

                                <div className='mt-4 d-flex flex-column ps-3'>
                                    <img width={250} src={planeLogo} alt="" className='m-width-180' />

                                    <span className='mt-3'>Hyundai Palisade (Manual)</span>
                                    <span>Class: Mini | AC | 7 Seater | Petrol | 0.0 km Away</span>
                                </div>

                                <div className='mt-4 '>
                                    <span className='fw-bold-c'>Pick-up</span>
                                </div>
                                <div className='mt-2 bg-grey p-2'>
                                    <span>Dubai International Airport, Dubai <br /> Date: 29-09-2022 <br /> Time: 1:05am</span>
                                </div>
                                <div className='mt-4 '>
                                    <span className='fw-bold-c'>Drop-off</span>
                                </div>
                                <div className='mt-2 bg-grey p-2'>
                                    <span>Deluxe 5 Star Hotel <br />Date: 29-09-2022  <br /> Time: 2:15am </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </CommonDrawer>
            <CommonDrawer onClose={setproceedPayOpen} open={proceedPayOpen} >
                <div className="bookFlight d-flex flex-column">
                    <h4>Passenger/Driver Details</h4>

                    <div className="d-flex w-100 columnReverse">
                        <div className='mt-4 w-60 mobileBox'>
                            <span className='fw-bold'>Passenger 1:</span>
                            <div className='mt-4 d-flex mobileFlexDirection'>
                                <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>Title</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={10}
                                    >
                                        <MenuItem value={10}>Mr</MenuItem>
                                        <MenuItem value={20}>Mrs</MenuItem>
                                        <MenuItem value={30}>Ms</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>First Name</label>
                                    <TextField inputProps={{ inputMode: 'text' }} />

                                </FormControl>
                                <FormControl fullWidth>
                                    <label className='mb-2'>Last Name</label>
                                    <TextField inputProps={{ inputMode: 'text' }} />

                                </FormControl>
                            </div>
                            <div className='mt-4 d-flex mobileFlexDirection'>
                                {/* <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>Date of Birth</label>
                                    <TextField
                                        id="dateDeparture"
                                        type="date"
                                        defaultValue={todaysDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl> */}
                                {/* <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>Email Id</label>
                                    <TextField inputProps={{ inputMode: ' email' }} />

                                </FormControl> */}
                                {/* <FormControl fullWidth>
                                    <label className='mb-2'>Nationality</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={10}
                                    >
                                        <MenuItem value={10}>Indian</MenuItem>
                                        <MenuItem value={20}>Pakistan</MenuItem>
                                        <MenuItem value={30}>Dubai</MenuItem>
                                    </Select>
                                </FormControl> */}
                            </div>
                            {/* <div className='mt-4 mb-5 d-flex mobileFlexDirection'>
                                <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>Passport Number</label>
                                    <TextField inputProps={{ inputMode: 'number' }} />

                                </FormControl>
                                <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>Passport Issue Date</label>
                                    <TextField
                                        id="dateDeparture"
                                        type="date"
                                        defaultValue={todaysDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <label className='mb-2'>Passport Exipry Date</label>
                                    <TextField
                                        id="dateDeparture"
                                        type="date"
                                        defaultValue={todaysDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                            </div> */}
                            <span className='fw-bold'>Passenger 2:</span>
                            <div className='mt-4 d-flex mobileFlexDirection'>
                                <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>Title</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={10}
                                    >
                                        <MenuItem value={10}>Mr</MenuItem>
                                        <MenuItem value={20}>Mrs</MenuItem>
                                        <MenuItem value={30}>Ms</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>First Name</label>
                                    <TextField inputProps={{ inputMode: 'text' }} />

                                </FormControl>
                                <FormControl fullWidth>
                                    <label className='mb-2'>Last Name</label>
                                    <TextField inputProps={{ inputMode: 'text' }} />

                                </FormControl>
                            </div>
                            <div className='mt-4 d-flex mobileFlexDirection'>
                                {/* <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>Date of Birth</label>
                                    <TextField
                                        id="dateDeparture"
                                        type="date"
                                        defaultValue={todaysDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl> */}
                                {/* <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>Email Id</label>
                                    <TextField inputProps={{ inputMode: ' email' }} />

                                </FormControl> */}
                                {/* <FormControl fullWidth>
                                    <label className='mb-2'>Nationality</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={10}
                                    >
                                        <MenuItem value={10}>Indian</MenuItem>
                                        <MenuItem value={20}>Pakistan</MenuItem>
                                        <MenuItem value={30}>Dubai</MenuItem>
                                    </Select>
                                </FormControl> */}
                            </div>
                            {/* <div className='mt-4 d-flex mobileFlexDirection'>
                                <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>Passport Number</label>
                                    <TextField inputProps={{ inputMode: 'number' }} />

                                </FormControl>
                                <FormControl fullWidth className='me-3'>
                                    <label className='mb-2'>Passport Issue Date</label>
                                    <TextField
                                        id="dateDeparture"
                                        type="date"
                                        defaultValue={todaysDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth >
                                    <label className='mb-2'>Passport Exipry Date</label>
                                    <TextField
                                        id="dateDeparture"
                                        type="date"
                                        defaultValue={todaysDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                            </div> */}
                            <Button onClick={() => {
                                setPayNowOpen(true)
                                setproceedPayOpen(false)
                            }} className='my-4 bg-white' style={{ color: 'var(--primayColor)', borderColor: 'var(--primayColor)', fontWeight: 'bold', textTransform: 'initial' }} variant="outlined" >Proceed to Pay</Button>


                        </div>

                        <div className='w-40  d-flex justify-content-center mobileBox'>
                            <div className='border w-90 p-3 mobileBox'>
                                <span className='fw-bold'>Itinerary</span>

                                <div className='mt-4 d-flex flex-column ps-3'>
                                    <img width={250} src={planeLogo} className='m-width-180' alt="" />

                                    <span className='mt-3'>Hyundai Palisade (Manual)</span>
                                    <span>Class: Mini | AC | 7 Seater | Petrol | 0.0 km Away</span>
                                </div>

                                <div className='mt-4 '>
                                    <span className='fw-bold-c'>Pick up</span>
                                </div>
                                <div className='mt-2 bg-grey p-2'>
                                    <span>Dubai International Airport, Dubai <br /> Date: 29-09-2022 <br /> Time: 1:05am</span>
                                </div>
                                <div className='mt-4 '>
                                    <span className='fw-bold-c'>Drop-off</span>
                                </div>
                                <div className='mt-2 bg-grey p-2'>
                                    <span>Deluxe 5 Star Hotel <br />Date: 29-09-2022  <br /> Time: 2:15am </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </CommonDrawer >

            <CommonDrawer onClose={setPayNowOpen} open={PayNowOpen}>
                {list("right")}
            </CommonDrawer>

        </div>
    )
}




export default CarResult