import ApiToken from "./apiToken";
import { CLIENT_ID } from "../utils/constantData";

export const getVoucherList = async (limit, skip) => {
  const response = await ApiToken.get(
    `v1/giftcards/client/${CLIENT_ID}/mappedProducts?is_detailed=true&limit=${limit}&skip=${skip}`
  );
  return response;
};

export const getVoucherTotal = async () => {
  const response = await ApiToken.get(
    `v1/giftcards/client/${CLIENT_ID}/mappedProducts?is_detailed=true`
  );
  return response;
};

export const newGetVoucherTotal = async (
  limit,
  skip,
  country,
  category,
  brand
) => {
  const response =
    await ApiToken.get(`/v1/giftcards/client/${CLIENT_ID}/mappedProducts?skip=${skip}&limit=${limit}${
      category?.id ? "&category_id=" + category?.id : ""
    }
    ${brand ? "&brand_name=" + brand : ""}${
      country?.id ? "&country_id=" + country?.id : ""
    }`);
  return response;
};
