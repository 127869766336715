import { makeStyles } from '@mui/styles';

const useStyle = makeStyles({

    cardDatail: {
        background: '#FFF 0% 0% no-repeat padding-box',
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        color: "#fff",
        minHeight: '100vh',
        overflowY: 'scroll',
        "&::before": {
            content: `''`,
            position: 'absolute',
            left: '-60px',
            top: '0px',
            width: '0',
            height: '0',
            border: '60px solid transparent',
            borderTopColor: '#f2f5f8'
        },
        borderRadius: '0px 0px 0px 40px',
        '@media (max-width: 900px)': {
            height: '100%',
            marginTop: '0px',
            position: 'relative',
        },
        '&::-webkit-scrollbar': {
            display: 'none',
        }


    },
    hero: {
        height: "220px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        justifyContent: "start",
        alignItems: "end",
        color: "#fff",
        fontSize: "4rem",
        borderRadius: '6px',
        marginTop: '60px',
        padding: '14px 15px',
        '@media (max-width: 1024px)': {
            marginTop: '146px !important'
        },
        '@media (max-width: 600px)': {
            marginTop: '120px !important'
        },

    },
    voucherValue: {
        border: "0px solid #f2f5f8 !important",
        color: '#282828 !important',
        backgroundColor: '#f2f5f8 !important',
        borderRadius: '6px !important',
        fontFamily: 'helvetica_neue65_medium !important',
        fontSize: '14px !important',
        marginRight: '20px !important',
        marginBottom: '10px !important',
        '&:hover': {
            border: '0px solid var(--primayColor) !important',
            backgroundColor: 'var(--primayColor) !important',
            opacity: [
                0.9, 0.8, 0.7
            ],
            color: "#ffffff !important"
        }
    },
    voucherSelectedValue: {
        borderRadius: '6px !important',
        fontFamily: 'helvetica_neue65_medium !important',
        fontSize: '14px !important',
        marginRight: '20px !important',
        marginBottom: '10px !important',

        border: '0px solid var(--primayColor) !important',
        backgroundColor: 'var(--primayColor) !important',
        opacity: [
            0.9, 0.8, 0.7
        ],
        color: "#ffffff !important"

    },
    voucherQtyMain: {
        display: 'flex',
        margin: '10px 0px !important'

    },
    voucherQtyText: {
        margin: '5px 0px !important',
        color: '#282828',
        display: 'block',
        fontFamily: 'helvetica_neue45_light',
    },
    voucherQtyValue: {
        border: "1px solid #C2C2C2 !important",
        backgroundColor: '#ffffff !important',
        borderRadius: '6px',
        fontFamily: 'helveticaneuebold_ !important'
    },
    voucherButton: {
        backgroundColor: 'rgb(119, 18, 106)',
        textTransform: "capitalize",
        marginTop: '40px',
        fontFamily: 'helvetica_neue65_medium',
        marginBottom: '20px',
        '&:hover': {
            border: '1px solid rgb(119, 18, 106)',
            backgroundColor: '#ffffff',
            boxShadow: 'none',
            opacity: [
                0.9, 0.8, 0.7
            ],

        }
    },
    voucherText: {
        color: 'var(--primayColor)',
        margin: '10px 0px !important',
        fontFamily: 'helvetica_neue45_light !important',
        fontSize: '30px !important',
    },
    voucherValueText: {
        margin: '10px 0px',
        color: '#282828 !important',
        fontSize: '16px',
        fontFamily: 'helvetica_neue45_light'
    },
    voucherValueDiv: {
        display: 'flex',
        margin: '10px 0px !important'
    },
    termsAndCondition: {
        backgroundColor: '#ffffff',
        padding: '10px',
        border: "1px solid #C2C2C2",
        borderRadius: '6px',
    },
    condition: {
        color: '#696969',
        marginLeft: '-10px',
        fontSize: '14px',
        fontFamily: 'helvetica_neue45_light'
    },
    conditionList: {
        padding: '5px 0px'
    },
    logo: {
        maxWidth: '100%'
    },
    cardConcave: {
        position: 'absolute',
        width: '45px',
        height: '116px',
        borderRadius: '70%',
        background: '#ffffff',
        zIndex: 1,
        transform: 'rotate(-41deg)',
        top: '-34px',
        left: '-64px'
    },
    termsheading: {
        margin: '10px 0px',
        color: '#282828',
        fontFamily: 'helvetica_neue65_medium',

    },



})

export default useStyle
