export const GET_FLIGHT_LIST_REQUEST = "GET_FLIGHT_LIST_REQUEST"
export const GET_FLIGHT_LIST_SUCCESS = "GET_FLIGHT_LIST_SUCCESS"
export const GET_FLIGHT_LIST_FAILURE = "GET_FLIGHT_LIST_FAILURE"


export const SET_FLIGHT_SCREEN_STEP = "SET_FLIGHT_SCREEN_STEP"

export const SET_TRAVEL_MODULE_STEP = "SET_TRAVEL_MODULE_STEP"

export const SET_FLIGHT_DETAILS = "SET_FLIGHT_DETAILS"

export const SET_SELECTED_FLIGHT_DETAILS = "SET_SELECTED_FLIGHT_DETAILS"

export const SET_PASSENGER_DETAILS = "SET_PASSENGER_DETAILS"