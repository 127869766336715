/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box } from '@mui/system';
import AttractionDetail from '../AttractionDetails/AttractionDetail';
import AttractionFilter from '../filter/AttractionFilter';
import AttractionCard from './AttractionCard';
import BannerComponent from '../../travel/BannerComponent';
import banner from '../../../assets/img/Attraction/banner.png';
import { useState } from 'react';
import { useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Stack } from '@mui/material';
import ButtonAppBar from '../../common/Appbar';
import { useDispatch, useSelector } from 'react-redux';
import { setFlightDetails, setFlightScreenStep } from '../../../redux/Flight/flightAction';
import { setAvailabilityRedux, setFieldsRedux, setProductDetailsRedux, setTicketsRedux, setVisitorsDetailsRedux } from '../../../redux/Attraction/AttractionAction';
import { getAttractionsProducts, getAttractionsProductsDetails, getAttractionsProductsCategories, getAttractionsCountry, getAttractionsCity, getAttractionsCurrency } from '../../../Api/AttractionsApi';
import SnackbarAlert from '../../common/SnackbarAlert';
import { useQuery } from 'react-query';
import PointsComponent from '../../commonComponents/PointsComponent';


const AttractionContainer = () => {
    const dispatch = useDispatch();

    const settingState = useSelector((state) => state?.settingState?.settingResponse);
    const productDetailsState = useSelector((state) => state?.attractionState?.productDetails);

    const countryList = useQuery('country_list', getAttractionsCountry);
    const currencyList = useQuery('currency_list', getAttractionsCurrency);
    const cityList = useQuery('city_list', getAttractionsCity);
    const categoriesList = useQuery('categories_list', getAttractionsProductsCategories);



    const [loaderProdDetails, setLoaderProdDetails] = useState(false);

    const [search, setSearch] = useState(null);
    const [fields, setFields] = useState({
        selectedCountry: null,
        selectedCategory: null,
        selectedCity: null,
        selectedCurrency: null,
    });
    const [productDetails, setProductDetails] = useState(productDetailsState ? productDetailsState : null);

    const { isLoading, error, data } = useQuery(['products_list', fields, search], getAttractionsProducts)


    const [bannerData, setBannerData] = useState(true);
    const [initialPage, setInitialPage] = useState("data");

    const [snackbar, setSnackbar] = useState(false);

    const [errors, setError] = useState({
        currency: "",

    });

    // useEffect(() => {
    //     if (productDetailsState) {
    //         setBannerData(false);
    //     } else {
    //         setBannerData(true);
    //     }
    // }, [productDetailsState]);

    useEffect(() => {
        dispatch(setFlightScreenStep(0));
        dispatch(setFlightDetails(null));
    }, []);

    useEffect(() => {
        // getProducts();
    }, [fields?.selectedCategory, fields?.selectedCountry, fields?.selectedCity, fields?.selectedCurrency])

    // useEffect(() => {
    //     if (search?.length >= 3) {
    //         setTimeout(() => {
    //             getProducts();
    //         }, 1000);
    //     } else if (search?.length === 0) {
    //         setSearch(null);
    //         getProducts();
    //     }
    // }, [search]);




    // const getProducts = async () => {
    //     setLoader(false);
    //     const res = await getAttractionsProducts(fields, search);
    //     setProduct(res.data.data.products);
    //     dispatch(setProductRedux(res.data.data.products));
    //     setLoader(false);
    // }

    const initialData = () => {
        setInitialPage(true)
        setBannerData(true)
    }

    const validation = () => {

        if (!fields?.selectedCurrency) {
            setError({ ...errors, currency: "Select currency" });
            return false;
        }

        return true;
    };

    const getProdDetails = (item) => {
        dispatch(setFieldsRedux(fields));

        const val = validation();
        if (val) {
            //  dispatch(getProductDetails(item.id));
            const idArr = [280745, 280781, 280761];
            var random = idArr[(Math.random() * idArr.length) | 0]
            getProductsDetails(random)

            //  getProductsDetails(item.productId)
            setBannerData(false);
        }
    }

    const getProductsDetails = async (id) => {
        setLoaderProdDetails(true);

        const res = await getAttractionsProductsDetails(id, fields?.selectedCurrency?.currency);
        if (res.data) {
            setProductDetails(res.data.data);
            //dispatch(setProductDetailsRedux(res.data.data));
            dispatch(setAvailabilityRedux(null));
            dispatch(setVisitorsDetailsRedux(null));
            dispatch(setTicketsRedux(null));


        } else {
            setSnackbar(true);
            setBannerData(true);
        }

        setLoaderProdDetails(false);

    }

    const handleClose = (item) => {
        setSnackbar(false);
    };

    return (
        <>

            <Stack direction="row" className='mobileStack' spacing={2} sx={{ width: '100%', marginLeft: '0px !important' }} >

                {initialPage &&
                    <Box className={bannerData ? 'commonw50 mobileBox' : 'commonw50 mobileBox mobileIndex'} p={2}>

                        <PointsComponent />

                        <h1 align='center' className="mt-4"> Get the best from your <strong>Favourite Attraction!</strong>
                        </h1>

                        <>
                            <AttractionFilter
                                fields={fields}
                                categories={categoriesList?.data?.data?.data?.categories}
                                currency={currencyList?.data?.data?.data}
                                countries={countryList?.data?.data?.data}
                                city={cityList?.data?.data?.data}
                                search={search}
                                setFields={setFields}
                                setSearch={setSearch}
                                error={errors}

                            />
                            <>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                    {(isLoading ? Array.from(new Array(6)) : data?.data?.data?.products)?.map((item, index) => {
                                        return (
                                            <>
                                                <Grid key={index} item
                                                    xs={12}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    onClick={() => getProdDetails(item)}>
                                                    <AttractionCard
                                                        item={item}
                                                        title={item?.name}
                                                        imgUrl={item?.image?.src}
                                                        currency={fields?.selectedCurrency}
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    })
                                    }
                                </Grid>
                                {!data?.data?.data?.products?.length &&
                                    <h5 align='center' className="mt-4 text-error">{!isLoading ? "Product not found" : ""}</h5>}
                            </>

                        </>
                    </Box>}
                <Box className='commonw50 mobileBox' style={{ marginLeft: '0px' }}>
                    {bannerData ? initialPage &&
                        <BannerComponent
                            heading={"Welcome to the"}
                            text={settingState?.atrractive?.atrractiveBannerTitle ? settingState?.atrractive?.atrractiveBannerTitle : "World of Endless Rewards!"}
                            banner={banner}
                        /> :
                        <>
                            <Box className='mobileDetailAppBar' sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
                                <ButtonAppBar initialData={initialData} text="Voucher" />
                            </Box>
                            <AttractionDetail
                                loader={loaderProdDetails}
                                productDetails={productDetails}
                            />
                        </>
                    }
                </Box>
            </Stack>

            <SnackbarAlert
                open={snackbar}
                vertical={"bottom"}
                horizontal={"center"}
                handleClose={handleClose}
                severity="error"
                message={"something wrong please try again"}
            />
        </>
    )
}

export default AttractionContainer
