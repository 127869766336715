import {makeStyles} from '@mui/styles';

const useStyle = makeStyles({

    rightSideSection: {
        width:'50%',
        background: '#FFF 0% 0% no-repeat padding-box',
        minHeight: "100vh",
        height: '100%',
        position: "relative",
        borderRadius : '0 0 0 65px/ 0 0 0 40px',
        padding: '45px 35px !important',
        margin: '0 !important',
        '@media (max-width: 900px)': {
            width:'100%',
            background: '#ffffff 0% 0% no-repeat padding-box',
        },
        '@media (max-width: 600px)': {
            width:'100%',
            background: '#ffffff 0% 0% no-repeat padding-box',
        },
        },
        // cornerTopLeft: {
        //     display: 'inline-block',
        //     width: '40px',
        //     height: '65px',
        //     overflow: 'hidden',
        //     position: 'absolute',
        //     left : '-39px',
        //     top: 0,
        //     "&:before": {
        //         content: '""',
        //         display: 'block',
        //         width: '200%',
        //         height: '200%',
        //         position: 'absolute',
        //         borderRadius: '50%',    
        //         top: '0',
        //         right: '0',
        //         boxShadow: '50px -50px 0 0 #F1F1F1',
        //     }

        // },
        accordionTitle : {
            background: '#C69FC3 !important',
            minHeight: 'unset !important',
            height: '48px'
        },
        accordionText: {
            fontSize: '16px !important',
            fontFamily: 'helvetica_neue65_medium !important',
            color: '#282828'
        },
        accordionDetails : {
            padding: '0 !important',
            background: '#f2f5f8',
            border: 'none',
            boxShadow: 'none',
            borderRadius: 0,
        },
        accordionCard: {
            backgroundColor: 'transparent !important',
            boxShadow: 'none !important',
            margin : '20px 0 !important',
        },
        tableTitle : {
            backgroundColor: '#CBCDCE',
            color: '#565656',
            fontSize: '14px !important',
            fontFamily: 'helvetica_neue65_medium !important',
            borderBottom: 'none !important',
            borderRight: '1px solid #ffffff !important',
            fontWeight:'normal !important',
            lineHeight:'1.25 !important',
        },
        tableTD : {
            backgroundColor: 'transparent',
            color: '#565656 !important',
            fontSize: '14px !important',
            fontFamily: 'helvetica_neue45_light !important',
            borderBottom: 'none !important',
            borderRight: '1px solid #ffffff !important',
            fontWeight:'normal !important',
            lineHeight:'1.25 !important',
        },
        tableTitleLast : {
            backgroundColor: '#CBCDCE',
            color: '#565656',
            fontSize: '14px !important',
            fontFamily: 'helvetica_neue65_medium !important',
            borderBottom: 'none !important',
            borderRight: 'none'
        },
        tableTDLast : {
            backgroundColor: 'transparent',
            color: '#565656 !important',
            fontSize: '14px !important',
            fontFamily: 'helveticaneueregular !important',
            borderBottom: 'none !important',
            borderRight: 'none !important'
        },
        tableBtn:{
            color: '#565656 !important',
            fontSize: '14px !important',
        }

    }

)

export default useStyle
