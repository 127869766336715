import React from "react";
import ReactDatePicker from "react-datepicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "react-datepicker/dist/react-datepicker.css";

function DatePicker({
  onChange,
  selected,
  minDate,
  maxDate,
  name,
  id,
  className,
  disabled,
  highlightDates,
  dateFormat,
  excludeDates,
  includeDates,
}) {
  const handleChange = (e) => {
    onChange(e, name);
  };

  return (
    <div className="datePicker-container">
      <ReactDatePicker
        onChange={(selected) => handleChange(selected)}
        selected={selected}
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        id={id}
        className={className}
        dateFormat={dateFormat ? dateFormat : "dd/MM/yyyy"}
        disabled={disabled}
        includeDates={
          includeDates &&
          includeDates.map((item) => {
            return new Date(item);
          })
        }
        highlightDates={
          highlightDates &&
          highlightDates.map((item) => {
            return new Date(item);
          })
        }
        excludeDates={
          excludeDates &&
          excludeDates.map((item) => {
            return new Date(item);
          })
        }
      />
      <CalendarTodayIcon style={{ color: "dimgray" }} />
    </div>
  );
}

export default DatePicker;
