import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import castle from '../../assets/img/Travel/webp/Castle.webp'
import privateRoom from '../../assets/img/Travel/Hotel/Privateroom.jpg'
import Skibbereen from '../../assets/img/Travel/Hotel/CottageinSkibbereen.jpg'
import Ballyporeen from '../../assets/img/Travel/Hotel/CottageinBallyporeen.jpg'
import Enniscorthy from '../../assets/img/Travel/Hotel/CottageinEnniscorthy.jpg'
import FlatinWestport from '../../assets/img/Travel/Hotel/FlatinWestport.jpg'
import StarRoundedIcon from '@mui/icons-material/StarRounded';
const HotelCards = ({ setHotelDetails }) => {
    const hotelCardData = [
        {
            id: 1,
            title: "Castle in Easky, Ireland",
            img: castle,
            rate: "4.84 (96)",
            loc: "Atlantic View Boutique",
            bed: "7 Beds",
            price: "AED 315"
        },
        {
            id: 2,
            title: "Private room in IE",
            img: privateRoom,
            rate: "4.84 (96)",
            loc: "Hazelnut Yurt, private hot tub",
            bed: "1 double bed",
            price: "AED 375"
        },
        {
            id: 3,
            title: "Cottage in Skibbereen",
            img: Skibbereen,
            rate: "4.84 (96)",
            loc: "Lough Hyne Cottage",
            bed: "1 bed",
            price: "AED 620"
        },
        {
            id: 4,
            title: "Appartment in Ballyporeen",
            img: Ballyporeen,
            rate: "4.84 (96)",
            loc: "Mini Gables, Ballyporeen, Co",
            bed: "1 bed",
            price: "AED 175"
        },
        {
            id: 5,
            title: "Cottage in Enniscorthy",
            img: Enniscorthy,
            rate: "4.84 (96)",
            loc: "Peace Paradise in the Sunny Sout",
            bed: "3 Beds",
            price: "AED 379"
        },
        {
            id: 6,
            title: "Flat in Westport",
            img: FlatinWestport,
            rate: "4.84 (96)",
            loc: "Croagh Patrick Apartment, Betra",
            bed: "1 beds",
            price: "AED 491"
        },
        {
            id: 7,
            title: "Castle in Easky, Ireland",
            img: castle,
            rate: "4.84 (96)",
            loc: "Atlantic View Boutique",
            bed: "7 Beds",
            price: "AED 315"
        },
        {
            id: 8,
            title: "Private room in IE",
            img: privateRoom,
            rate: "4.84 (96)",
            loc: "Hazelnut Yurt, private hot tub",
            bed: "1 double bed",
            price: "AED 375"
        },
        {
            id: 9,
            title: "Cottage in Skibbereen",
            img: Skibbereen,
            rate: "4.84 (96)",
            loc: "Lough Hyne Cottage",
            bed: "1 bed",
            price: "AED 620"
        },
        {
            id: 10,
            title: "Appartment in Ballyporeen",
            img: Ballyporeen,
            rate: "4.84 (96)",
            loc: "Mini Gables, Ballyporeen, Co",
            bed: "1 bed",
            price: "AED 175"
        },
        {
            id: 11,
            title: "Cottage in Enniscorthy",
            img: Enniscorthy,
            rate: "4.84 (96)",
            loc: "Peace Paradise in the Sunny Sout",
            bed: "3 Beds",
            price: "AED 379"
        },
        {
            id: 12,
            title: "Flat in Westport",
            img: FlatinWestport,
            rate: "4.84 (96)",
            loc: "Croagh Patrick Apartment, Betra",
            bed: "1 beds",
            price: "AED 491"
        }
    ]
    const maphotelCards = hotelCardData.map((card) => {
        return (
            <div className='col-lg-6 col-sm-6' key={card?.id}>
                <Card onClick={() => setHotelDetails(false)}>
                    <CardActionArea>
                        <div className='mainTravelcard1'>
                            <div className={`hotelcard  `} style={{ background: `url(${card.img}) no-repeat center center` }}>

                            </div>
                        </div>
                        {/* <CardMedia
                            component="img"
                            height="140"
                            image={card.img}
                            alt="green iguana"
                        /> */}
                        <CardContent>
                            <div className='d-flex justify-content-between hotel-card-text'>
                                <span className='hotel-card-title text-nowrap text-truncate w-50'>{card?.title}</span>
                                <span className='d-flex align-items-center'><StarRoundedIcon /> {card?.rate}</span>
                            </div>
                            <div className='d-flex flex-column'>
                                <span className='w-50 text-nowrap text-truncate'>{card?.loc}</span>
                                <span className=''>{card?.bed}</span>
                            </div>
                            <div className='d-flex hotel-card-price'>
                                <span className='me-1'>{card?.price}</span>
                                <span>night</span>
                            </div>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        )
    })
    return (
        <section className='d-flex align-items-center justify-content-center row px-md-5 gy-4 mb-2 w-100 m-margin'>
            {/* <div className='col-lg-6'>
                <Card onClick={() => setHotelDetails(false)}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image={castle}
                            alt="green iguana"
                        />
                        <CardContent>
                            <div className='d-flex justify-content-between hotel-card-text'>
                                <span className='hotel-card-title '>Castle in Easky, Ireland</span>
                                <span className='d-flex align-items-center'><StarRoundedIcon /> 4.84 (96)</span>
                            </div>
                            <div className='d-flex flex-column'>
                                <span className='w-50 text-nowrap text-truncate'>Atlantic View Boutique</span>
                                <span className=''>7 Beds</span>
                            </div>
                            <div className='d-flex hotel-card-price'>
                                <span className='me-1'>AED 315</span>
                                <span>night</span>
                            </div>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div> */}
            {maphotelCards}

        </section>
    )
}

export default HotelCards