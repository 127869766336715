import axios from 'axios'
import { getToken } from '../utils/prodURL';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_SERVER_URL}`
})

instance.interceptors.request.use(
    async (config) => {
        const token = `${process.env.REACT_APP_TOKEN}`;
        //  config.headers.Authorization = `Bearer ${getToken()}`;
        config.headers.Accept = "application/json";

        return config
    },
    error => Promise.reject(error)
)

instance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if ([401].includes(error.response.status)) {
            console.log("Invalid token or expired token.");
            localStorage.clear();
            window.location.reload();
        }

        return Promise.reject(error)
    }
)

export default instance