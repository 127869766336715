/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
    MenuItem,
    IconButton,
    FormHelperText,
    FormControl,
    Select,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Grid
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useStyle from './AttractionFilterStyle';
import { useState } from "react";
import { useEffect } from "react";


const AttractionFilter = ({ fields, currency, categories, countries, city, search, setSearch, setFields, error }) => {
    const classess = useStyle();

    const [sortdeCity, setSortdeCity] = useState(null);

    useEffect(() => {
        let currencyObj = null
        currency && currency.map((item, i) => {
            if (i === 0) {
                currencyObj = item
                //   setFields({ ...fields, "selectedCurrency": item });
            }
        });

        countries && countries.map((item, i) => {
            if (item.id === 208) {
                setFields({ ...fields, "selectedCountry": item, "selectedCurrency": currencyObj });
            }
        });

    }, [currency, countries]);

    useEffect(() => {
        let cityArr = [];
        city && city.map((item) => {
            if (fields?.selectedCountry?.name === item?.countryName) {
                cityArr.push(item);
            }
        })
        setSortdeCity(cityArr);

    }, [fields?.selectedCountry, city]);

    const setCountry = (e) => {
        setFields({ ...fields, "selectedCountry": e, "selectedCity": null });
    }

    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className={
                classess.mb2 && `mt-5 mb-5`
            }>
                <Grid item xs={6} md={4}  >
                    <FormControl className={classess.voucherControll}
                        size="small">
                        <FormHelperText className={classess.voucherLable}>
                            Currency
                        </FormHelperText>
                        <Select value={fields?.selectedCurrency}
                            onChange={(event) => setFields({ ...fields, "selectedCurrency": event.target.value })} >
                            {currency && currency.map((item, index) => (
                                <MenuItem key={item.id} value={item} >{item?.currency + " - " + item?.title}</MenuItem>
                            ))}
                        </Select>
                        {!fields?.selectedCurrency && error.currency ? (
                            <label className="text-error t-error w-100">{error.currency}</label>
                        ) : null}
                    </FormControl>
                </Grid>

                <Grid item xs={6} md={4}  >
                    <FormControl className={classess.voucherControll}
                        size="small">
                        <FormHelperText className={classess.voucherLable}>
                            Country
                        </FormHelperText>
                        <Select value={fields?.selectedCountry}
                            onChange={(event) => setCountry(event.target.value)}>
                            {countries && countries.map((item, index) => (
                                <MenuItem key={item.id} value={item} >{item?.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6} md={4}  >
                    <FormControl className={classess.voucherControll}
                        size="small">
                        <FormHelperText className={classess.voucherLable}>
                            City
                        </FormHelperText>
                        <Select value={fields?.selectedCity}
                            onChange={(event) => setFields({ ...fields, "selectedCity": event.target.value })} >
                            <MenuItem value={null} >None</MenuItem>
                            {sortdeCity && sortdeCity.map((item, index) => (
                                <MenuItem key={item.id} value={item} >{item?.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6} md={4}>
                    <FormControl className={classess.voucherControll}
                        size="small">
                        <FormHelperText className={classess.voucherLable} style={{ margin: '2px 0px' }}>
                            Category
                        </FormHelperText>
                        <Select
                            value={fields?.selectedCategory}
                            onChange={(event) => setFields({ ...fields, "selectedCategory": event.target.value })}
                            inputProps={{ "aria-label": "Without label" }}
                        >
                            <MenuItem value={null} >None</MenuItem>
                            {categories && categories.map((item, index) => (
                                <MenuItem key={item.id} value={item} >{item.category_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormControl className={classess.voucherControll}
                        variant="outlined"
                        size="small">
                        <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                        <FormHelperText className={classess.voucherLable}>
                            Name
                        </FormHelperText>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type="text"
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                            disabled={fields?.selectedCountry ? false : true}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="Search by brand" edge="end">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}

export default AttractionFilter
