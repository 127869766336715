import React, { useState } from 'react';
import useStyle from '../RightSideSection';
import PrintIcon from '@mui/icons-material/Print';
import { Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useQuery } from 'react-query';
import { getProductOrderList } from '../../../../Api/OrderApi';
import { dateFormat, getStatus, printDocument } from '../../../../utils/constantFunctions';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import CommonPagination from '../../../commonComponents/CommonPagination';
import SnackbarAlert from '../../../common/SnackbarAlert';


const VoucherOrder = () => {
    const classess = useStyle();

    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [isSnackbar, setIsSnackbar] = useState(false);

    const { isLoading, error, data } = useQuery(['voucher_order_list', skip, limit], () => getProductOrderList("giftcard", skip, limit));


    const settingState = useSelector((state) => state?.settingState?.settingResponse?.site_details);
    const accountDetailsState = useSelector((state) => state?.settingState?.accountDetails);

    const handleClose = () => {
        setIsSnackbar(false);
    };

    return (
        <>
            {isLoading ?
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={'5%'}
                >
                    <CircularProgress color="secondary" />
                </Stack> :

                <TableContainer className={classess.tableBox} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell className={
                                    classess.tableTitle
                                }>Sr.</TableCell> */}
                                <TableCell className={
                                    classess.tableTitle
                                }>Date</TableCell>
                                <TableCell className={
                                    classess.tableTitle
                                }>Product Name</TableCell>
                                <TableCell className={
                                    classess.tableTitle
                                }>Voucher Amount</TableCell>
                                <TableCell className={
                                    classess.tableTitle
                                }>Order Amount</TableCell>
                                <TableCell className={
                                    classess.tableTitle
                                }>Quantity</TableCell>
                                <TableCell className={
                                    classess.tableTitle
                                }>Status
                                    {/* (E.g. Active/Redeemed/Expired) */}
                                </TableCell>
                                <TableCell className={
                                    classess.tableTitleLast
                                }>Download Invoice</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data?.data?.result && data?.data?.result?.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {/* <TableCell className={
                                        classess.tableTD
                                    } component="th" scope="row">
                                        {index + 1}
                                    </TableCell> */}
                                    <TableCell className={
                                        classess.tableTD
                                    }>{dateFormat(row?.created_at)}</TableCell>
                                    <TableCell className={
                                        classess.tableTD
                                    }>{row?.giftcard?.default_title}</TableCell>
                                    <TableCell className={
                                        classess.tableTD
                                    }>{row?.total_price}</TableCell>
                                    <TableCell className={
                                        classess.tableTD
                                    }>{row?.order_price}</TableCell>
                                    <TableCell className={
                                        classess.tableTD
                                    }>{row.quantity}</TableCell>
                                    <TableCell className={
                                        classess.tableTD
                                    }>{getStatus(row?.order_status)}</TableCell>
                                    <TableCell className={
                                        classess.tableTDLast
                                    }>
                                        <Button variant="text"
                                            className={classess.tableBtn}
                                            onClick={() => {
                                                printDocument("giftcard", row, settingState, accountDetailsState);
                                                setIsSnackbar(true);
                                            }}>
                                            <PrintIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <CommonPagination limit={limit} setPage={setPage} setSkip={setSkip} page={page} setLimit={setLimit} skip={skip} total={data?.data?.count} />
                </TableContainer >}
            <>
                <SnackbarAlert
                    open={isSnackbar}
                    vertical={"bottom"}
                    horizontal={"center"}
                    handleClose={handleClose}
                    severity="info"
                    autoHideDuration={2000}
                    message={"Please wait..."}
                />
            </>
        </>
    )
}

export default VoucherOrder;