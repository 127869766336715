/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout";
import { setAccountDetailsRedux } from "../redux/Settings/settingsAction";
import { PublicRoutes } from "./PublicRoutes";
import { PrivateRoutes } from "./PrivateRoutes";

const EhadayaRouter = () => {
  const dispatch = useDispatch();

  const accountDetailsState = useSelector(
    (state) => state?.settingState?.accountDetails
  );

  const [isLoading, setIsLoding] = React.useState(false);

  useEffect(() => {
    if (!accountDetailsState) {
      let details = localStorage.getItem("user");
      dispatch(setAccountDetailsRedux(JSON.parse(details)));
    }
  }, []);

  useEffect(() => {}, [accountDetailsState]);

  const isAuthonticted = accountDetailsState?.token;

  return (
    <>
      <BrowserRouter>
        <Routes>
          {isLoading ? (
            <Route
              exact
              path="/"
              element={
                <div className="loader">
                  <CircularProgress color="secondary" />
                </div>
              }
            />
          ) : (
            <>
              <Route path="/" element={<Layout />}>
                {PublicRoutes?.map((item, index) => {
                  return (
                    <Route
                      exact
                      path={item.url}
                      element={item.component}
                      key={index}
                    />
                  );
                })}
                {isAuthonticted &&
                PrivateRoutes?.map((item, index) => {
                  return (
                    <Route
                      exact
                      path={item.url}
                      element={item.component}
                      key={index}
                    />
                  );
                })
                }
              </Route>
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default EhadayaRouter;
