import React, { useEffect } from 'react'
import './Attraction.css'
import { setFlightDetails, setFlightScreenStep } from '../../redux/Flight/flightAction';
import { useDispatch } from 'react-redux';
import AttractionContainer from './container/AttractionContainer';

const Attraction = () => {
  const dispatch = useDispatch()


  // Travel module step reset to 0
  useEffect(() => {
    dispatch(setFlightScreenStep(0));
    dispatch(setFlightDetails(null));
  }, [])

  return (
    <>
      <AttractionContainer />
    </>
  )
}

export default Attraction