/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import user from "../../assets/img/voucher/user.png";
import Avatar from "@mui/material/Avatar";
import OtpInput from "react-otp-input";
import { Button, InputLabel } from "@mui/material";
import { UseOnKeyDown } from "../../hooks/UseOnKeyDown";
import { sendOTPApi, signInWithOtpApi } from "../../Api/Auth";
import { LoadingButton } from "@mui/lab";


export default function OtpScr({ field, setOtpModel }) {

  const [otp, setOtp] = useState("");
  const [error, setError] = useState({ otp: "", message: "" });
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoding] = useState(false);



  // OTP Time Add function
  useEffect(() => {
    // getOTPTimes();
    field.username && setMinutes(1);
    field.username && setSeconds(59);
  }, [field?.message]);

  // If user not found on OTP screen
  useEffect(() => {
    if (field.username === "" || field.password === "") {
      setOtpModel(false);
    }
  }, [field.username, field.password]);

  // OTP timer functionality
  let expireTimer;
  useEffect(() => {
    expireTimer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(expireTimer);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(expireTimer);
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoding(false);
    }, 10000);
  }, [message]);

  useEffect(() => {
    setIsLoding(false);
    setMessage("");
  }, [otp]);

  // OTP input handler
  const OTPHandler = (e) => {
    setOtp(e);
    setError({ ...error, otp: "" });
    setMessage("");
  };

  // OTP validation
  const validate = () => {
    if (otp.length !== 6) {
      setError({ ...error, otp: "Please enter OTP" });
      return false;
    }
    return true;
  };

  const submitOTP = async () => {

    let val = validate();
    if (val) {
      setIsLoding(true);
      let response = await signInWithOtpApi(field.username, field.password, otp);
      if (response?.data?.status === "success") {
        localStorage.setItem("token", JSON.stringify(response.data.result.token));
        localStorage.setItem("permissions", JSON.stringify(response.data.result.role_permissions.permissions));
        localStorage.setItem("user", JSON.stringify(response.data.result));
        localStorage.setItem("loggedIn", true);
        localStorage.removeItem("otpTime");
        window.location.reload();
      } else if (response?.data?.status === "error") {
        if (response?.data?.errors[0] === "OTP Expired") {
          setMinutes(0);
          setSeconds(0);
          setIsLoding(false);

        } else {
          setMessage(response?.data?.errors[0]);
          setIsLoding(false);
        }
        setMessage(response?.data?.errors[0]);
        setIsLoding(false);
      }
      setIsLoding(false);
    }
  };

  // Resend OTP handler
  const resendOtp = async () => {

    if (field?.username && field?.username) {
      let response = await sendOTPApi(field.username, field.password);

      if (
        response?.data?.status === "success" ||
        response?.data?.code === 429 ||
        response?.data?.code === 401
      ) {
        if (response?.data?.code === 429) {
          setMessage("Otp has been already sent. Please use the that OTP.");
        } else if (response?.data?.code === 401) {
          setMessage(response?.data?.message);
        } else {
          field.username && setMinutes(1);
          field.username && setSeconds(59);
          setMessage(response?.data?.message);
        }
      } else if (response?.data?.status === "error") {

      }

    }
  };

  //following function is used to detect enter key hit
  UseOnKeyDown(submitOTP, "Enter");

  return (
    <div className="d-flex flex-column justify-content-center align-items-center ">
      <div style={{ paddingTop: "20px", paddingLeft: "30px", paddingRight: "30px" }}>
        <Avatar className="mui-useravtar my-3" src={user} />
        <InputLabel>OTP sent through registered email</InputLabel>

        <div className="my-3">
          <div className="col-sm-12">
            <OtpInput
              value={otp}
              onChange={OTPHandler}
              numInputs={6}
              separator={<span className="mx-2"></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={"otp-input-style"}
            //containerStyle={"px-4 py-4"}
            />
          </div>
          {error.otp ? <label className="text-error">{error.otp}</label> : null}
        </div>

        <div className="d-flex justify-between">
          {minutes === 0 && seconds === 0 ? (
            <span
              onClick={resendOtp}
              className="text-light-blue font-14 font-bold cursor"
            >
              Resend OTP
            </span>
          ) : null}

          {minutes === 0 && seconds === 0 ? null : (
            <span className="text-light-blue font-14 font-bold">
              {minutes < 10 ? "0" + minutes : minutes}:{" "}
              {seconds < 10 ? "0" + seconds : seconds}
            </span>
          )}
        </div>

        <div className="my-3">
          <div className="d-flex justify-content-between">
            {minutes === 0 && seconds === 0 ? (
              <Button
                variant="outlined"
                className={`borderButton`}
                onClick={() => { setOtpModel(false); setMessage("") }}
              >
                Back
              </Button>
            ) : null}

            <LoadingButton
              className={isLoading ? "fillBtn-loader-true" : "fillBtn-loader"}
              loading={isLoading}
              variant="outlined"
              onClick={() => submitOTP()}
            >
              {isLoading ? "" : "Continue"}
            </LoadingButton>
          </div>
        </div>
      </div>

      {message &&
        <div className="loginErrorLable" style={{ paddingTop: "20px", paddingLeft: "30px", paddingRight: "30px" }}>
          <span className="font-14 font-bold ">
            {message && message}
          </span>
        </div>
      }

    </div>
  );
}
