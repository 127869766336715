import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider({ hours }) {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(value);
  };

  return (
    <Box sx={{ width: 200 }}>
      <div className="border px-1">
        <Slider
          getAriaLabel={() => "Temperature range"}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          style={{ color: "var(--primayColor)" }}
          sx={{
            "& .MuiSlider-thumb": {
              borderRadius: "1px",
              width: "10px",
              height: "15px",
            },
            "& .MuiSlider-rail, .MuiSlider-track": {
              color: "black",
              opacity: "1",
            },
          }}
        />
      </div>
      <div className="d-flex justify-content-between w-100">
        <div>
          {value[0]}
          {hours}
        </div>
        {value[1]}
        {hours}
      </div>
    </Box>
  );
}
