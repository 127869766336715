import {makeStyles} from '@mui/styles';

const useStyle = makeStyles({

    redeemButton:{
        display:'flex !important',
        backgroundColor: 'var(--primayColor) !important',
        textTransform: "capitalize !important ",
        marginTop: '40px !important',
        fontFamily: 'helvetica_neue65_medium !important',
        marginBottom: '20px !important',
        '&:hover': {
        border:'1px solid var(--primayColor) !important',
        backgroundColor: '#ffffff !important',
        boxShadow:'none !important',
        opacity: [
                0.9, 0.8, 0.7
        ],
        color: "var(--primayColor) !important ",
        }
    },
    redeemformInput:{
        width: '28ch ', 
        marginLeft:'0px !important', 
        marginTop:'23px !important'
    },
    redeemchecktext1:{
        alignItems: "center !important ", 
        display: "flex !important ",
        fontFamily:'helvetica_neue45_light !important',
        fontSize:'12px !important'
    },
    redeemForm:{
        width: '30ch !important', 
        marginLeft:'0px !important', 
        marginTop:'15px !important'
    },
    redeemchecktext:{
        alignItems: "center !important ",
        display: "flex !important ",
        marginTop: '10px !important',
        fontFamily:'helvetica_neue45_light !important',
        fontSize:'14px !important' 
    },
    redeemContainer:{
        width:'100% !important',
        padding: '0px 0px 0px 7px !important'
    },
    tabName:{
        fontWeight:'600 !important',
        minHeight:'66px !important'
    },
    tabs:{
        backgroundColor:'#f1f1f1 !important',
         fontWeight: 600
    },
    reedemBox:{
        bgcolor: "#ffffff !important ", 
        width: "460px !important",
        '@media (max-width: 450px)': {
            width: "400px !important",
        },
        '@media (max-width: 400px)': {
            width: "350px !important",
        },
    },
    username:{
        alignItems: "center !important ", 
        display: "flex !important ", 
        fontFamily:'helvetica_neue45_light !important',
        fontSize: '20px !important'
    }

});

export default useStyle
