/* eslint-disable react-hooks/exhaustive-deps */

import { Button } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { dateFormat, getStatus, printDocument } from '../../utils/constantFunctions';


const GiftcardTable = ({ orderDetails, setIsSnackbar }) => {

    const settingState = useSelector((state) => state?.settingState?.settingResponse?.site_details);
    const accountDetailsState = useSelector((state) => state?.settingState?.accountDetails);


    return (
        <>
            <div className="table-responsive">
                <table className="table mt-3 w-100 passengertable">
                    <tr>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Date</td>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Order Id</td>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Product Name</td>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Quantity</td>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Order Price</td>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Total Price</td>
                        <td className="bg-dark-grey py-2 px-3 fsc-5">Status</td>
                    </tr>
                    <tr>
                        <td className="bg-grey py-2 px-3 fsc-5">{dateFormat(orderDetails?.created_at)}</td>
                        <td className="bg-grey py-2 px-3 fsc-5">{orderDetails?.id}</td>
                        <td className="bg-grey py-2 px-3 fsc-5">{orderDetails?.product_name}</td>
                        <td className="bg-grey py-2 px-3 fsc-5">{orderDetails?.quantity}</td>
                        <td className="bg-grey py-2 px-3 fsc-5">{orderDetails?.order_price}</td>
                        <td className="bg-grey py-2 px-3 fsc-5">{orderDetails?.total_price}</td>

                        <td className="bg-grey py-2 px-3 fsc-5">{getStatus(orderDetails?.order_status)}</td>

                    </tr>
                </table>
            </div>
            {orderDetails &&
                <div className="d-flex justify-content-end mt-3">
                    <Button
                        onClick={() => {
                            printDocument("giftcard", orderDetails, settingState, accountDetailsState);
                            setIsSnackbar(true);
                        }}
                        variant="contained"
                        className="backcolorPrimary my-5 ms-4"
                        style={{ textTransform: "initial" }}
                    >
                        Print
                    </Button>
                </div>}
        </>
    )

}

export default GiftcardTable