/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import useStyle from './VoucherDetailStyle';
import { Container, Box, Typography, Grid, Button, FormControl, TextField, Stack, Avatar, Tab, } from '@mui/material';
import voucherbanner from '../../../assets/img/voucher/voucherbanner.webp'
import PaymentTab from "../../drawer/PaymentTab";
import { useState } from 'react';
import CommonDrawer from '../../common/CommonDrawer';
import LoginScreen from '../../signin/LoginScreen';
import { useDispatch, useSelector } from 'react-redux';
import { setProductOrderDetailsRedux } from '../../../redux/Order/OrderAction';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import "./style.css";




const VoucherDetail = ({ title, imgUrl, open, setOpen, ProductDetail, item }) => {
    const classess = useStyle();
    const dispatch = useDispatch();

    const accountDetailsState = useSelector((state) => state?.settingState?.accountDetails);
    const userPointState = useSelector((state) => state?.orderState?.points);

    const [denomination, setDenomination] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [voucherPrice, setVoucherPrice] = useState("");
    const [email, setEmail] = useState("");
    const [tabValue, setTabValue] = React.useState('1');

    const [payNowFlag, setPayNowFlag] = useState(false);
    const [loginFlag, setLoginFlag] = useState(false);
    const [loginStatus, setLoginStatus] = useState(false);

    const [error, setError] = useState({
        voucherPrice: "",
        email: ""
    });

    useEffect(() => {
        setLoginStatus(Boolean(localStorage.getItem("loggedIn")));
    }, []);

    useEffect(() => {
        if (!email) {
            setEmail(accountDetailsState?.email);
        }
    }, [accountDetailsState]);

    useEffect(() => {
        if (ProductDetail?.giftcard_value_type === "denominations" && ProductDetail?.denominations) {
            setDenomination(ProductDetail?.denominations[0]);
        }
        setVoucherPrice("");
    }, [ProductDetail]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const voucherPriceHandler = (e) => {
        let regex = /^\d{0,15}(\.\d{0,2})?$/;
        if (regex.test(e.target.value)) {
            setVoucherPrice(e.target.value);
        }
        if (ProductDetail?.min_amount > parseFloat(e.target.value) || parseFloat(e.target.value) > ProductDetail?.max_amount) {
            setError({ ...error, voucherPrice: "Please enter correct amount" });
        } else {
            setError({ ...error, voucherPrice: "" });
        }
        setDenomination(null);
    }

    const validation = () => {
        const emailRegex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (!voucherPrice && ProductDetail?.giftcard_value_type === "range") {
            setError({ ...error, voucherPrice: "Please enter amount" });
            return false;

        } else if (ProductDetail?.min_amount > parseFloat(voucherPrice) || parseFloat(voucherPrice) > ProductDetail?.max_amount) {
            setError({ ...error, voucherPrice: "Please enter correct amount" });
            return false;
        } else if (!email || emailRegex.test(email) === false) {
            setError({ ...error, email: "Enter valid email" });
            return false;
        }
        return true;
    }

    const submit = () => {
        let val = validation();
        if (val && loginStatus) {
            setPayNowFlag(true);
            let totalAmount = ProductDetail?.giftcard_value_type === "denominations" ? denomination?.denomination_value * quantity : voucherPrice;
            let disAmt = (totalAmount * (item?.client_discount && item?.client_discount > 0 ? item?.client_discount : 0)) / 100;
            let obj = {
                prodType: "giftcard",
                prodDetails: ProductDetail,
                persnalDetails: accountDetailsState,
                denomination: denomination,
                quantity: ProductDetail?.giftcard_value_type === "denominations" ? quantity : 1,
                email: email,
                amount: totalAmount,
                discount: item?.client_discount && item?.client_discount > 0 ? item?.client_discount : 0,
                orderAmount: totalAmount - disAmt
            }
            dispatch(setProductOrderDetailsRedux(obj));
        } else if (!loginStatus) {
            setLoginFlag(true);
        }
    }


    const textFormater = (str) => {
        var str1 = str.slice(0, -2) + '';
        var str2 = str1.split('\n\n').join('\n');
        return str2.split('\n').join('<br><p><li>');
    }

    return (
        <>

            <div className={classess.cardDatail} style={{ width: '-webkit-fill-available' }}>

                <Container maxWidth="lg">
                    <>
                        <Box
                            style={{ backgroundImage: `url(${voucherbanner})` }}
                            className={classess.hero}>
                            <div style={{
                                backgroundColor: '#fff',
                                padding: '0px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '146px'
                            }}>
                                <img src={imgUrl}
                                    alt="logo"
                                    className={classess.logo} />
                            </div>
                        </Box>
                        <Typography variant="h6"
                            className={classess.voucherText}>
                            {title} </Typography>

                        {ProductDetail?.giftcard_value_type === "denominations" ?
                            <>
                                <Typography variant="p"
                                    className={classess.voucherValueText}>
                                    Select Gift Voucher Value: ({ProductDetail?.country?.currency})
                                </Typography>
                                <Grid item
                                    xs={10}
                                    className={classess.voucherValueDiv}
                                    style={{ display: 'block' }}>

                                    {ProductDetail?.denominations && ProductDetail?.denominations.sort((a, b) => {
                                        return a.denomination_value - b.denomination_value;
                                    }).map((item) => {
                                        return (
                                            <Button variant="outlined"
                                                className={item?.id === denomination?.id ? classess.voucherSelectedValue : classess.voucherValue}
                                                onClick={() => { setDenomination(item) }}>
                                                {item?.denomination_value}
                                            </Button>
                                        )
                                    })
                                    }
                                </Grid>
                                {/* <Grid item
                                    xs={6}
                                    className={classess.voucherQtyMain}>
                                    <Box style={{ marginRight: '15px' }}>
                                        <Typography variant="p"
                                            className={classess.voucherQtyText}>
                                            Quantity
                                        </Typography>
                                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                                            <Button className={classess.voucherQtyValue}
                                                style={{ color: '#7A7A7A' }}
                                                onClick={() => { setQuantity(quantity + 1) }}>
                                                +
                                            </Button>
                                            <Button
                                                className={classess.voucherQtyValue}
                                                style={{ color: '#7A7A7A' }}>
                                                {quantity}
                                            </Button>
                                            <Button
                                                className={classess.voucherQtyValue}
                                                style={{ color: '#7A7A7A' }}
                                                onClick={() => { quantity > 1 && setQuantity(quantity - 1) }}>
                                                -
                                            </Button>
                                        </ButtonGroup>
                                    </Box>
                                    <Box>
                                        <Typography variant="p" className={classess.voucherQtyText}
                                        >
                                            Total AED
                                        </Typography>

                                        <Button
                                            className={classess.voucherQtyValue} disabled
                                            style={{
                                                color: 'var(--primayColor)', width: '120px', border: "1px solid #C2C2C2", backgroundColor: '#ffffff', orderRadius: '6px',
                                                fontFamily: 'helveticaneuebold_', justifyContent: 'start',
                                            }}>
                                            {denomination?.denomination_value * quantity}
                                        </Button>
                                    </Box>

                                </Grid> */}
                                <Grid item
                                    xs={6}
                                    className={classess.voucherQtyMain}>
                                    <Box style={{ marginRight: '15px' }}>
                                        <Typography variant="p"
                                            className={classess.voucherQtyText}>
                                            Email
                                        </Typography>
                                        <FormControl fullWidth className="me-3">
                                            <TextField
                                                onChange={(e) => {
                                                    setEmail(e.target.value.toLowerCase())
                                                    setError({ ...error, email: "" })
                                                }}
                                                value={email}
                                                name={"email"}
                                                id={"email"}
                                                type={"text"}
                                                inputProps={{ inputMode: "text", style: { padding: 17, backgroundColor: "#f2f5f8" } }}
                                            />
                                            <label className="text-error t-error w-100 my-1">{error.email ? error.email : ""}</label>
                                        </FormControl>
                                    </Box>

                                </Grid>
                            </> :
                            <>
                                <div className='d-flex flex-column gap-2'>
                                    <Typography variant="p"
                                        className={classess.voucherValueText}>
                                        Enter Gift Voucher Amount ({ProductDetail?.country?.currency})
                                    </Typography>
                                    <Typography variant="p"
                                        className={classess.voucherValueText}>
                                        Minimum amount: {ProductDetail?.country?.currency_symbol + " " + ProductDetail?.min_amount} - Maximum amount: {ProductDetail?.country?.currency_symbol + " " + ProductDetail?.max_amount}
                                    </Typography>

                                </div>

                                <Grid item
                                    xs={6}
                                    className={classess.voucherQtyMain}>
                                    <Box style={{ marginRight: '15px' }}>
                                        <Typography variant="p"
                                            className={classess.voucherQtyText}>
                                            Amount
                                        </Typography>
                                        <FormControl fullWidth className="me-3">
                                            <TextField
                                                onChange={voucherPriceHandler}
                                                value={voucherPrice}
                                                name={"voucherPrice"}
                                                id={"voucherPrice"}
                                                type={"text"}
                                                inputProps={{ inputMode: "text", style: { padding: 17, backgroundColor: "#f2f5f8" } }}
                                            />
                                            <label className="text-error t-error w-100 my-1">{error.voucherPrice ? error.voucherPrice : ""}</label>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item
                                    xs={6}
                                    className={classess.voucherQtyMain}>
                                    <Box style={{ marginRight: '15px' }}>
                                        <Typography variant="p"
                                            className={classess.voucherQtyText}>
                                            Email
                                        </Typography>
                                        <FormControl fullWidth className="me-3">
                                            <TextField
                                                onChange={(e) => {
                                                    setEmail(e.target.value.toLowerCase())
                                                    setError({ ...error, email: "" })
                                                }}
                                                value={email}
                                                name={"email"}
                                                id={"email"}
                                                type={"text"}
                                                inputProps={{ inputMode: "text", style: { padding: 17, backgroundColor: "#f2f5f8" } }}
                                            />
                                            <label className="text-error t-error w-100 my-1">{error.email ? error.email : ""}</label>
                                        </FormControl>
                                    </Box>

                                </Grid>
                            </>}

                        <div className='my-3'>
                            <Button
                                onClick={() => submit()}
                                variant="contained"
                                className="backcolorPrimary my-4"
                                style={{ textTransform: "initial" }}
                            >
                                Buy Now
                            </Button>
                        </div>
                        {/* <TemporaryDrawer /> */}
                        {/* <Box sx={{ width: '100%', bgcolor: '#FFF' }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                textColor="#000"
                                indicatorColor="FFF"
                            >
                                <Tab value="one" label="Description" />
                                <Tab value="two" label="Terms & Conditions" />
                            </Tabs>
                        </Box> */}

                        <TabContext value={tabValue}>
                            <Box sx={{ bgcolor: '#FFF' }}>
                                <TabList onChange={handleChange}
                                    textColor="#000"
                                    indicatorColor="FFF"
                                    aria-label="lab API tabs example"
                                >
                                    <Tab className="descterm" label="Description" value="1" sx={{ '&.Mui-selected': { borderBottom: '3px solid', borderColor: '#77126a' }, color: 'black  !important' }} />
                                    <Tab className="descterm" label="Terms & Conditions" value="2" sx={{ '&.Mui-selected': { borderBottom: '3px solid', borderColor: '#77126a' }, color: 'black  !important' }} />
                                </TabList>
                            </Box>
                            <TabPanel value="1" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                <div style={{ paddingBottom: '20px' }}>
                                    <Box className={classess.termsAndCondition}>
                                        <Typography variant="p"
                                            className={classess.termsheading}
                                            style={{ fontWeight: '400' }}>
                                            Description
                                        </Typography>
                                        <ul className={classess.condition}>
                                            <li
                                                className={classess.conditionList}
                                                dangerouslySetInnerHTML={{ __html: textFormater(ProductDetail?.about_the_product?.[0]?.description_text) }}
                                            >
                                            </li>
                                        </ul>
                                    </Box>
                                </div>
                            </TabPanel>
                            <TabPanel value="2" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                <div style={{ paddingBottom: '20px' }}>
                                    <Box className={classess.termsAndCondition}>
                                        <Typography variant="p"
                                            className={classess.termsheading}
                                            style={{ fontWeight: '400' }}>
                                            Terms & Conditions
                                        </Typography>
                                        <ul className={classess.condition}>
                                            <li
                                                className={classess.conditionList}
                                                dangerouslySetInnerHTML={{
                                                    __html: textFormater(ProductDetail?.terms_condition?.[0]?.terms_condition_text)
                                                }}
                                            >
                                            </li>
                                        </ul>
                                    </Box>
                                </div>

                            </TabPanel>
                        </TabContext>


                    </>

                </Container>
            </div>
            <CommonDrawer onClose={setPayNowFlag} open={payNowFlag}>
                <Box sx={{ bgcolor: "#ffffff", width: "100%" }}>
                    <Box
                        style={{ margin: '20px 0px 20px 0px', width: "100%" }}
                        sx={{ width: "right" === "top" || "right" === "bottom" ? "auto" : 250 }}
                        role="presentation">
                        <Container maxWidth="sm">
                            <Stack direction="row" spacing={2} justifyContent={"space-between"} style={{ paddingLeft: '0px' }}>
                                <Stack direction="row" justifyContent="space-evenly" spacing={2}>
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                    <Typography
                                        variant="h6"
                                        component="h6"
                                    >
                                        {accountDetailsState?.firstname + " " + accountDetailsState?.lastname}
                                    </Typography>
                                </Stack>
                                {userPointState && <Typography
                                    variant="h6"
                                    component="h6"
                                >
                                    {parseFloat(userPointState).toFixed(2)} Pts
                                </Typography>}

                            </Stack>
                        </Container>
                    </Box>
                    <PaymentTab
                        amount={ProductDetail?.giftcard_value_type === "denominations" ? denomination?.denomination_value * quantity : voucherPrice}
                        discount={item?.client_discount && item?.client_discount > 0 ? item?.client_discount : 0}
                    />
                </Box>
            </CommonDrawer>
            <CommonDrawer onClose={setLoginFlag} open={loginFlag}>
                <Box sx={{ bgcolor: "#ffffff", width: "100%" }}>
                    <LoginScreen open={loginFlag} onClose={setLoginFlag} />
                </Box>
            </CommonDrawer>
        </>
    )
}

export default VoucherDetail
