import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  mainDiv: {
    textAlign: "center",
  },
  tredingText: {
    color: "#221F3C",
  },

  tradingDiv: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "helvetica_neue65_medium",
  },
  categorylist: {
    width: "60px !important",
    height: "60px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    border: "1px solid #f1f1f1",
    borderRadius: "100%",
    boxShadow: "10px 4px 16px #0000003C",
    cursor: "pointer",
    backgroundColor: "#ffffff",
    marginBottom: "10px",
    transition: "0.3s all",
    "&:hover": {
      backgroundColor: "var(--primayColor)",
      border: "1px solid var(--primayColor)",
    },
  },
  stackList: {
    justifyContent: "space-around",
    padding: "20px",
    marginBottom: "10px",
  },
  // boxMainDiv:{
  //     height: '100vh',
  // overflowY: 'scroll',
  // '&::-webkit-scrollbar':{
  //     display: 'none',
  // },
  // }
  container: {
    padding: "0px 0px 0px 30px",
    maxWidth: "100%",
  },
  card: {
    height: "175px",
    opacity: 1,
    border: "1px solid #F1F1F1",
    boxShadow: "none !important",
    marginBottom: "1rem",
    cursor: "pointer",
    transition: "0.3s all",
    "&:hover": {
      border: "1px solid var(--primayColor)",
    },
  },
  cardTitle: {
    background: "#F1F1F1 0% 0% no-repeat padding-box",
    padding: "8px 12px !important",
    opacity: 1,
    fontFamily: "helvetica_neue65_medium",
    color: "#565656",
    fontSize: "15px",
  },
  cardImage: {
    maxWidth: "200px",
    maxHeight: "112px",
    width: "200px",
    height: "112px",
    backgroundSize: "contain !important",
    backgroundPosition: "center !important",
  },
  cardImageBox: {
    height: "138px",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardGrid: {
    height: "100vh",
    overflowY: "scroll",
    position: "sticky",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  inputValue: {
    "@media (max-width: 429px)": {
      width: "15ch !important",
    },
  },
});

export default useStyle;
