import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import moment from "moment";
import Menu from "./Menu";
import hotelsidebanner from "../../assets/img/Travel/webp/sunset-pool.webp";
import "./hotels.css";
import Tabs from "./Tabs";
import TrendingProducts from "./TrendingProducts";
import BannerComponent from "./BannerComponent";
import HotelResult from "./HotelResult";
import HotelDetails from "./HotelDetails";
import Box from "@mui/material/Box";
import ButtonAppBar from "../common/Appbar";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../DatePicker/DatePicker";
import { nextDayDate, nextYearRange } from "../../utils/constantFunctions";
import { setHotelScreenStep, setSearchHotelDetails } from "../../redux/Hotel/hotelAction";
import { min } from "moment/moment";

export default function HotelsComponent({ option, setoption }) {
  const dispatch = useDispatch();
  const todaysDate = moment(new Date()).format("YYYY-MM-DD");

  const settingState = useSelector((state) => state?.settingState?.settingResponse);
  const hotelDetailState = useSelector((state) => state?.hotelState?.hotelDetails);
  const step = useSelector((state) => state?.hotelState?.step);

  const [fields, setFields] = useState({
    city: hotelDetailState?.city ? hotelDetailState?.city : "",
    check_In_Date: hotelDetailState?.check_In_Date ? new Date(hotelDetailState?.check_In_Date) : new Date(),
    check_Out_Date: hotelDetailState?.check_Out_Date ? new Date(hotelDetailState?.check_Out_Date) : new Date(),
    rooms: hotelDetailState?.rooms ? hotelDetailState?.rooms : 1,
    adult: hotelDetailState?.adult ? hotelDetailState?.adult : 2,
    child: hotelDetailState?.child ? hotelDetailState?.child : 0,
  });

  const [error, setError] = useState({
    city: "",
    check_In_Date: "",
    check_Out_Date: "",
    rooms: "",
    adult: "",
    child: "",
  });

  const [bannerShow, setbannerShow] = useState(true);
  const [hotelDetails, setHotelDetails] = useState(true);

  useEffect(() => {
    if (step >= 1) {
      setbannerShow(false);
      setHotelDetails(true);
    }
  }, [step]);

  const initialData = () => {
    setbannerShow(true);
  };

  const selectHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields({ ...fields, [name]: value })
    setError({ ...error, [name]: "" })
  }

  const dateHandler = (item, name) => {
    if (name === "check_In_Date") {
      setFields({ ...fields, "check_In_Date": item, "check_Out_Date": nextDayDate(item) });
    } else if (name === "check_Out_Date") {
      setFields({ ...fields, "check_Out_Date": item });
    }
  };

  const plusButtonHandler = (e) => {
    const name = e.target.name;

    if (name === "adult" && fields.adult < 30) {

      setFields({ ...fields, [name]: Number(fields.adult) + 1 });
      setError({ ...error, adult: "" });
    } else if (name === "child" && fields.child < 10) {
      setFields({ ...fields, [name]: Number(fields.child) + 1 });
      setError({ ...error, child: "" });
    }
  };

  const minusButtonHandler = (e) => {
    const name = e.target.name;
    if (name === "adult" && fields.adult > 1) {

      setFields({ ...fields, [name]: Number(fields.adult) - 1 });
      setError({ ...error, adult: "" });
    } else if (name === "child" && fields.child > 0) {
      setFields({ ...fields, [name]: Number(fields.child) - 1 });
      setError({ ...error, child: "" });
    }
  };


  const validation = () => {
    if (!fields.city) {
      setError({ ...error, city: "Please select city" });
      return false;
    } else if (!fields.check_In_Date) {
      setError({ ...error, check_In_Date: "Please select date" });
      return false;
    } else if (!fields.check_Out_Date) {
      setError({ ...error, check_Out_Date: "Please select date" });
      return false;
    } else if (!fields.rooms) {
      setError({ ...error, rooms: "Please select rooms" });
      return false;
    } else if (!fields.adult) {
      setError({ ...error, adult: "Please enter the adult" });
      return false;
      // } else if (noPasssenger >= 11) {
      //   setError({ ...error, maxPassenger: "Maximum 10 passengers allowed" });
      //   return false
    }

    return true;
  };

  const searchHotelsHandler = () => {
    let val = validation();
    if (!val) {
      setbannerShow(true);
    }

    if (val) {
      setbannerShow(false);
      dispatch(setHotelScreenStep(1));
      dispatch(setSearchHotelDetails(fields));
    }
  };

  return (
    <>
      <div className="hotel w-100 d-flex mobileStack">
        <div
          className={
            bannerShow
              ? "commonw50 mainLeft mobileBox"
              : "commonw50 mainLeft mobileBox mobileIndex"
          }
        >
          <Tabs setoption={setoption} option={option} />
          <div className="d-flex flex-column align-items-center">
            <Box
              sx={{
                width: { xs: "100%", sm: "auto" },
                padding: { xs: "0px 15px", sm: "0px" },
              }}
            >
              <div className="my-3">
                <InputLabel>City</InputLabel>
                <Select
                  id="selectCity"
                  sx={{
                    width: { xs: "100%", sm: "75vw", md: "31vw", lg: "100%" },
                  }}
                  name={"city"}
                  value={fields.city}
                  onChange={selectHandler}
                >
                  <MenuItem value={"Sharjah"}>Sharjah</MenuItem>
                  <MenuItem value={"Al Ain"}>Al Ain</MenuItem>
                  <MenuItem value={"Ras Al Khaimah"}>Ras Al Khaimah</MenuItem>
                </Select>
                {error && error.city ? (
                  <label className="text-error ">{error.city}</label>
                ) : null}
              </div>
              <div className="my-3">
                <Stack
                  direction="row"
                  spacing={2}
                  className="mobileFlexDirection"
                  sx={{ justifyContent: "space-between" }}
                >
                  <Stack spacing={0} style={{ width: "100%" }}>
                    <InputLabel>Check-in</InputLabel>
                    <DatePicker
                      onChange={dateHandler}
                      selected={fields?.check_In_Date}
                      minDate={new Date()}
                      maxDate={nextYearRange()}
                      name={"check_In_Date"}
                      id="check_In_Date"
                      className="datepicker-input-style-without-border"
                    />
                    {/* {error && error.departure ? (
                    <label className="text-error">{error.departure}</label>
                  ) : null} */}
                  </Stack>
                  <Stack
                    spacing={0}
                    style={{ width: "100%" }}
                    className="marginLeft"
                  >
                    <InputLabel>Check-out</InputLabel>
                    <DatePicker
                      onChange={dateHandler}
                      selected={fields?.check_Out_Date}
                      minDate={nextDayDate(fields?.check_In_Date)}
                      maxDate={nextYearRange()}
                      name={"check_Out_Date"}
                      id="check_Out_Date"
                      className="datepicker-input-style-without-border"
                    />
                  </Stack>
                </Stack>
              </div>
              <div className="my-3">
                <Stack
                  direction="row"
                  spacing={3}
                  sx={{
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                  }}
                >
                  <Stack spacing={0}>
                    <InputLabel>Rooms</InputLabel>
                    <Select
                      labelId="selectRoomsClass"
                      id="demo-simple-select"
                      sx={{ width: { xs: "100%", sm: "100%", md: "8vw" } }}
                      name={"rooms"}
                      onChange={selectHandler}
                      value={fields.rooms}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                    </Select>
                    {error && error.rooms ? (
                      <label className="text-error t-error w-80">{error.rooms}</label>
                    ) : null}
                  </Stack>
                  <Box
                    className="mobilemt-2"
                    sx={{
                      display: { xs: "flex" },
                      marginLeft: {
                        xs: "0px !important",
                        sm: "16px !important",
                        md: "16px !important",
                      },
                    }}
                  >
                    <Stack spacing={0}>
                      <InputLabel>Adult(s)12+ Yrs</InputLabel>
                      <div className="d-flex">
                        <Button
                          onClick={plusButtonHandler}
                          style={{
                            borderRadius: "2px 0px 0px 2px",
                            borderColor: "lightgrey",
                            fontSize: "24px",
                            color: "#58595B",
                            backgroundColor: "white",
                          }}
                          variant="outlined"
                          className="mobileWidth"
                          name={"adult"}
                        >
                          +
                        </Button>
                        <TextField
                          required
                          id="textAdult"
                          type="text"
                          value={fields && fields.adult ? fields.adult : "0"}
                          disabled="true"
                          className="data"
                          sx={{
                            textAlign: "center",
                            fontSize: "24px",
                            backgroundColor: "white",
                            width: "60px",
                            borderRadius: "0px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "0",
                            },
                          }}
                        />
                        <Button
                          style={{
                            borderRadius: "0px 2px 2px 0px",
                            borderColor: "lightgrey",
                            fontSize: "24px",
                            color: "#58595B",
                            backgroundColor: "white",
                          }}
                          variant="outlined"
                          className="mobileWidth"
                          onClick={minusButtonHandler}
                          name={"adult"}
                        >
                          -
                        </Button>
                      </div>
                      {error && error.adult ? (
                        <label className="text-error t-error w-100">{error.adult}</label>
                      ) : null}
                    </Stack>
                    <Stack spacing={0}>
                      <InputLabel style={{ marginLeft: "15px" }}>
                        Child(s)2+ 11 Yrs
                      </InputLabel>
                      <div className="d-flex" style={{ marginLeft: "15px" }}>
                        <Button
                          onClick={plusButtonHandler}
                          style={{
                            borderRadius: "0px 2px 2px 0px",
                            borderColor: "lightgrey",
                            backgroundColor: "white",
                            fontSize: "24px",
                            color: "#58595B",
                          }}
                          variant="outlined"
                          className="mobileWidth"
                          name={"child"}
                        >
                          +
                        </Button>
                        <TextField
                          required
                          id="textAdult"
                          type="text"
                          value={fields && fields.child ? fields.child : "0"} disabled="true"
                          className="data"
                          sx={{
                            textAlign: "center",
                            fontSize: "24px",
                            backgroundColor: "white",
                            width: "60px",
                            borderRadius: "0px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "0",
                            },
                          }}
                        />
                        <Button
                          style={{
                            borderRadius: "0px 2px 2px 0px",
                            borderColor: "lightgrey",
                            color: "#58595B",
                            fontSize: "24px",
                            backgroundColor: "white",
                          }}
                          variant="outlined"
                          className="mobileWidth"
                          onClick={minusButtonHandler}
                          name={"child"}
                        >
                          -
                        </Button>
                      </div>
                    </Stack>
                  </Box>
                </Stack>
              </div>
              <div className="mt-3 d-flex justify-content-end">
                <button
                  className="search-travel-btn"
                  onClick={searchHotelsHandler}
                >
                  Search Hotel
                </button>
              </div>
            </Box>

            <Menu />
            <TrendingProducts />
          </div>
        </div>
        <div className="commonw50 mobileBox">
          {bannerShow ? (
            <BannerComponent
              heading={"Welcome to the"}
              text={settingState?.hotel?.hotel_banner_title ? settingState?.hotel?.hotel_banner_title
                : "World of Endless Rewards!"}
              banner={hotelsidebanner}
            />
          ) : hotelDetails ? (
            <>
              <Box
                className="mobileDetailAppBar"
                sx={{ display: { xs: "block", sm: "none", md: "none" } }}
              >
                <ButtonAppBar initialData={initialData} text="Hotel" />
              </Box>
              <HotelResult setHotelDetails={setHotelDetails} />
            </>
          ) : (
            <HotelDetails fields={fields} setHotelDetails={setHotelDetails} />
          )}
        </div>
      </div>
    </>
  );
}
