/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import { postPaymentToken } from "../../Api/PaymentApi";
import { CircularProgress } from "@mui/material";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51M52fCJsdCw9uYfXmNaKl3XvWjBCRfIPwgTe82j4nPsjuQFZQCnHx2NlRervLgmJSVMxyrw2ihxG5qgbobY7bdH100t0zxDjSK");

export default function Payment({ amount }) {

    const [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );
        getPaymentToken();


        // Create PaymentIntent as soon as the page loads
        // fetch("/create-payment-intent", {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
        // })
        //   .then((res) => res.json())
        //   .then((data) => setClientSecret(data.clientSecret));
    }, [amount]);


    const getPaymentToken = async () => {
        let amt = amount?.payAmount;
        const res = await postPaymentToken(amt * 100);
        setClientSecret(res.data.result.clientSecret);
    }

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div className="w-100">
            {clientSecret ?
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
                : <div className='strip-loader'>
                    <CircularProgress color="secondary" />
                </div>}
        </div>
    );
}