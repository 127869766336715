import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CarRentalComponent from "./car-rental";
import FlightComponent from "./flights";
import HotelsComponent from "./hotels";
import "./travel.css";

const Travel1 = () => {
  const data = useLocation();

  const [option, setoption] = useState(1);
  useEffect(() => {
    if (data?.state) {
      if (data?.state?.state === "Flight") {
        setoption(1);
      } else if (data?.state?.state === "Hotel") {
        setoption(2);
      } else {
        setoption(3);
      }
    }
  }, [data]);

  return (
    <>
      {option === 1 ?
        <FlightComponent setoption={setoption} option={option} />
        : option === 2 ?
          <HotelsComponent setoption={setoption} option={option} /> :
          <CarRentalComponent setoption={setoption} option={option} />
      }
    </>

  );
};

export default Travel1;
