import {
  SET_ATTRACTION_SCREEN_STEP, SET_AVAILABILITY, SET_CITY, SET_CONTACT_DETAILS, SET_COUNTRIES, SET_CURRENCY, SET_FIELDS, SET_LANDING_SCREEN_PRODUCT, SET_PRODUCT, SET_PRODUCT_CATEGORIES, SET_PRODUCT_DETAILS, SET_TICKETS, SET_VISITORES_DETAILS,
} from "./AttractionType";


export const setAttractionScreenStepRedux = (data) => {
  return {
    type: SET_ATTRACTION_SCREEN_STEP,
    payload: data,
  };
};

// Attraction Product CAtegories
export const setProductCategoriesRedux = (data) => {
  return {
    type: SET_PRODUCT_CATEGORIES,
    payload: data,
  };
};

export const setFieldsRedux = (data) => {
  return {
    type: SET_FIELDS,
    payload: data,
  };
};

export const setTicketsRedux = (data) => {
  return {
    type: SET_TICKETS,
    payload: data,
  };
};

export const setAvailabilityRedux = (data) => {
  return {
    type: SET_AVAILABILITY,
    payload: data,
  };
};

export const setVisitorsDetailsRedux = (data) => {
  return {
    type: SET_VISITORES_DETAILS,
    payload: data,
  };
};

export const setContactDetailsRedux = (data) => {
  return {
    type: SET_CONTACT_DETAILS,
    payload: data,
  };
};


export const setProductDetailsRedux = (data) => {
  return {
    type: SET_PRODUCT_DETAILS,
    payload: data,
  };
};

// Attraction Product CAtegories
export const setLandingScrProductRedux = (data) => {
  return {
    type: SET_LANDING_SCREEN_PRODUCT,
    payload: data,
  };
};


// Attraction Product CAtegories
export const setProductRedux = (data) => {
  return {
    type: SET_PRODUCT,
    payload: data,
  };
};


export const setCityRedux = (data) => {
  return {
    type: SET_CITY,
    payload: data,
  };
};

export const setCurrencyRedux = (data) => {
  return {
    type: SET_CURRENCY,
    payload: data,
  };
};

export const setCountriesRedux = (data) => {
  return {
    type: SET_COUNTRIES,
    payload: data,
  };
};
