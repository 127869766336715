import {makeStyles} from '@mui/styles';

const useStyle = makeStyles({

    cardDatail: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        color: "#fff",
        "&::before": {
            content: `''`,
            position: 'absolute',
            left: '-60px',
            top: '0px',
            width: '0',
            height: '0',
            border: '60px solid transparent',
            borderTopColor: '#f2f5f8'
        },
        borderRadius: '0px 0px 0px 40px',
        '@media (max-width: 900px)': {
            height: '100%',
            marginTop: '40px'
        },
        overflowY:'scroll',
        '&::-webkit-scrollbar':{
            display: 'none',
        }


    },
    hero: {
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontSize: "4rem",
        borderRadius: '6px',
        marginTop: '60px'

    },
    shopButton: {
        border: "0px solid #ffffff !important",
        color: '#282828 !important',
        backgroundColor: '#ffffff !important',
        borderRadius: '6px !important',
        fontFamily: 'helvetica_neue65_medium !important',
        fontSize: '14px !important',
       
        '&:hover': {
            border:'0px solid rgb(119, 18, 106) !important',
            backgroundColor: 'rgb(119, 18, 106) !important',
            opacity: [
                0.9, 0.8, 0.7
            ],
            color: "#ffffff !important"
        }
    },

    shopSecondButton: {
        border: "0px solid #ffffff !important",
        color: '#fff !important',
        backgroundColor: 'rgb(119, 18, 106) !important',//'#ffffff !important',
        borderRadius: '6px !important',
        fontFamily: 'helvetica_neue65_medium !important',
        fontSize: '14px !important',
    },

    voucherText: {
        color: 'var(--primayColor)',
        margin: '10px 0px !important',
        fontFamily: 'helvetica_neue45_light !important',
        fontSize: '24px !important',
    },
    voucherData:{
        color: '#000000',
        margin: '0px 0px !important',
        fontFamily: 'helvetica_neue45_light !important',
        fontSize: '12px !important',
    },
    voucherHeading:{
        color: 'var(--primayColor)',
        margin: '10px 0px !important',
        fontFamily: 'helvetica_neue65_medium !important',
        fontSize: '16px !important',
    },
    productHeading:{
        color: 'var(--primayColor)',
        margin: '0px 0px !important',
        fontFamily: 'helvetica_neue65_medium !important',
        fontSize: '16px !important',
    },
    voucherHeadingSection:{
        color: 'var(--primayColor)',
        margin: '10px 0px !important',
        fontFamily: 'helvetica_neue45_light !important',
        fontSize: '16px !important',
    },
    productHeadingSection:{
        color: 'var(--primayColor)',
        margin: '0px 0px !important',
        fontFamily: 'helvetica_neue45_light !important',
        fontSize: '16px !important',
    },
    logo: {
       width:'100%',
       height:'400px',
    },
    cardConcave: {
        position: 'absolute',
        width: '45px',
        height: '116px',
        borderRadius: '70%',
        background: '#ffffff',
        zIndex: 1,
        transform: 'rotate(-41deg)',
        top: '-34px',
        left: '-64px'
    },
    mainBanner:{
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
        height: 'fit-content',
        '@media (max-width: 1024px)': {
            marginTop:'150px',
        },
        '@media (max-width: 900px)': {
            marginTop:'95px',
        },
    },
    subBanner:{
        
        backgroundColor: '#f2f5f8',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding:'20px',
        marginTop: '40px',
        marginLeft:'40px',
        height:'90px',
        width:'108px',
        cursor:'pointer',
        '&:nth-child(1)': {
            marginTop: '0px',
        },
        '@media (max-width: 600px)': {
            marginTop: '0px',
        },
    
    },
    hiddenDiv:{
        width: '200px',
        overflowY: 'scroll',
        height: '510px',
        marginTop:'41px',
        '&::-webkit-scrollbar':{
            display: 'none',
        },
        '@media (max-width: 1024px)': {
            marginTop:'150px',
        },
        '@media (max-width: 900px)': {
            marginTop:'95px',
        },
        '@media (max-width: 600px)': {
            marginTop:'40px',
            height:'auto'
        },
        
    },
    sublogo:{
        width:'100%',
        '@media (max-width: 600px)': {
            width:'85px',
        },
        
        
    },
    listData:{
        color: '#221F3C',
        marginBottom: '15px',
    },
    listItem:{
        color: '#221F3C',
        fontFamily: 'helvetica_neue65_medium',
        padding: '2px 0px',
       fontSize: '14px'
    },
    shopcolor:{
          height:'40px',
          border:'none'
    }
   

})

export default useStyle
