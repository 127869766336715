import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import WestIcon from "@mui/icons-material/West";

export default function ButtonAppBar({ initialData, text }) {
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton size="large" edge="start" color="inherit" aria-label="menu">
          <WestIcon onClick={() => initialData()} />
        </IconButton>
        <div style={{ margin: "0 auto" }}>{text}</div>
      </Toolbar>
    </AppBar>
  );
}
