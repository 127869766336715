import { Box, Stack } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/leftSidebar/SideBar";

const Layout = () => {

  return (
    <>
      <Box>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Sidebar />
          <Outlet />
        </Stack>
      </Box></>
  );
};

export default Layout;
