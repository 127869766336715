import ApiAuth from "./apiAuth";

// export const signInApi = async (username, password) => {
//     const response = await ApiAuth.post(`/v1/login/otp?user_identifier=${username}&password=${password}`)
//     return response;
// };

// send otp
export const sendOTPApi = async (username, password) => {
  const response = await ApiAuth.post(
    `/v1/login/otp?user_identifier=${username}&password=${password}`
  );
  return response;
};

// login
export const signInWithOtpApi = async (username, password, otp) => {
  const response = await ApiAuth.post(
    `/v1/login?user_identifier=${username}&password=${password}&otp=${otp}`
  );
  return response;
};

// Create new user
export const registerUserApi = async (payload) => {
  const response = await ApiAuth.post(`/v1/register`, payload);
  return response;
};

// user identifier
export const userIdentifierApi = async (username) => {
  const response = await ApiAuth.post(
    `/v1/password/otp?user_identifier=${username}`
  );
  return response;
};

// reset password
export const resetPasswordApi = async (username, password, otp) => {
  const response = await ApiAuth.post(
    `/v1/password?user_identifier=${username}&password=${password}&otp=${otp}`
  );
  return response;
};
