import { Drawer } from '@mui/material'
import React from 'react'
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';

const CommonDrawer = (props) => {
    return (
        <>
            <div>
                <Drawer
                    anchor="right"
                    open={props?.open}
                    onClose={() => props?.onClose(false)}
                >
                    <div className='display-flex align-items-center backButton mb-4 m-flex' >
                        <span className='arrowBack d-flex align-items-center justify-content-center me-2' onClick={() => props?.onClose(false)}>
                            <ChevronLeftTwoToneIcon />
                        </span>
                        <span>Back</span>
                    </div>
                    {props?.children}
                </Drawer>
            </div>
        </>
    )
}

export default CommonDrawer