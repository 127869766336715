/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { Stack } from '@mui/material';
import AccountEdit from './accountLeft/AccountEdit'
import AccountDetails from './accountRight/AccountDetails';
import ProfilePic from './accountLeft/ProfilePic';
import { useDispatch } from 'react-redux';
import { setFlightDetails, setFlightScreenStep } from '../../redux/Flight/flightAction';

const AccountContainer = () => {
  const dispatch = useDispatch()

  // Travel module step reset to 0
  useEffect(() => {
    dispatch(setFlightScreenStep(0));
    dispatch(setFlightDetails(null));
  }, [])

  return (
    <>
      <Stack direction="row" spacing={2} style={{ width: '100%', margin: '0' }} className='profileMobileStack'>
        <Box p={0} style={{ width: '50%', zIndex: 2, margin: '0', padding: '40px 10px 50px 20px' }} className='profileMobileBox'>

          <h1 style={{ paddingLeft: '45px', marginBottom: '20px' }} className='headingMobile'>Profile</h1>
          <Stack direction="row" spacing={2} className='profileMobileStack'>
            <Box className='mobileProfile '>
              <ProfilePic />
            </Box>
            <Box className='mobileEdit'>
              <AccountEdit />
            </Box>
          </Stack>
        </Box>

        <AccountDetails />

      </Stack>
    </>
  )
}

export default AccountContainer;