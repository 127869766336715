import {
  SET_ATTRACTION_SCREEN_STEP, SET_AVAILABILITY, SET_CITY, SET_CONTACT_DETAILS, SET_COUNTRIES, SET_CURRENCY, SET_FIELDS, SET_LANDING_SCREEN_PRODUCT, SET_PRODUCT, SET_PRODUCT_CATEGORIES, SET_PRODUCT_DETAILS, SET_TICKETS, SET_VISITORES_DETAILS,
} from "./AttractionType";

const initialWeightState = {
  loading: false,
  step: 0,
  loadingproductDetails: false,
  fields: null,
  categories: null,
  currency: null,
  countries: null,
  city: null,
  product: null,
  productDetails: null,
  tickets: null,
  availability: null,
  visitorsDetails: null,
  error: "",
};

const attractionReducer = (state = initialWeightState, action) => {
  switch (action.type) {

    case SET_ATTRACTION_SCREEN_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case SET_FIELDS:
      return {
        ...state,
        fields: action.payload,
      };

    case SET_PRODUCT_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    case SET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.payload,
      };

    case SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };

    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };

    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };

    case SET_CITY:
      return {
        ...state,
        city: action.payload,
      };

    case SET_TICKETS:
      return {
        ...state,
        tickets: action.payload,
      };

    case SET_AVAILABILITY:
      return {
        ...state,
        availability: action.payload,
      };

    case SET_VISITORES_DETAILS:
      return {
        ...state,
        visitorsDetails: action.payload,
      };

    case SET_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: action.payload,
      };


    default:
      return state;
  }
};
export default attractionReducer;
