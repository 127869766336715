/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import CommonDrawer from "../common/CommonDrawer";
import { useEffect } from "react";
import "../travel/home.css"
import ForgetPassword from "../signin/ForgetPassword";
import { setFlightDetails, setFlightScreenStep } from "../../redux/Flight/flightAction";
import { useDispatch } from "react-redux";
import RegisterScreen from "./RegisterScreen";
import SignInScreen from "./SignInScreen";

export default function LoginScreen({ open, onClose, setoption, option, history }) {
    const dispatch = useDispatch();

    const [modelShow, setModelShow] = useState("signin");


    // Travel module step reset to 0
    useEffect(() => {
        dispatch(setFlightScreenStep(0));
        dispatch(setFlightDetails(null));
    }, []);



    return (
        <div className="d-flex ">
            <CommonDrawer
                className="Login-Drawer"
                onClose={onClose}
                open={open}
                sx={{ p: 2 }}
            >

                {/* SignIn Form */}
                {modelShow === "signin" && (
                    <SignInScreen
                        setModelShow={setModelShow}
                    />
                )}

                {/* Register Form */}
                {modelShow === "register" && (
                    <>
                        <RegisterScreen
                            setModelShow={setModelShow}
                        />
                    </>
                )}

                {modelShow === "forget" && (
                    <ForgetPassword
                        setModelShow={setModelShow}
                    />
                )}

            </CommonDrawer>
        </div>

    );
}
