import ApiToken from "./apiToken";

export const getCountryList = async () => {
  const response = await ApiToken.get(`/v1/attributes/country`);
  return response;
};

export const getBrandsCategoryList = async (type) => {
  const response = await ApiToken.get(
    `/v1/brand_category${type ? "/" + type + "?feature_flag=true" : ""}`
  );
  return response;
};
