/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box } from "@mui/system";
import ShopDetail from "../ShopDetail/ShopDetail";
import ShopCard from "../../voucher/container/Shopcard";
import BannerComponent from "../../travel/BannerComponent";
import ShopBanner from "../../../assets/img/shop/shop.jpg";
import { useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import useStyle from "./style.js";
import { Stack } from "@mui/material";
import bgImgArray from "../../../assets/img/shop/index";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "../../commonComponents/Tabs.css";
import Avatar from "@mui/material/Avatar";
import TrendingProducts from "../../travel/TrendingProducts";
import ButtonAppBar from "../../common/Appbar";
import CommonPagination from "../../commonComponents/CommonPagination";
import { getMerchandiseProducts } from "../../../Api/MerchandiseApi";
import { useDispatch, useSelector } from "react-redux";
import { setFlightDetails, setFlightScreenStep, } from "../../../redux/Flight/flightAction";
import { useQuery } from "react-query";
import "./style.css";
import ShopFilter from "../filter/ShopFilter";
import PointsComponent from "../../commonComponents/PointsComponent";
import { useDebounce } from "../../../utils/constantFunctions";


const categoryList = [
  { "name": "Top Offers", "id": "" }, { "name": "Mobiles", "id": "63527042f7888caedef0cdc9" },
  // { "name": "Fashion", "id": "63c00afea07a5f16aeddfc71" },
  { "name": "Home Appliance", "id": "63527029f7888caedef0cb1b" },
];

const VoucherContainer = () => {
  const dispatch = useDispatch();
  const classess = useStyle();

  const settingState = useSelector(
    (state) => state?.settingState?.settingResponse
  );

  const [item, setItem] = useState();
  const [bannerData, setBannerData] = useState(true);
  const [phoneCategory, setPhoneCategory] = useState(true);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [value, setValue] = useState(0);

  //Filters
  const [filterFlag, setFilterFlag] = useState(false);
  const [country, setCountry] = useState();
  const [category, setCategory] = useState();
  const [brand, setBrand] = useState("");
  const [minMaxAmount, setMinMaxAmount] = useState([1, 1000]);

  const brandDebounceQuery = useDebounce(brand, 1000);
  const amountDebounceQuery = useDebounce(minMaxAmount, 2000);


  // Travel module step reset to 0
  useEffect(() => {
    dispatch(setFlightScreenStep(0));
    dispatch(setFlightDetails(null));
  }, []);

  useEffect(() => {
    setPage(1);
  }, [brandDebounceQuery, category, country]);



  const initialData = () => {
    setBannerData(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const { isLoading, isError, data } = useQuery(["merchandise_list", limit, skip, country, category, brandDebounceQuery], () => getMerchandiseProducts(limit, skip, country, category, brandDebounceQuery));


  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{ width: "100%", marginLeft: "0px !important" }}
        className="mobileStack"
      >
        <Box
          p={2}
          style={{ zIndex: 2 }}
          className={classess.boxMainDiv || "commonw50 mobileBox"}
        >
          <PointsComponent />

          <h1 align="center" className="mt-4 mb-5" style={{ fontSize: "26px" }}>
            Shop for <strong> Best Brands Ever!</strong>
          </h1>

          {/* Category Section Start*/}

          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              paddingLeft: "0px",
              paddingRight: "0px",
              justifyContent: "center",
              overflowX: "scroll",
            }}
            className={classess.stackList}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="icon label tabs example"
              style={{ overflowX: "scroll" }}
            // className="tabScroll"
            >
              {categoryList.map((curr, index) => {
                return (
                  <Tab
                    icon={<Avatar alt="test avatar" src={bgImgArray[index]} />}
                    label={curr.name}
                    onClick={() => { setCategory(curr.id); }}
                  />
                );
              })}
            </Tabs>
          </Stack>

          {/* Category Section End*/}

          <Box className={classess.cardGrid || "mobileBox"}>
            {phoneCategory && (
              <>
                {/* Filter*/}

                <ShopFilter
                  filterFlag={filterFlag} setFilterFlag={setFilterFlag}
                  country={country} setCountry={setCountry}
                  category={category} setCategory={setCategory}
                  brand={brand} setBrand={setBrand}
                  minMaxAmount={minMaxAmount} setMinMaxAmount={setMinMaxAmount}
                />

                <>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    style={{ marginTop: "10px", }}
                  >
                    {(isLoading ? Array.from(new Array(6)) : data?.data?.result)?.map((current, index) => {
                      return (
                        <>
                          <Grid item xs={12} sm={6} md={6} lg={6}
                            onClick={() => { setItem(current); setBannerData(false); }}
                          >
                            <ShopCard
                              title={current?.merchandise?.default_title}
                              imgUrl={current?.product_class === "merchandise_variant" ? current?.merchandise_variant?.[0]?.images?.[0]?.file_url : current?.merchandise?.images?.[0]?.file_url}
                              price={current?.selling_price}
                              currency={current?.merchandise?.country?.currency}
                            />
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>

                  {data?.data?.result?.length > 0 && !isLoading ?
                    <CommonPagination limit={limit} setPage={setPage} setSkip={setSkip} total={data?.data?.count} page={page} setLimit={setLimit} /> :
                    <h5 align='center' className="mt-4 text-error">{!isLoading ? "Product not found" : ""}</h5>}
                </>

              </>
            )}

            <TrendingProducts paddingX="0" />
          </Box>
        </Box>
        <Box className="commonw50 mobileBox" style={{ marginLeft: "0px" }}>
          {bannerData ? (
            <BannerComponent
              heading={"Welcome to the"}
              text={settingState?.shop?.shop_banner_title ? settingState?.shop?.shop_banner_title : "World of Endless Rewards!"}
              banner={ShopBanner}
            />
          ) : (
            <>
              <Box
                className="mobileDetailAppBar"
                sx={{ display: { xs: "block", sm: "none", md: "none" } }}
              >
                <ButtonAppBar initialData={initialData} text="Shop" />
              </Box>
              <ShopDetail
                item={item}
                currency={item?.merchandise?.country?.currency}
                variants_flag={item?.merchandise?.product_variants_flag}
              />
            </>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default VoucherContainer;
