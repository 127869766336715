import React from 'react'
import Button from '@mui/material/Button'

const BannerComponent = ({ banner, heading, text, button }) => {
  return (
    <div className='mainBanner' style={{ background: `url(${banner}) no-repeat center center`, backgroundSize: 'cover', borderRadius: '0px 0px 0px 60px' }}>
      <div className='overlay'>
        <div className='position-relative'>
          <span>{heading}</span>
          <h2>{text}</h2>
          {button ? <Button className='knowmore-btn'>Know More</Button>:''}
        </div>
      </div>

    </div>
  )
}

export default BannerComponent