

// screen steps
export const SET_ATTRACTION_SCREEN_STEP = "SET_ATTRACTION_SCREEN_STEP"

export const SET_PRODUCT_CATEGORIES = "SET_PRODUCT_CATEGORIES"

export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS"

export const SET_LANDING_SCREEN_PRODUCT = "SET_LANDING_SCREEN_PRODUCT"

export const SET_PRODUCT = "SET_PRODUCT"

export const SET_FIELDS = "SET_FIELDS"

export const SET_CITY = "SET_CITY"

export const SET_CURRENCY = "SET_CURRENCY"

export const SET_COUNTRIES = "SET_COUNTRIES"

export const SET_TICKETS = "SET_TICKETS"

export const SET_AVAILABILITY = "SET_AVAILABILITY"

export const SET_VISITORES_DETAILS = "SET_VISITORES_DETAILS"

export const SET_CONTACT_DETAILS = "SET_CONTACT_DETAILS"


