export const GET_HOTEL_LIST_REQUEST = "GET_HOTEL_LIST_REQUEST"
export const GET_HOTEL_LIST_SUCCESS = "GET_HOTEL_LIST_SUCCESS"
export const GET_HOTEL_LIST_FAILURE = "GET_HOTEL_LIST_FAILURE"


export const SET_HOTEL_SCREEN_STEP = "SET_HOTEL_SCREEN_STEP"

export const SET_HOTEL_DETAILS = "SET_HOTEL_DETAILS"

export const SET_SELECTED_HOTEL_DETAILS = "SET_SELECTED_HOTEL_DETAILS"

export const SET_PASSENGER_DETAILS = "SET_PASSENGER_DETAILS"