/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useStyle from './PaymentDrawerStyle';
import PayByPoint from './PayByPoint'
import PayByCard from './PayByCard'
import Points from "../../assets/img/payment/Points.png"
import Path from "../../assets/img/payment/Path.png"
import Avatar from '@mui/material/Avatar';

import './paymentTab.css'
import PayByStripe from "./PayByStripe";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setOrderPriceObjectRedux, setUserPointsRedux } from "../../redux/Order/OrderAction";
import { getUserPointsApi } from "../../Api/Points";
import PaymentSummery from "./PaymentSummery";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

export default function FullWidthTabs({ amount, discount }) {
  const theme = useTheme();
  const classess = useStyle();
  const dispatch = useDispatch();

  const pointsState = useSelector((state) => state?.orderState?.points);

  const [value, setValue] = React.useState(0);
  const [usePointsFlag, setUsePointsFlag] = React.useState(false);

  useEffect(() => {
    getUserPoints();
  }, []);

  useEffect(() => {
    if (parseFloat(pointsState) >= parseFloat(amount)) {
      setValue(0);
    } else {
      setValue(1);
    }
  }, [amount, pointsState]);

  const getUserPoints = async () => {
    const res = await getUserPointsApi();
    dispatch(setUserPointsRedux(res.data?.result?.Table1[0]?.AvailablePoints));
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const payAmountHandler = () => {
    let disAmt = (amount * (discount && discount > 0 ? discount : 0)) / 100;
    let totDisOrderAmt = amount - disAmt;
    let amtPay = 0;
    let usePoints = 0;
    let redeemPoints = 0;

    if (usePointsFlag && pointsState) {
      if (parseFloat(totDisOrderAmt) < parseFloat(pointsState)) {
        amtPay = parseFloat(0);
        usePoints = parseFloat(totDisOrderAmt);
        redeemPoints = parseFloat(totDisOrderAmt);

      } else if (parseFloat(totDisOrderAmt) > parseFloat(pointsState)) {
        amtPay = parseFloat(totDisOrderAmt) - parseFloat(pointsState);
        usePoints = parseFloat(pointsState);
        redeemPoints = parseFloat(pointsState);
      }
    } else {
      amtPay = parseFloat(totDisOrderAmt);
      usePoints = parseFloat(0);
      redeemPoints = parseFloat(totDisOrderAmt) < parseFloat(pointsState) ? parseFloat(totDisOrderAmt) : parseFloat(pointsState);
    }
    let obj = {
      burnFlag: usePointsFlag,
      totalOrderAmount: amount,
      discountAmt: disAmt,
      totalOrderAmountDiscount: totDisOrderAmt,
      totalAvilPoints: pointsState ? pointsState : 0,
      payAmount: amtPay,
      payPoints: usePoints,
      redeemPoints: redeemPoints,
      disable: parseFloat(pointsState) <= parseFloat(amount) && parseFloat(totDisOrderAmt) > parseFloat(pointsState) && (parseFloat(totDisOrderAmt) - parseFloat(pointsState)) > 10
    }

    dispatch(setOrderPriceObjectRedux(obj));

    return obj;
  };


  return (
    <Box className={classess.reedemBox}>
      <AppBar position="static">
        <Tabs
          className={classess.tabs}
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            className={classess.tabName}
            icon={<Avatar className="mui-avtar mb-1" alt="Points" src={Points} />}
            iconPosition="start"
            label="REDEEM POINTS"
            {...a11yProps(0)}
            disabled={parseFloat(pointsState) <= parseFloat(amount)}
          />
          <Tab
            className={classess.tabName}
            icon={<Avatar className="mui-avtar" alt="Path" src={Path} />}
            iconPosition="start" label="CARD"
            {...a11yProps(1)}
          // disabled={parseFloat(pointsState) >= parseFloat(amount)}
          />

        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} style={{ paddingLeft: '0px' }}>
          <PayByPoint
            payAmt={payAmountHandler()}
            amount={amount}
            usePointsFlag={usePointsFlag}
            setUsePointsFlag={setUsePointsFlag}
            disabled={parseFloat(pointsState) >= parseFloat(amount)}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} style={{ paddingLeft: '0px' }}>
          <PaymentSummery
            payAmt={payAmountHandler()}
            amount={amount}
            usePointsFlag={usePointsFlag}
            setUsePointsFlag={setUsePointsFlag}
            disabled={parseFloat(pointsState) >= parseFloat(amount)}
          />
          <PayByStripe
            amount={payAmountHandler()}
          />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}