/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import useStyle from './AccountStyle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';


const AccountEdit = () => {
    const classess = useStyle();

    const accountDetailsState = useSelector((state) => state?.settingState?.accountDetails);
    const userPointState = useSelector((state) => state?.orderState?.points);


    return (
        <>
            <Box style={{ padding: '0 0 0 0px' }} className='profileMobile'>
                <div className="d-flex flex-column mb-5">
                    <h3 style={{ marginBottom: '10px' }}>{accountDetailsState?.firstname + " " + accountDetailsState?.lastname}</h3>
                    <span>Membership No: {accountDetailsState?.id}</span>
                    {userPointState && <span>Points:  <span className='fw-semibold'>{parseFloat(userPointState).toFixed(2)} Pts</span></span>}
                </div>
                <FormControl className={classess.accountInput} style={{ width: '100%' }} variant="outlined" size="small">Primary Email Address
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <FormHelperText style={{ marginLeft: '0px' }}></FormHelperText>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        value={accountDetailsState?.email}
                        className='bg-white'
                    />
                    <EditOutlinedIcon className={classess.inputIcon} />
                </FormControl>
                <FormControl className={classess.accountInput} style={{ width: '100%' }} variant="outlined" size="small">Secondary Email Address
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <FormHelperText style={{ marginLeft: '0px' }}></FormHelperText>
                    <OutlinedInput id="outlined-adornment-password" type="text" className='bg-white' />
                    <EditOutlinedIcon className={classess.inputIcon} />
                </FormControl>
                <FormControl className={classess.accountInput} style={{ width: '100%' }} variant="outlined" size="small">Phone Number - 1
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <FormHelperText style={{ marginLeft: '0px' }}></FormHelperText>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        value={accountDetailsState?.contact_no}
                        className='bg-white'
                    />
                    <EditOutlinedIcon className={classess.inputIcon} />
                </FormControl>
                <FormControl className={classess.accountInput} style={{ width: '100%' }} variant="outlined" size="small">Phone Number - 2
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <FormHelperText style={{ marginLeft: '0px' }}></FormHelperText>
                    <OutlinedInput id="outlined-adornment-password" type="text" className='bg-white' />
                    <EditOutlinedIcon className={classess.inputIcon} />
                </FormControl>
                <FormControl className={classess.accountInput} style={{ width: '100%' }} variant="outlined" size="small">Password
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <FormHelperText style={{ marginLeft: '0px' }}></FormHelperText>
                    <OutlinedInput id="outlined-adornment-password" type="text" className='bg-white' />
                    <EditOutlinedIcon className={classess.inputIcon} />
                </FormControl>
                <div style={{ textAlign: 'right' }}>
                    <Button variant="outlined" className={classess.borderBtn} >Save Changes</Button>
                </div>
            </Box>
        </>
    )
}
export default AccountEdit;