import { Button, Checkbox, FormControlLabel } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune';
import React, { useState } from 'react'
import HotelCards from './HotelCards';
import CommonDrawer from '../common/CommonDrawer';
import RangeSlider from '../common/RangeSlider';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';


const HotelResult = ({ setHotelDetails }) => {


    const [filterOpen, setFilterOpen] = useState(false);



    return (
        <div className='hotel-result position-fixed overflow-auto d-flex flex-column px-3'>
            <div className='d-flex justify-content-center align-items-center pt-4 mobilemt-5'>
                <div className="d-flex justify-content-between align-items-center w-90">
                    <h4 className="m-0 w-100">Total Hotel(s) found 80</h4>
                    <div className='d-flex justify-content-end w-90'>
                        <Button onClick={() => setFilterOpen(true)} className='bg-white' style={{ color: 'var(--primayColor)', borderColor: 'var(--primayColor)', fontWeight: 'bold' }} variant="outlined"> <TuneIcon style={{ marginRight: '10px', transform: 'rotate(-90deg)' }} /> Filter</Button>
                    </div>
                </div>
            </div>
            <div className='mt-4 mb-5 overflow-auto'>
                <HotelCards setHotelDetails={setHotelDetails} />
            </div>
            <CommonDrawer onClose={setFilterOpen} open={filterOpen} >
                <div className="hotel-filter px-5 pb-5 mx-3 d-flex flex-column">
                    <h4 className="m-0 mt-5 ">Refine your search</h4>

                    <div className='mt-5'>
                        <span className='colorPrimary fw-bold'>Total AED</span>
                        <RangeSlider />
                    </div>
                    <span className='my-3 hotel-filter-line'></span>
                    <div>
                        <span className='colorPrimary fw-bold'>Hotel Ratings</span>
                        <div className='d-flex flex-column'>
                            <FormControlLabel control={<Checkbox style={{ color: "var(--primayColor)" }} className='hotelRating' />} label="Select All" />
                            <FormControlLabel control={<Checkbox style={{ color: "var(--primayColor)" }} className='hotelRating' />} label={<><StarRoundedIcon /><StarRoundedIcon /><StarRoundedIcon /><StarRoundedIcon /><StarRoundedIcon /></>} />
                            <FormControlLabel control={<Checkbox style={{ color: "var(--primayColor)" }} className='hotelRating' />} label={<><StarRoundedIcon /><StarRoundedIcon /><StarRoundedIcon /><StarRoundedIcon /><StarOutlineRoundedIcon /></>} />
                            <FormControlLabel control={<Checkbox style={{ color: "var(--primayColor)" }} className='hotelRating' />} label={<><StarRoundedIcon /><StarRoundedIcon /><StarRoundedIcon /><StarOutlineRoundedIcon /><StarOutlineRoundedIcon /></>} />
                            <FormControlLabel control={<Checkbox style={{ color: "var(--primayColor)" }} className='hotelRating' />} label={<><StarRoundedIcon /><StarRoundedIcon /><StarOutlineRoundedIcon /><StarOutlineRoundedIcon /><StarOutlineRoundedIcon /></>} />
                        </div>
                    </div>
                    <span className='my-3 hotel-filter-line'></span>
                    <div className="">
                        <span className='colorPrimary fw-bold'>Hotel Amenities</span>
                        <div className="d-flex flex-column">
                            <FormControlLabel className='' control={<Checkbox style={{ color: "var(--primayColor)" }} />} label="Select All" />
                            <FormControlLabel className='' control={<Checkbox style={{ color: "var(--primayColor)" }} />} label="Air Condition" />
                            <FormControlLabel className='' control={<Checkbox style={{ color: "var(--primayColor)" }} />} label="Bar" />
                            <FormControlLabel className='' control={<Checkbox style={{ color: "var(--primayColor)" }} />} label="Business Centre" />
                            <FormControlLabel className='' control={<Checkbox style={{ color: "var(--primayColor)" }} />} label="Coffe Shop" />
                            <FormControlLabel className='' control={<Checkbox style={{ color: "var(--primayColor)" }} />} label="Gym" />
                            <FormControlLabel className='' control={<Checkbox style={{ color: "var(--primayColor)" }} />} label="Pool" />
                            <FormControlLabel className='' control={<Checkbox style={{ color: "var(--primayColor)" }} />} label="Restaurant" />
                            <FormControlLabel className='' control={<Checkbox style={{ color: "var(--primayColor)" }} />} label="Room Service" />
                            <FormControlLabel className='' control={<Checkbox style={{ color: "var(--primayColor)" }} />} label="Wi-Fi Access" />
                        </div>
                    </div>
                </div>
            </CommonDrawer >
        </div>
    )
}

export default HotelResult