import React, { useEffect } from "react";
import user from "../../assets/img/voucher/user.png";
import Avatar from "@mui/material/Avatar";
import { IconButton, InputAdornment, InputLabel, OutlinedInput, Stack } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from "react";
import { sendOTPApi } from "../../Api/Auth";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import OtpScr from "./OtpScr";

function SignInScreen({ setModelShow }) {

  const [isLoading, setIsLoding] = useState(false);
  const [message, setMessage] = useState("");
  const [passShowHide, setPassShowHide] = useState(false);
  const [otpModel, setOtpModel] = useState(false);

  const [field, setField] = useState({
    username: "",
    password: "",
    message: "",
  });

  const [error, setError] = useState({
    email: "",
    password: "",
    message: "",
  });

  useEffect(() => {
    setMessage("");
    setIsLoding(false);
  }, [field.username, field.password]);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 10000);
  }, [message]);

  const togglePassword = () => {
    setPassShowHide((prevState) => !prevState);
  };

  const signInAuthHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setField({ ...field, [name]: value });
    setError({ ...error, email: "", password: "", message: "" });
  };

  const signInValidate = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var passRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,}$)"
    );

    if (!field.username) {
      setError({ ...error, email: "Please enter email or username" });
      return false;
    // } else if (
    //   !field.username ||
    //   emailRegex.test(field.username) === false
    // ) {
    //   setError({ ...error, email: "Enter valid email" });
    //   return false;
    } else if (field.password === "" || field.password === null) {
      setError({ ...error, password: "Please enter password" });
      return false;
    } else if (
      !field.password ||
      passRegex.test(field.password) === false
    ) {
      setError({
        password: `Minimum 8 characters, at least one uppercase letter,
    one lowercase letter, one number and one special character`,
      });
      return false;
    }
    return true;
  };

  const singInHandler = async () => {
    let val = signInValidate();
    if (val) {
      setIsLoding(true);
      let response = await sendOTPApi(field.username, field.password);
      if (
        response?.data?.status === "success" ||
        response?.data?.code === 429
      ) {
        if (response?.data?.code === 429) {
          setOtpModel(true);
          setMessage("Otp has been already sent. Please use the that OTP.");
        } else if (response?.data?.code === 401) {
          setOtpModel(true);
          setMessage(response?.data?.message);
        } else {
          setOtpModel(true);
          //setMessage(response?.data?.message);
        }
      } else if (response?.data?.code === 404) {
        setMessage("Incorrect email or password");
      } else if (response?.data?.code === 401) {
        setMessage("Incorrect email or password");
      }
      setIsLoding(false);
    }
  }


  return (
    <>
      {otpModel === false ?
        <div className="">
          <div style={{ padding: "20px 30px" }}>
            <Avatar className="mui-useravtar my-3" src={user} />
            <InputLabel>Username / Email Address</InputLabel>
            <OutlinedInput
              className="InputBox"
              id=""
              type="text"
              size="small"
              sx={{
                minWidth: "400px",
                height: "40px",
              }}
              name="username"
              onChange={signInAuthHandler}
              value={field.username}
            />
            {error.email ? (
              <InputLabel className="text-danger">
                {error.email}
              </InputLabel>
            ) : null}

            <div className="my-3">
              <InputLabel>Password</InputLabel>
              <OutlinedInput
                className="InputBox"
                id="outlined-adornment-password"
                type={passShowHide ? "text" : "password"}
                size="small"
                sx={{
                  minWidth: "400px",
                  height: "40px",
                }}
                endAdornment={
                  <InputAdornment
                    style={{ marginLeft: "0" }}
                    position="end"
                  >
                    <IconButton
                      aria-label="Search by brand"
                      edge="end"
                      onClick={() => togglePassword()}
                    >
                      {passShowHide ? (
                        <RemoveRedEyeIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                name="password"
                onChange={signInAuthHandler}
                value={field.password}
              />
              {error.password ? (
                <InputLabel className="loginErrorLable text-danger ">
                  {error.password}
                </InputLabel>
              ) : null}
            </div>
            {error.message ? (
              <InputLabel className="loginErrorLable text-danger ">
                {error.message}
              </InputLabel>
            ) : null}
            <div className="my-3" style={{ width: "max-content" }}>
              <InputLabel
                className="loginErrorLable cursor"
                onClick={() => setModelShow("forget")}
              >
                Forgot Password ?
              </InputLabel>
            </div>
            <div className="my-3" style={{ width: "max-content" }}>
              <InputLabel
                className="loginErrorLable cursor"
                onClick={() => setModelShow("register")}
              >
                Don't have an account? Sign up
              </InputLabel>
            </div>
            <div className="my-3">
              <Stack spacing={3} direction="row">
                <LoadingButton
                  className={isLoading ? "fillBtn-loader-true" : "fillBtn-loader"}
                  loading={isLoading}
                  variant="outlined"
                  onClick={() => singInHandler()}
                >
                  {isLoading ? "" : "Sign In"}
                </LoadingButton>
              </Stack>
            </div>
          </div>
        </div> :
        <OtpScr
          field={field}
          setOtpModel={setOtpModel}
        />}

      {message &&
        <div className="loginErrorLable" style={{ paddingTop: "0px", paddingLeft: "30px", paddingRight: "30px" }}>
          <span className="font-14 font-bold">
            {message && message}
          </span>
        </div>
      }
    </>
  );
}

export default SignInScreen;
