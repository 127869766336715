import React, { useState } from 'react'
import './profile.css';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Pic from '../../../assets/img/account/profile.png'

const ProfilePic = () => {

  const [profileImg, setProfileImg] = useState(`${Pic}`);

  const handleChange = (event) => {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (e) => {
      let img = e.target.result;
      setProfileImg(img);
    };
  };

  const handleRemove = () => {
    setProfileImg(`${Pic}`);
  }


  return (
    <>
      <div className="profilePic">
        <span className="profileDelete">
          <DeleteOutlinedIcon onClick={handleRemove} />
        </span>
        <img src={profileImg} className="img" alt="" />
      </div>
      <div style={{ position: 'relative', textAlign: 'center' }}>
        <input className="profileChangeBtn" type="file" onChange={handleChange} />
        <span className="profileBtn bg-white">
          Change Profile
          <EditOutlinedIcon />
        </span>
      </div>
    </>
  );

}

export default ProfilePic