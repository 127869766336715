import {makeStyles} from '@mui/styles';

const useStyle = makeStyles({
    container: {
        padding: '0px 0px 0px 7px'
    },
    voucherControll: {
        margin: '0px',
        width: "100%"
    },
    voucherLable: {
        marginLeft: '0px !important',
        fontFamily: 'helvetica_neue45_light',
        fontSize: '15px'
    },
    mb2: {
        marginBottom:'2rem'
    },
});

export default useStyle
